<template lang="pug">
    div(v-if="getLoggedIn" class="mr-2" style="max-width:1250px; overflow:scroll; border:0px solid red;")
        div(style="width:1120px; border:0px solid;")
            div(style="display:table; border:0px solid;")
                div(style="display:table-cell; border:0px solid;")
                    div(style="font-familt:'Courier New'; font-size:12px; font-weight:bolder; display:table; border:0px solid;")
                        div(style="display:table-cell; width:80px; border:0px solid;") Date Time
                        div(style="display:table-cell; width:220px; border:0px solid;") Title
                        div(style="display:table-cell; width:60px; border:0px solid;")
                    div(style="font-familt:'Courier'; font-size:12px; height:100px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                        div(v-for="comment in ceoComments" style="display:table; border:0px solid;")
                            div(style="display:table-cell; width:80px; border:0px solid;") {{ comment.date }}
                            div(style="display:table-cell; width:220px; border:0px solid;") {{ comment.note }}
                            div(style="display:table-cell; position:relative; width:60px; border:0px solid;")
                                img(@click="openEditCommentPopup(comment)" src="@/assets/edit-kpi-icon.svg" style="position:absolute; height:16px; cursor:pointer;")
                    div(class="text-right mt-2" style="border:0px solid;")
                        v-btn(@click="addMarketComment()" height="20" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") add market comment
                div(style="display:table-cell; position:relative; width:750px; border:0px solid;")
                    div(style="border:0px solid;")
                        ul(style="position:absolute; font-family:'Poppins'; font-size:14px; right:0; bottom:0; width:550px; border:0px solid;")
                            li Market commentary is designed to augment price changes in the market.
                            li Highlight exogenous events or significant internal events.
                            li Limited to 40 characters; be concise.
            div(id="comments-chart-element" class="mt-4" style="overflow:hidden; width:100%; height:calc(100vh - 285px); border:0px solid;")
                ChartPrice(
                    v-if="hideMe"
                    :legendView="false"
                    :single="true"
                    :leftToolbar="false"
                    :showrightSideBarLine="false"
                    :width="width - 3"
                    :height="height - 30"
                    page="main"
                    style="border:0px solid red;"
                    ref="chartPrice"
                )

        div(v-if="activeCommentId !== 0" id="editCommentPopupElement" class="pl-2 pr-2 pb-2" style="position:absolute; font-size:12px; background-color:#ebebeb; top:220px; z-index:99999; box-shadow:4px 4px 8px 1px rgba(0, 0, 0, 0.15); border:1px solid lightgrey;")
            div(style="display:table; border:0px solid;")
                div(style="display:table-cell; border:0px solid;")
                    div(style="display:table; border:0px solid;")
                        div(style="display:table-cell; border:0px solid;")
                            div(:style="`height:${heightPopup}px;`" style="overflow:hidden; border:0px solid;")
                                div(style="display:table; border:0px solid;")
                                    div(class="pb-1" style="display:table-cell; width:172px; border:0px solid;") Title
                                    //- div(class="pb-1" style="display:table-cell; width:170px; border:0px solid;") Description
                                template(v-for="langcomment in languagesAvailable")
                                    template(v-for="comment in commentsOnLang(langcomment.language)")
                                        div(:key="comment.language" style="display:table; border:0px solid;")
                                            div(style="display:table-cell; width:172px; border:0px solid green;")
                                                div(class="mb-2" style="display:table; border:0px solid red;")
                                                    div(class="pr-1" style="display:table-cell; border:0px solid;") {{ comment.language }}
                                                    div(style="display:table-cell; border:0px solid;")
                                                        div(style="border:0px solid;")
                                                            input(type="text" v-model="comment.note" class="pl-1 pr-1" style="width:150px; background-color:#FFFFFF; border:1px solid lightgrey;")
                                                        div(class="text-right mt-1" style="border:0px solid;")
                                                            v-btn(@click="updateTitleAndDescription(comment)" :disabled="isTitleOrDescriptionChanged(comment)" width="80" height="20" :style="isTitleOrDescriptionChanged(comment) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                                            //- div(style="display:table-cell; width:170px; border:0px solid;")
                                                textarea(type="text" rows="3" v-model="comment.comment" class="pl-1 pr-1" style="width:165px; background-color:#FFFFFF; border:1px solid lightgrey;")
                                        //- div(class="text-right mb-4" style="display:table; width:100%; border:0px solid;")
                                            div(style="display:table-cell; border:0px solid;")
                                                v-btn(@click="updateTitleAndDescription(comment)" :disabled="isTitleOrDescriptionChanged(comment)" width="80" height="20" class="mr-2" :style="isTitleOrDescriptionChanged(comment) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                        div(style="display:table-cell; border:0px solid;")
                            div(style="width:120px; border:0px solid;") Time
                            div(class="text-right" style="width:120px; padding-top:4px; border:0px solid;")
                                input(type="date" v-model="date" class="pl-1 pr-1" style="background-color:#FFFFFF; height:20px; width:100%; border:1px solid lightgrey;")
                                v-btn(@click="updateDate()" :disabled="isDateChanged()" width="80" height="20" class="mt-1" :style="isDateChanged() ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                        div(class="pl-6" style="display:table-cell; position:relative; border:0px solid;")
                            div(style="border:0px solid;")
                                div(@click="cancel()" class="text-center mt-1" style="font-family:Poppins; font-size:12px; width:80px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
                            div(style="position:absolute; bottom:0; border:0px solid;")
                                v-btn(@click="removeComment()" width="80" height="20" style="font-family:Poppins; font-size:12px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;") Delete
</template>

<script>
import CanvasGeneric from "@/components/CanvasGeneric.vue";
import ChartPrice from "@/components/ChartPrice.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "MarketCommentary",
    components: {
        CanvasGeneric,
        ChartPrice,
    },
    data() {
        return {
            activeCommentId: 0,
            ceoComments: [],
            ceoCommentsCopy: [],
            companies: [],
            companiesCopy: [],
            width: 0,
            height: 0,
            hideMe: false,
            date: '',
            dateCopy: '',
        };
    },
    created() {
        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
        this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        const element = document.getElementById('comments-chart-element');

        this.width = element.offsetWidth;

        setTimeout(() => {
            this.height = element.offsetHeight;
        }, 1);

        this.hideMe = true;
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies', 'getCompanyName']),
        getCeoComments() {
            return this.getCompany.ceo_comments;
        },
        languagesAvailable() {
            return this.getCompany.languages_available;
        },
        heightPopup() {
            return this.getCompany.languages_available.length * 101;
        },
    },
    methods: {
        ...mapActions(['setCeoComment', 'loadCompany', 'loadCompanyDetails', 'loadCompanies', 'loadCompanyPrices', 'updateTextTitleAndDescription', 'addNewMarketComment', 'updateMarketCommentDate', 'deleteMarketComment']),
        ...mapMutations(['setShowSnackbar']),
        async updateTitleAndDescription(comment) {
            const updateCommentObject = {
                company_id: this.getCompany.company_id,
                stock_id: comment.stock_id,
                journal_id: comment.journal_id,
                language: comment.language,
                content: comment.note,
            };

            // console.log(updateCommentObject);

            const result = await this.updateTextTitleAndDescription(updateCommentObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated title',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
            this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));

            this.activeCommentId = 0;
        },
        async updateDate() {
            const updateDateObject = {
                company_id: this.getCompany.company_id,
                journal_id: this.activeCommentId,
                stock_id: this.getCompany.stock_id,
                date: this.date,
                language: this.getCompany.language,
            };

            // console.log(updateDateObject);

            const result = await this.updateMarketCommentDate(updateDateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated date',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
            this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));

            this.activeCommentId = 0;
        },
        isTitleOrDescriptionChanged(comment) {
            const noteEditedString = JSON.stringify(this.companies.find((l) => l.language === comment.language).ceo_comments.find((c) => c.journal_id === comment.journal_id));
            const noteOriginalString = JSON.stringify(this.companiesCopy.find((l) => l.language === comment.language).ceo_comments.find((c) => c.journal_id === comment.journal_id));

            return (noteEditedString === noteOriginalString);
        },
        isDateChanged() {
            return (this.date === this.dateCopy);
        },
        cancel() {
            this.activeCommentId = 0;
        },
        async removeComment() {
            const result = await this.deleteMarketComment(this.activeCommentId);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully deleted the comment',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
            this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));

            this.activeCommentId = 0;
        },
        openEditCommentPopup(comment) {
            console.log(JSON.parse(JSON.stringify(comment)));

            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));

            this.ceoComments = JSON.parse(JSON.stringify(this.ceoCommentsCopy));

            this.date = comment.date;
            this.dateCopy = comment.date;
            this.activeCommentId = 0;

            setTimeout(() => {
                this.activeCommentId = comment.journal_id;
            }, 1);

            setTimeout(() => {
                const editCommentPopupElement = document.getElementById('editCommentPopupElement');

                console.log(editCommentPopupElement);

                window.addEventListener('click', this.openElement);
            }, 50);
        },
        openElement(e) {
            if(e.target.closest('#editCommentPopupElement')) {
                return;
            }

            this.activeCommentId = 0;

            window.removeEventListener('click', this.openElement);
        },
        async addMarketComment() {
            const stock_id = this.getCompany.stock_id;
            const company_id = this.getCompany.company_id;
            const date = moment().format('YYYY-MM-DD');

            const newMarketCommentObject = {
                company_id: company_id,
                journal_id: null,
                stock_id: stock_id,
                date: date,
            };

            console.log(newMarketCommentObject);

            const result = await this.addNewMarketComment(newMarketCommentObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully inserted new market comment',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
            this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));
        },
        commentsOnLang(language) {
            return this.companies.find((c) => c.language === language).ceo_comments.filter((cc) => cc.journal_id === this.activeCommentId);
        },
        // async updatesNewCompanyValues() {
        //     const company_id = this.getCompany.company_id;

        //     console.log(this.getCompanyName);
        //     console.log(company_id);

        //     await this.loadCompany({
        //         short_name: this.getCompanyName,
        //         language: 'en',
        //     });

        //     await this.loadCompanyPrices(company_id);

        //     await this.loadCompanyDetails();

        //     await this.loadCompanies(this.getCompanyName);

        //     this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        //     this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        //     this.ceoComments = JSON.parse(JSON.stringify(this.getCeoComments));
        //     this.ceoCommentsCopy = JSON.parse(JSON.stringify(this.getCeoComments));

        //     this.hideMe = false;

        //     setTimeout(() => {
        //         this.hideMe = true;
        //     }, 50);
        // },
    },
}
</script>

<style scoped>
textarea {
	resize: none;
}
</style>
