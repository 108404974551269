<template lang="pug">
    div(class="ir-corporate-mobile")
        div(style="max-width:1000px; margin-left:auto; margin-right:auto; border:0px solid;")
            div(id="headline-corporate" class="mb-2")
                h2 {{ $t('menu_corporate') }}
            div(class="container-element" style="border:0px solid;")
                //- header
                div(class="header-block")
                    div(class="col-block pl-2" style="float:left; border:0px solid;") {{ $t('Title') }}
                    div(class="col-block" style="float:left; border:0px solid;") {{ $t('file') }}
                    div(class="col-block" style="float:left; border:0px solid;") {{ $t('date') }}

                //- body
                div(v-for="doc in docs" :key="doc.doc_id" class="row-block" style="border:0px solid;")
                    div(class="col-block ellipsis pl-2 mt-1 mb-1" style="float:left; border:0px solid;") {{ doc.file_name }}
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; border:0px solid;")
                        div(style="height:18px; overflow:hidden; border:0px solid;")
                            a(:href="doc.url" target="_blank")
                                img(src="@/assets/pdf-icon.png" style="height:18px;")
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; border:0px solid;")
                        span {{ doc.date }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "CorporateMobile",
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.docs)));
    },
    computed: {
        ...mapGetters(['getCompany']),
        docs() {
            let docs = this.getCompany.docs;

            docs = _.orderBy(docs, ['date'], ['desc']);

            return docs;
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
