import { render, staticRenderFns } from "./StylingCss.vue?vue&type=template&id=3c638fec&scoped=true&lang=pug&"
import script from "./StylingCss.vue?vue&type=script&lang=js&"
export * from "./StylingCss.vue?vue&type=script&lang=js&"
import style0 from "./StylingCss.vue?vue&type=style&index=0&id=3c638fec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c638fec",
  null
  
)

export default component.exports