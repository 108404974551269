<template lang="pug">
    div(v-if="getLoggedIn" class="ir-invest-in-us-main-regular" style="width:950px; border:0px solid red;")
        template(v-for="why in whys")
            div(:key="why.why_id" class="row-block" :style="activeWhy === why.why_id ? 'max-height:4000px;' : 'height:170px;'" style="border:0px solid;")
                div(class="pa-2" style="float:left; height:250px; width:610px; border:0px solid;")
                    h2(style="border:0px solid;") {{ why.title }}
                    div(style="height:125px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                        div(v-html="changeToHtmlBrInText(why.detail)")
                div(style="float:left; height:250px; width:250px; overflow:hidden; border:0px solid;")
                    img(:src="why.img" style="height:250px;")
                div(class="pl-2 pr-3 pt-1" style="float:left; height:250px; background-color:#F9F8F8; border:0px solid black;")
                    v-btn(@click="updateWhy(why.why_id)" height="20" width="70" :disabled="activeWhy === why.why_id" :style="activeWhy === why.why_id ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Edit

                div(v-if="activeWhy === why.why_id" style="color:#000000;")
                    div(class="mb-4" style="float:left; background-color:#F9F8F8; width:610px; border:0px solid;")
                        
                        template(v-for="lang in getCompany.languages_available")
                            template(v-for="whylang in companies.find((c) => c.language === lang.language).whys.filter((w) => w.why_id === why.why_id)")
                                div(style="background-color:#F9F8F8; border:0px solid;") {{ languageTitle(whylang.language) }}
                                div(class="pr-2" style="background-color:#F9F8F8; border:0px solid;")
                                    input(type="text" v-model="whylang.title" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                div(class="pr-2 mt-1" style="background-color:#F9F8F8; border:0px solid;")
                                    textarea(type="text" rows="8" v-model="whylang.detail" style="background-color:#FFFFFF; resize:none; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                div(class="text-right pr-2" style="background-color:#F9F8F8; border:0px solid;")
                                    v-btn(@click="saveWhyTexts(whylang)" height="20" width="70" :disabled="whylang.title === checkIfChangedWhyText(whylang.why_id, 'title', whylang.language) && whylang.detail === checkIfChangedWhyText(whylang.why_id, 'detail', whylang.language)" :style="whylang.title === checkIfChangedWhyText(whylang.why_id, 'title', whylang.language) && whylang.detail === checkIfChangedWhyText(whylang.why_id, 'detail', whylang.language) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save

                    div(class="mb-4" style="float:left; background-color:#F9F8F8; border:0px solid;")
                        div(style="float:left; background-color:#F9F8F8; width:190px; border:0px solid;")
                            ImageUpload(@saveImage="saveImage" class="mt-1" :id="why.why_id" :encodedImage="why.img" :company="getCompany" :updateField="'why'" :height="190")
                        div(class="text-center" style="float:left; background-color:#F9F8F8; width:75px; border:0px solid;")
                            div(@click="cancel()" class="mt-1" style="font-family:Poppins; font-size:12px; background-color:#F9F8F8; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
</template>

<script>
import ImageUpload from "./helpers/ImageUpload.vue";
import { getLanguageHeadlines } from "./../../helpers/util";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

export default {
    name: "InvestmentCase",
    components: {
        ImageUpload,
    },
    data() {
        return {
            activeWhy: 0,
            whysForLanguages: [],
            companies: [],
            companiesCopy: [],
            languageHeadlines: getLanguageHeadlines(),
        };
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.getCompanies)));
        console.log(JSON.parse(JSON.stringify(this.companies)));
        console.log(JSON.parse(JSON.stringify(this.companiesCopy)));

        // console.log(JSON.parse(JSON.stringify(this.checkIfChangedWhyText(11, 'detail', 'sv'))));

        for(const company of this.getCompanies) {
            this.whysForLanguages = this.whysForLanguages.concat(company.whys);// .push(company.whys);
        }

        this.whysForLanguages = _.groupBy(this.whysForLanguages, 'language');

        console.log(JSON.parse(JSON.stringify(this.whysForLanguages)));

        // this.loggedIn = userLoggedInCheck();
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompanies', 'getCompany']),
        //TODO: This is sorted on why_id at the moment.
        // Later it will be sorted on order_by when available
        whys() {
            return this.getCompany.whys;
        },
    },
    methods: {
        ...mapActions(['updateWhyImage', 'updateWhyTexts']),
        ...mapMutations(['setShowSnackbar']),
        checkIfChangedWhyText(id, type, language) {
            return this.companiesCopy.find((c) => c.language === language).whys.find((w) => w.why_id === id)[type];
        },
        languageTitle(code) {
            return this.languageHeadlines.find((l) => l.code === code).text;
        },
        changeToHtmlBrInText(text) {
            return text.replace(/\n/g, "<br />");
        },
        updateWhy(why_id) {
            console.log(why_id);

            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));
            this.activeWhy = why_id;
        },
        async saveWhyTexts(whylang) {
            // console.log(JSON.parse(JSON.stringify(whylang)));

            // Example update whytext object:
            // {
            //     why_id: 11,
            //     company_id: 5470600,
            //     language: 'en',
            //     title: 'Some title text here',
            //     detail: 'Some detail text here'
            // }

            // "company_id": 5470600,
            // "why_id": 12,
            // "language": "en",
            // "title": "Some title text here",
            // "detail": "Some detail text here"

            //TODO: Use enpoint from backend to update why texts
            const updateWhyTextsObject = {
                company_id: this.getCompany.company_id,
                why_id: whylang.why_id,
                language: whylang.language,
                title: whylang.title,
                detail: whylang.detail,
            };

            console.log(updateWhyTextsObject);

            const result = await this.updateWhyTexts(updateWhyTextsObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated why texts',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));
        },
        async saveImage(imageString) {
            const updateWhyImageObject = {
                why_id: imageString.id,
                company_id: this.getCompany.company_id,
                image: imageString.image,
            };

            console.log(updateWhyImageObject);

            const result = await this.updateWhyImage(updateWhyImageObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated why image',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }
        },
        cancel() {
            this.activeWhy = 0;
            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));
        },
    },
}
</script>

<style scoped>

</style>
