<template lang="pug">
    div(v-if="loggedIn") Financial History
</template>

<script>
import { userLoggedInCheck } from "./../../helpers/util";

export default {
    name: "FinancialHistory",
    data() {
        return {
            loggedIn: false,
        };
    },
    mounted() {
        this.loggedIn = userLoggedInCheck();
    },
}
</script>

<style scoped>

</style>
