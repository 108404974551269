export default {
    state: {
        routerViewShow: true,
        languageCode: 'sv',
        showSnackbar: {
            show: false,
            message: '',
            type: 'notset',
        },
        showCanvasGenericMobile: true,
    },

    getters: {
        getRouterViewShow(state) {
            return state.routerViewShow;
        },
        getLanguageCode(state) {
            return state.languageCode;
        },
        getShowSnackbar(state) {
            return state.showSnackbar;
        },
        getShowCanvasGenericMobile(state) {
            return state.showCanvasGenericMobile;
        },
    },

    mutations: {
        setRouterViewShow(state, payload) {
            state.routerViewShow = payload;
        },
        setLanguageCode(state, payload) {
            state.languageCode = payload;
        },
        setShowSnackbar(state, payload) {
            state.showSnackbar = payload;
        },
        setShowCanvasGenericMobile(state, payload) {
            state.showCanvasGenericMobile = payload;
        },
    },
};
