<template lang="pug">
    div(v-if="pageCount > 1" style="font-size:14px; max-width:1000px; border:0px solid;")
        div(v-for="page of pageCount" @click="changePage(page)" class="mr-1" :key="page" :style="page === activePage ? 'font-weight:bold; color:#0077FF;' : ''" style="display:inline-block; cursor:pointer; color:lightgrey; border:0px solid;") {{ page }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "PagingNews",
    data() {
        return {
            activePage: 1,
        };
    },
    computed: {
        ...mapGetters(['getCompany']),
        pageCount() {
            return Number(this.getCompany.news.page_count);
        },
    },
    methods: {
        changePage(page) {
            this.activePage = page;

            console.log(this.$parent.$refs.somethingSimple);

            this.$parent.$refs.somethingSimple.changePage(page);
        },
    },
}
</script>

<style scoped>

</style>
