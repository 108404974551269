<template lang="pug">
    div(class="ir-leadership-team-main-regular" style="padding-bottom:25px; border:0px solid;")
        div(style="max-width:1000px; border:0px solid;")
            div(class="container-element")
                div(v-for="member in members" class="row-block" :class="member.odd ? 'odd' : ''" :key="member.team_id" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="position:relative; height:165px; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                    div(class="image-block" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="float:left; width:165px; height:165px; overflow:hidden; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                        img(:src="member.photo_img" style="height:165px;")
                    div(:style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="float:left; position:relative; width:calc(100% - 165px); height:165px; overflow:hidden; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid red;")
                        div(class="pl-4 pt-4 pb-4" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id) - 20}px;` : '' : ''" style=" position:relative; height:135px; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                            h2(@click="setElementHeight(member.team_id)" class="ellipsis mr-4" :class="checkIfThereIsMoreThanTopParagraphs(member.bio) ? member.odd ? 'highlight-text' : 'highlight-text-odd' : ''" :style="checkIfThereIsMoreThanTopParagraphs(member.bio) ? 'cursor:pointer;' : ''" style="border:0px solid;") {{ member.name }}, {{ member.position }}
                            div(v-if="checkIfOpened(member.team_id)" class="mt-2 mr-12" v-html="member.bio" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="max-width:700px; height:75px; overflow:hidden; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                            div(v-else class="mt-2 mr-12" v-html="getTopParagraph(member.bio)" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 165 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="max-width:700px; height:75px; overflow:hidden; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                            div(class="mt-2 mr-12" :id="`memberElement${member.team_id}`" v-html="member.bio" style="visibility:hidden; max-width:700px; border:0px solid;")
                            //- div(class="ellipsis mt-2 mr-12" style="max-width:700px; border:1x solid;")
                                span(style="font-weight:bold; border:0px solid;") Shares:
                                span(class="ml-1") {{ member.number_of_shares }} ({{ ((member.number_of_shares / 6675209) * 100).toFixed(0) }}% of {{ getCompany.name }})
                            div(style="position:absolute; bottom:-20px; border:0px solid;")
                                span(v-if="member.email !== null" style="font-weight:bold;") Email:
                                span(v-if="member.email !== null" class="ml-1")
                                    a(:href="`mailto:${member.email}`") {{ member.email }}
                        div(style="position:absolute; bottom:6px; right:8px;")
                            a(:href="member.linkedin" target="_blank")
                                img(src="@/assets/linkedin-icon.svg")
                    template(v-if="checkIfThereIsMoreThanTopParagraphs(member.bio)")
                        v-icon(@click="setElementHeight(member.team_id)" v-if="checkIfOpened(member.team_id)" :class="checkIfThereIsMoreThanTopParagraphs(member.bio) ? member.odd ? 'highlight-text' : 'highlight-text-odd' : ''" :style="member.odd ? 'color:#000000;' : 'color:#FFFFFF;'" style="position:absolute; top:8px; right:8px;") mdi-chevron-up
                        v-icon(@click="setElementHeight(member.team_id)" v-else :class="checkIfThereIsMoreThanTopParagraphs(member.bio) ? member.odd ? 'highlight-text' : 'highlight-text-odd' : ''" :style="member.odd ? 'color:#000000;' : 'color:#FFFFFF;'" style="position:absolute; top:8px; right:8px;") mdi-chevron-down
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "LeadershipTeam",
    data() {
        return {
            openedMembers: [],
            height: 75,
        };
    },
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.members)));
        console.log(this.getCompany.name);
    },
    computed: {
        ...mapGetters(['getCompany']),
        members() {
            let membersTemp = this.getCompany.team;
            let odd = true;

            membersTemp = _.orderBy(membersTemp, ["order_by"], ["asc"]);

            for(const member of membersTemp) {
                odd = !odd;
                member.odd = odd;
            }

            return membersTemp;
        }
    },
    methods: {
        heightMethod(id) {
            if(this.openedMembers.find((m) => m.id === id) !== undefined) {
                return this.openedMembers.find((m) => m.id === id).height + 90;
            }

            return 165;
        },
        checkIfOpened(id) {
            console.log(this.openedMembers.find((m) => m.id === id) !== undefined);

            return this.openedMembers.find((m) => m.id === id) !== undefined;
        },
        setElementHeight(id) {
            const element = document.getElementById(`memberElement${id}`);

            console.log(`memberElement${id}`, element.offsetHeight);

            this.height = element.offsetHeight;

            console.log('height', this.height);

            if(this.openedMembers.find((m) => m.id === id) === undefined) {
                const tempObject = {
                    id: id,
                    height: this.height
                };

                this.openedMembers.push(tempObject);
            } else {
                this.openedMembers = this.openedMembers.filter((m) => m.id !== id);
            }
        },
        getTopParagraph(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let topParagraphString = '';

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    topParagraphString += `<${el.localName} class="${el.className}">${el.innerHTML}</${el.localName}>\n\n`;
                }
            }

            return topParagraphString;
        },
        checkIfThereIsMoreThanTopParagraphs(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let numberOfParagraphsTotally = 0;
            let numberOfParagraphsWithClass = 0;

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    numberOfParagraphsWithClass += 1;
                }

                if(el.localName === 'p') {
                    numberOfParagraphsTotally += 1;
                }
            }

            return (numberOfParagraphsWithClass !== numberOfParagraphsTotally);
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.highlight-text:hover {
    color: #777777 !important;
}

.highlight-text-odd:hover {
    color: #e5e4e4 !important;
}
</style>
