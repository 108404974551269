<template lang="pug">
    div(class="ir-leadership-team-main-mobile" style="border:0px solid;")
        div(id="headline-leadership-team" class="mb-2" style="border:0px solid;")
            h2 {{ $t('menu_leadership_and_board') }}
        div(class="container-element" style="border:0px solid;")
            div(v-for="member in members" :key="member.team_id" class="row-block" :class="member.odd ? 'odd' : ''" style="overflow:hidden; border:0px solid;")
                div(class="image-block" style="overflow:hidden; border:0px solid;")
                    img(:src="member.photo_img" style="width:calc(100% + 10px);")
                div(:style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 195 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" class="pa-4" style="position:relative; height:195px; transition:height 0.3s ease-out; transition:height 0.3s ease-in; border:0px solid;")
                    template(v-if="checkIfThereIsMoreThanTopParagraphs(member.bio)")
                        v-icon(@click="setElementHeight(member.team_id)" v-if="checkIfOpened(member.team_id)" :class="checkIfThereIsMoreThanTopParagraphs(member.bio) ? member.odd ? 'highlight-text' : 'highlight-text-odd' : ''" :style="member.odd ? 'color:#000000;' : 'color:#FFFFFF;'" style="position:absolute; top:12px; right:8px;") mdi-chevron-up
                        v-icon(@click="setElementHeight(member.team_id)" v-else :class="checkIfThereIsMoreThanTopParagraphs(member.bio) ? member.odd ? 'highlight-text' : 'highlight-text-odd' : ''" :style="member.odd ? 'color:#000000;' : 'color:#FFFFFF;'" style="position:absolute; top:12px; right:8px;") mdi-chevron-down
                    div(@click="setElementHeight(member.team_id)" class="ellipsis" style="font-size:14px; font-weight:bold; width:calc(100vw - 70px); border:0px solid;") {{ member.name }}, {{ member.position }}
                    
                    //- bio block
                    div(v-if="checkIfOpened(member.team_id)" class="mt-2 mr-8" v-html="member.bio" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 195 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="border:0px solid;")
                    div(v-else class="mt-2 mr-8" v-html="getTopParagraph(member.bio)" :style="checkIfOpened(member.team_id) ? heightMethod(member.team_id) > 195 ? `height:${heightMethod(member.team_id)}px;` : '' : ''" style="border:0px solid;")
                    
                    div(class="mt-2 mr-8" :id="`memberElement${member.team_id}`" v-html="member.bio" style="visibility:hidden; border:0px solid;")
                    div(class="ellipsis pb-1" :class="member.odd ? 'odd' : 'row-block'" style="position:absolute; bottom:0; width:100%; border:0px solid;")
                        //- span(style="font-weight:bold; border:0px solid;") Shares:
                        //- span(class="ml-1") 101 500 (12% of {{ getCompany.name }}))
                        span(v-if="member.email !== null" style="font-weight:bold;") Email:
                        span(v-if="member.email !== null" class="ml-1")
                            a(:href="`mailto:${member.email}`") {{ member.email }}
                    div(style="position:absolute; bottom:40px; right:8px;")
                        a(:href="member.linkedin" target="_blank")
                            img(src="@/assets/linkedin-icon.svg" title="LinkedIn")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "LeadershipTeamMobile",
    data() {
        return {
            openedMembers: [],
            height: 75,
        };
    },
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.members)));
        console.log(this.getCompany.name);
    },
    computed: {
        ...mapGetters(['getCompany']),
        members() {
            let membersTemp = this.getCompany.team;
            let odd = true;

            membersTemp = _.orderBy(membersTemp, ["order_by"], ["asc"]);

            for(const member of membersTemp) {
                odd = !odd;
                member.odd = odd;
            }

            return membersTemp;
        }
    },
    methods: {
        heightMethod(id) {
            if(this.openedMembers.find((m) => m.id === id) !== undefined) {
                return this.openedMembers.find((m) => m.id === id).height + 90;
            }

            return 195;
        },
        checkIfOpened(id) {
            console.log(this.openedMembers.find((m) => m.id === id) !== undefined);

            return this.openedMembers.find((m) => m.id === id) !== undefined;
        },
        setElementHeight(id) {
            const element = document.getElementById(`memberElement${id}`);

            console.log(`memberElement${id}`, element.offsetHeight);

            this.height = element.offsetHeight;

            console.log('height', this.height);

            if(this.openedMembers.find((m) => m.id === id) === undefined) {
                const tempObject = {
                    id: id,
                    height: this.height
                };

                this.openedMembers.push(tempObject);
            } else {
                this.openedMembers = this.openedMembers.filter((m) => m.id !== id);
            }
        },
        getTopParagraph(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let topParagraphString = '';

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    topParagraphString += `<${el.localName} class="${el.className}">${el.innerHTML}</${el.localName}>\n\n`;
                }
            }

            return topParagraphString;
        },
        checkIfThereIsMoreThanTopParagraphs(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let numberOfParagraphsTotally = 0;
            let numberOfParagraphsWithClass = 0;

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    numberOfParagraphsWithClass += 1;
                }

                if(el.localName === 'p') {
                    numberOfParagraphsTotally += 1;
                }
            }

            return (numberOfParagraphsWithClass !== numberOfParagraphsTotally);
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
