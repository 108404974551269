<template lang="pug">
    div
        div(class="d-flex justify-end" style="max-width:1000px; border:0px solid;")
            //- Do if more than one language selection
            v-menu(v-if="getAvailableLanguages.length > 1" offset-y)
                template(v-slot:activator="{ on, attrs }")
                    div(v-bind="attrs" v-on="on" class="mb-2" style="width:200px; height:22px; border:1px solid #E4E4E4;")
                        div(style="float:left; width:70%; height:22px; line-height:19px; color:#5B5B5B; padding-left:2px; border:0px solid;") {{ getSelectedLanguage.title }}
                        div(class="text-right" style="float:left; width:30%; height:22px; border:0px solid;")
                            img(:src="getSelectedLanguage.icon" style="width:28px; padding-top:1px; padding-right:2px;")
                v-list(class="overflow-y-auto pl-0 pr-0 pb-0 pt-1")
                    div(v-for="(item, index) in getAvailableLanguages" :key="item.language")
                        div(@click="setLanguage(item.language)" class="d-flex justify-space-between pl-1 pr-1" style="width:100%; cursor:pointer; border:0px solid;")
                            div(class="ellipsis" style="width:155px; height:25px; border:0px solid;") {{ getLanguageOnCode(item.language).title }}
                            div(style="height:21px; width:31px; overflow:hidden; border:0px solid;")
                                img(:src="getLanguageOnCode(item.language).icon" style="height:100%; border:0px solid;")

            //- Do if only one language selection
            v-menu(v-else offset-y)
                template(v-slot:activator="{ on, attrs }")
                    div(class="mb-2" style="width:200px; height:22px; border:1px solid #E4E4E4;")
                        div(style="float:left; width:70%; height:22px; line-height:19px; color:#5B5B5B; padding-left:2px; border:0px solid;") {{ getSelectedLanguage.title }}
                        div(class="text-right" style="float:left; width:30%; height:22px; border:0px solid;")
                            img(:src="getSelectedLanguage.icon" style="width:28px; padding-top:1px; padding-right:2px;")
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getLanguagesForLanguageSelector } from "./../helpers/util";
import i18n from "./../plugins/i18n";

export default {
    name: 'LanguageSelector',
    data() {
        return {
            selectedLanguageCode: null,
            items: getLanguagesForLanguageSelector(),
        };
    },
    created() {
        this.selectedLanguageCode = localStorage.getItem('language');
    },
    computed: {
        ...mapGetters(['getCompany', 'getCompanyName']),
        getAvailableLanguages() {
            return this.getCompany.languages_available;
        },
        getSelectedLanguage() {
            return this.items.find((l) => l.language.toLowerCase() === this.selectedLanguageCode.toLowerCase());
        },
    },
    methods: {
        ...mapActions(['loadCompany', 'loadCompanyDetails', 'loadCompanyPrices']),
        ...mapMutations(['setLanguageCode', 'setCompany', 'setIsListed']),
        getLanguageOnCode(language) {
            return this.items.find((i) => i.language === language);
        },
        async setLanguage(language) {
            localStorage.setItem('language', language);

            this.selectedLanguageCode = localStorage.getItem('language');

            console.log(this.selectedLanguageCode);

            console.log({
                short_name: this.getCompanyName,
                language: this.selectedLanguageCode,
            });

            await this.loadCompany({
                short_name: this.getCompanyName,
                language: this.selectedLanguageCode,
            });

            //TODO: make sure to set company as listed while
            // we haven't added it to the database yet
            // if(this.getCompany.is_listed === undefined) {
            //     const companyTemp = this.getCompany;

            //     companyTemp.is_listed = true;
            //     // companyTemp.is_listed = false;

            //     this.setCompany(companyTemp);
            // }

            // this.setIsListed(this.getCompany.is_listed);

            const company_id = this.getCompany.company_id;

            if(this.getCompany.is_listed) {
                await this.loadCompanyPrices(company_id);
            }

            await this.loadCompanyDetails();

            i18n.locale = this.selectedLanguageCode;

            this.setLanguageCode(this.selectedLanguageCode);

            console.log(JSON.parse(JSON.stringify(this.getCompany)));
        }
    },
}
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
