<template lang="pug">
    div(v-if="getLoggedIn" class="mr-2" style="font-size:12px; overflow:scroll; border:0px solid;")
        div(style="width:1000px; border:0px solid;")
            div(style="position:relative; border:0px solid;")
                div(class="mb-2" style="border:0px solid green;")
                    v-btn(@click="setNewBespokePage()" height="25" elevation="0" style="font-family:Poppins; font-size:15px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;")
                        v-icon mdi-cog-outline
                        span(class="ml-3") Add New Bespoke Page
                div(v-if="selectedPage !== '' && selectedLanguage !== ''" @click="cancel()" class="text-right" style="position:absolute; top:0; right:0; font-family:Poppins; font-size:12px; width:80px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
            div(class="text-right" style="display:table; width:100%; border:0px solid;")
                div(style="display:table-cell; font-weight:bold; border:0px solid;") Select language and page to update:
            div(class="mb-2" style="display:table; border:0px solid;")
                div(style="display:table-cell; width:500px; height:22px; border:0px solid;")
                    div(style="border:0px solid;")
                div(style="display:table-cell; width:500px; height:22px; border:0px solid;")
                    div(style="border:0px solid;")
                        div(style="display:table; width:100%; border:0px solid red;")
                            div(style="display:table-cell; border:0px solid green;")
                                div(style="display:table; position:relative; width:100%; height:22px; border:0px solid blue;")
                                    div(style="width:336px; position:absolute; right:-10px; border:0px solid brown;")
                                        div(style="display:table; border:0px solid;")
                                            div(style="display:table-cell; border:0px solid yellow;")
                                                div(class="mb-1" style="height:22px; width:162px; border:0px solid;")
                                                    v-select(
                                                        @change="selectPageOnLanguage()"
                                                        item-text="text"
                                                        item-value="code"
                                                        placeholder="Select languages"
                                                        v-model="selectedLanguage"
                                                        :items="avalableLanguages"
                                                        solo
                                                        flat
                                                        dense
                                                        outlined
                                                        style="font-family:Courier; font-size:12px; top:0; left:0; width:160px; border-radius:0;"
                                                    )
                                            div(style="display:table-cell; border:0px solid orange;")
                                                div(class="ml-1 mb-1" style="height:22px; width:162px; border:0px solid;")
                                                    v-select(
                                                        @change="selectPageOnLanguage()"
                                                        item-text="menu_display"
                                                        item-value="page_id"
                                                        placeholder="Select page"
                                                        v-model="selectedPage"
                                                        :items="getCompany.pages"
                                                        :disabled="selectedLanguage === ''"
                                                        solo
                                                        flat
                                                        dense
                                                        outlined
                                                        style="font-family:Courier; font-size:12px; top:0; left:0; width:160px; border-radius:0;"
                                                    )

            template(v-for="company in companies")
                template(v-for="page in company.pages")
                    template(v-if="page.page_id === selectedPage && page.language === selectedLanguage")
                        div(style="display:table; width:100%; border:0px solid;")
                            div(class="text-right" style="display:table-cell; border:0px solid;")
                                div(style="font-weight:bold; border:0px solid;") Update Menu Text:
                        div(class="mb-4" style="display:table; width:100%; height:22px; border:0px solid;")
                            div(style="display:table-cell; position:relative; border:0px solid;")
                                div(style="display:table; position:absolute; right:0; border:0px solid;")
                                    div(style="display:table-cell; border:0px solid;")
                                        input(class="pl-1 pr-1" type="text" v-model="page.menu_display" style="background-color:#FFFFFF; height:20px; width:415px; border:1px solid lightgrey;")
                                    div(style="display:table-cell; border:0px solid;")
                                        v-btn(@click="saveMenuTitle()" class="ml-1" width="80" height="20" :disabled="checkIfTitleMenuHasBeenChanged(page)" :style="checkIfTitleMenuHasBeenChanged(page) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; margin-bottom:3px; border:1px solid #FB993F;") Save

                        div(style="display:table; width:100%; border:0px solid;")
                            div(class="text-right" style="display:table-cell; border:0px solid;")
                                div(style="font-weight:bold; border:0px solid;") Update Bespoke Page Text:
                        div(class="mb-1" style="display:table; width:100%; border:0px solid;")
                            div(style="display:table-cell; border:0px solid;")
                                div(style="display:table; width:100%; border:0px solid;")
                                    div(class="text-right" style="display:table-cell; border:0px solid;")
                                        v-btn(@click="saveBespokePage()" width="80" height="20" :disabled="checkIfHtmlPageHasBeenChanged(page)" :style="checkIfHtmlPageHasBeenChanged(page) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; margin-bottom:1px; border:1px solid #FB993F;") Save

                        div(style="display:table; border:0px solid;")
                            div(style="display:table-cell; position:relative; width:500px; overflow-y:scroll; overflow-x:hidden; border:0px solid green;")
                                div(style="position:absolute; border:0px solid;")
                                    div(class="pr-3" class="ir-generic-component-regular" style="width:500px; height:calc(100vh - 310px); border:0px solid;")
                                        span(v-html="page.html_page")
                            div(style="display:table-cell; width:500px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                                div(style="border:0px solid yellow;")
                                    codemirror(v-model="page.html_page" :options="cmOptions")

                        div(style="border:0px solid;")
                            v-btn(@click="removeBespokePage(page.page_id)" height="20" width="70" style="position:absolute; bottom:10px; right:10px; font-family:Poppins; font-size:12px; background-color:#EBEBEB; color:#27407E; text-transform:none; border:1px solid #27407E;") Delete
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getLanguageHeadlines } from "./../../helpers/util";

// require component
import { codemirror } from "vue-codemirror";

// require styles
import "codemirror/lib/codemirror.css";

// language js
import "codemirror/mode/xml/xml.js";

// theme css
import "codemirror/theme/mbo.css";

export default {
    name: "BespokePage",
    components: {
        codemirror
    },
    data() {
        return {
            code: 'const a = 10',
            cmOptions: {
                mode: 'xml',
                theme: 'mbo',
                lineNumbers: true,
                lineWrapping: true,
                line: true,
            },
            languages: getLanguageHeadlines(),
            selectedLanguage: '',
            selectedPage: '',
            page: {},
            companies: [],
            companiesCopy: [],
            pages: [],
            pagesCopy: [],
        };
    },
    created() {
        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        this.pages = JSON.parse(JSON.stringify(this.getCompany.pages));
        this.pagesCopy = JSON.parse(JSON.stringify(this.getCompany.pages));

        console.log(JSON.parse(JSON.stringify(this.companies)));
        console.log(JSON.parse(JSON.stringify(this.companiesCopy)));

        console.log(JSON.parse(JSON.stringify(this.pages)));
        console.log(JSON.parse(JSON.stringify(this.pagesCopy)));

        console.log(JSON.parse(JSON.stringify(this.selectedBespokePage)));


        this.selectedPage = this.selectedBespokePage.page_id;
        this.selectedLanguage = this.selectedBespokePage.language;
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.avalableLanguages)));
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies', 'getCompanyName']),
        avalableLanguages() {
            const languages = this.languages.filter((l) => {
                return this.getCompany.languages_available.find((cl) => {
                    if(l.code === cl.language) {
                        return l;
                    }
                });
            });

            return languages;
        },
        selectedBespokePage() {
            // console.log(this.getCompany.page_id);
            // console.log(JSON.parse(JSON.stringify(this.getCompany)));
            // console.log(JSON.parse(JSON.stringify(this.pages)));

            if(this.pages.length > 0) {
                return this.pages.find((p) => p.page_id === Number(this.getCompany.page_id));
            }

            return {};
        },
    },
    methods: {
        ...mapActions(['insertNewBespokePage', 'loadCompany', 'loadCompanyPrices', 'loadCompanyDetails', 'loadCompanies', 'updateBespokePage']),
        ...mapMutations(['setShowSnackbar']),
        cancel() {
            console.log('cancel');

            this.selectedLanguage = '';
            this.selectedPage = '';

            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));
        },
        async setNewBespokePage() {
            console.log('setNewBespokePage');

            const company_id = this.getCompany.company_id;

            const result = await this.insertNewBespokePage({
                company_id: company_id,
            });

            await this.updatesNewCompanyValues();

            console.log('result', result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully added new bespoke page',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }
        },
        checkIfTitleMenuHasBeenChanged(page) {
            const menuDisplay = this.companies.find((c) => c.language === page.language).pages.find((p) => p.page_id === page.page_id).menu_display;
            const menuDisplayCopy = this.companiesCopy.find((c) => c.language === page.language).pages.find((p) => p.page_id === page.page_id).menu_display;

            return (menuDisplay === menuDisplayCopy);
        },
        checkIfHtmlPageHasBeenChanged(page) {
            const htmlPage = this.companies.find((c) => c.language === page.language).pages.find((p) => p.page_id === page.page_id).html_page;
            const htmlPageCopy = this.companiesCopy.find((c) => c.language === page.language).pages.find((p) => p.page_id === page.page_id).html_page;

            return (htmlPage === htmlPageCopy);
        },
        selectPageOnLanguage() {
            console.log(this.selectedLanguage);
        },
        getPageOnPageId(page_id) {
            return this.getCompany.pages.find((p) => p.page_id === page_id);
        },
        async saveMenuTitle() {
            console.log('saveMenuTitle');

            const menuTitleObject = {
                page: {
                    page_id: this.selectedPage,
                    company_id: this.getCompany.company_id,
                    language: this.selectedLanguage,
                    menu_display: this.companies.find((c) => c.language === this.selectedLanguage).pages.find((p) => p.language === this.selectedLanguage && p.page_id === this.selectedPage).menu_display,
                }
            };

            console.log(JSON.parse(JSON.stringify(menuTitleObject)));

            const result = await this.updateBespokePage(menuTitleObject);

            await this.updatesNewCompanyValues();

            console.log('result', result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successful update of title',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }
        },
        async saveBespokePage() {
            console.log('saveBespokePage');

            const beskopePageObject = {
                page: {
                    page_id: this.selectedPage,
                    company_id: this.getCompany.company_id,
                    language: this.selectedLanguage,
                    html_page: this.companies.find((c) => c.language === this.selectedLanguage).pages.find((p) => p.language === this.selectedLanguage && p.page_id === this.selectedPage).html_page,
                }
            };

            console.log(JSON.parse(JSON.stringify(beskopePageObject)));

            const result = await this.updateBespokePage(beskopePageObject);

            await this.updatesNewCompanyValues();

            console.log('result', result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successful update of content',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }
        },
        removeBespokePage(page_id) {
            console.log(page_id);
        },
        async updatesNewCompanyValues() {
            await this.loadCompany({
                short_name: this.getCompanyName,
                language: 'sv',
            });

            const company_id = this.getCompany.company_id;

            if(this.getCompany.is_listed) {
                await this.loadCompanyPrices(company_id);
            }

            await this.loadCompanyDetails();

            await this.loadCompanies(this.getCompanyName);

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.pages = JSON.parse(JSON.stringify(this.getCompany.pages));
            this.pagesCopy = JSON.parse(JSON.stringify(this.getCompany.pages));
        },
    },
}
</script>

<style scoped>
>>> .v-text-field.v-text-field--enclosed .v-text-field__details,
>>> .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    padding-bottom: 4px;
    padding-top: 2px;
    max-height: 20px;
    min-height: 20px;
}

textarea {
	resize: none;
}

>>> .CodeMirror {
    height: calc(100vh - 310px);
}
</style>
