<template lang="pug">
    div(v-if="!kul" style="border:0px solid;")
        v-container(fluid)
            v-row
                v-col(cols="12" class="pa-0")
                    Header(id="header-element")
                    MenuMobile(id="header-mobile-element")

                v-col(cols="12" class="cards pa-0 mt-3" style="border:0px solid;")
                    div(id="menu-element" style="border:0px solid;")
                        Menu

                    div(v-if="innerWidth > 599" id="main-element" class="ml-8 mr-2" style="border:0px solid;")
                        div(style="border:0px solid;")
                            LanguageSelector
                            PagingNews(v-if="nisse === 'pressReleasesAndNews'")
                        div(:style="nisse === 'pressReleasesAndNews' ? 'height:calc(100vh - 235px);' : ''" style="height:calc(100vh - 215px); overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                            router-view(ref="somethingSimple")

                    div(v-if="innerWidth <= 599" id="mobile-main-content-element" style="border:0px solid;")
                        MobileMainContent

        Footer

        //- EdgerFinance

    div(v-else style="font-family:Courier;")
        v-container(fluid)
            v-row(class="pa-2" style="border:0px solid;")
                v-col(cols="12" class="pa-0")
                    div(class="pl-3 pr-3 pt-2 pb-3" style="height:80px; line-height:1.2; background-color:#212426; color:#FFFFFF; border-radius:7px 7px 0 0; border:0px solid;")
                        div(style="float:left; width:70%; height:62px; border:0px solid;")
                            div(style="font-size:32px;") Investor Relations Tools
                            div(style="font-size:18px;") by Edger Finance
                        div(v-if="getLoggedIn" style="float:left; width:30%; height:62px; border:0px solid;")
                            div(style="float:left; width:calc(100% - 70px); margin-top:16px; border:0px solid;")
                                div(style="float:right; width:fit-content; line-height:0.95; border:0px solid;")
                                    div(style="font-size:18px; border:0px solid;") {{ getCompany.name }}
                                    div(style="font-size:11px; border:0px solid;") {{ getCompany.ir_email }}
                            div(class="text-right" style="float:left; position:relative; width:70px; height:62px; border:0px solid;")
                                v-icon(@click="openUserMenu()" size="38" color="white" style="margin-top:12px; border-radius:5px; border:2px solid;") mdi-account-outline
                                div(v-if="loginPopupVisible" id="loginPopupWindowElement" class="pa-3 mt-1" style="position:absolute; right:0; font-size:12px; width:118px; background-color:#D9D9D9; color:#000000; z-index:999999; border:0px solid;")
                                    div(style="cursor:pointer; border:0px solid;") User Settings
                                    div(class="mt-2 mb-2" style="cursor:pointer; border:0px solid;") Company Users
                                    div(@click="logout()" style="cursor:pointer; border:0px solid;") Logout
                v-col(v-if="!getLoggedIn" cols="12" class="pa-0")
                    router-view
                v-col(v-else cols="12" class="cards pa-0")
                    MenuBackoffice
                    div(class="pa-2" style="height:calc(100vh - 97px); width:calc(100vw - 220px); overflow-y:scroll; overflow-x:scroll; border:0px solid;")
                        router-view
        //- div(style="position:fixed; bottom:0; width:100%; height:80px; background-color:green; border:1px solid;") Footer
</template>

<script>
import Header from "@/views/header/Header.vue";
import Menu from "@/views/menu/Menu.vue";
import MenuMobile from "@/views/menu/MenuMobile.vue";
// import EdgerFinance from "@/views/edgerFinance/EdgerFinance.vue";
import MobileMainContent from "@/views/content/mobile/MobileMainContent.vue";
import Footer from "@/views/footer/Footer.vue";
import LanguageSelector from "@/components/LanguageSelector.vue";
import PagingNews from "@/components/PagingNews.vue";
import { userLoggedInCheck } from "./../helpers/util";
import MenuBackoffice from "./../components/backoffice/menu/MenuBackoffice.vue";
import { mapGetters } from "vuex";

export default {
    name: "Main",
    components: {
        Header,
        Menu,
        MenuMobile,
        // EdgerFinance,
        MobileMainContent,
        Footer,
        LanguageSelector,
        PagingNews,
        MenuBackoffice,
    },
    data() {
        return {
            innerWidth: null,
            loginPopupVisible: false,
        };
    },
    mounted() {
        userLoggedInCheck(false);

        console.log(this.kul);
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log('nisse');
        console.log(this.nisse);

        window.addEventListener('resize', this.onResize);

        this.onResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany']),
        loginCheck() {
            return window.location.href.split('/').includes('login');
        },
        kul() {
            return window.location.href.split('/').includes('backoffice');
        },
        nisse() {
            return this.$route.name;
        },
    },
    watch: {
        loginPopupVisible() {
            if(this.loginPopupVisible) {
                console.log('watch popup...');
                // this.loginPopupVisible = false;
            }
        },
    },
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth;
        },
        openUserMenu() {
            console.log('openUserMenu');

            this.loginPopupVisible = !this.loginPopupVisible;

            setTimeout(() => {
                window.addEventListener('click', this.clickedOutsideCheck);
            }, 100);
        },
        clickedOutsideCheck(e) {
            const element = document.getElementById('loginPopupWindowElement');

            if(!element.contains(e.target)) {
                console.log('clicked outside');

                this.loginPopupVisible = false;

                window.removeEventListener('click', this.clickedOutsideCheck);
            }
        },
        logout() {
            window.location.href = '/backoffice/login';
        },
    },
};
</script>

<style scoped>
.cards {
    display: grid;
    grid-template-columns: 220px 1fr;
}

#header-mobile-element,
#mobile-main-content-element {
    display: none;
}

@media (max-width: 599px) {
    .cards {
        grid-template-columns: 1fr;
    }

    #header-element,
    #menu-element,
    #main-element {
        display: none;
    }

    #header-mobile-element {
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100000;
    }

    #mobile-main-content-element {
        display: block;
    }
}
</style>
