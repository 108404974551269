<template lang="pug">
    div(class="cards" style="background-color:#EEEDED; border:0px solid;")
        div(class="pl-2 pr-2" style="float:left; height:calc(100vh - 100px); background-color:#D9D9D9; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
            div(class="mb-8" style="font-family:'Times New Roman', Times, serif; font-size:28px;")
                div White Label IR Pages
                div Domain Specific CMS
                div Fully Managed
            div(class="mb-8")
                div(style="font-family:'Times New Roman', Times, serif; font-size:28px;") Streamline Investor Relations
                div(class="mb-4" style="font-family:Monda; font-size:14px;") Vim an adhuc convenire hendrerit. Debet incorrupte duo id. Debitis deleniti sadipscing at eos, et per sonet dicunt. Verear dolorem eam an, ea pro ancillae euripidis appellantur. Et minim dissentiunt nec, ut duo amet alia cetero. Vel ea mutat etiam signiferumque, putant impetus placerat at mel.
                div(style="font-family:Monda; font-size:14px;") Eum cu case sententiae philosophia, quo no inani essent definitionem, vim ut iudico tincidunt deseruisse. Ex dico laoreet nec. Ea mel malis facilisi maluisset. No duo populo incorrupte, pri invidunt maiestatis ad.
            div(class="mb-8")
                div(style="font-family:'Times New Roman', Times, serif; font-size:28px;") Open data , accessible, distribute effortlessly
                div(class="mb-4" style="font-family:Monda; font-size:14px;") Vim an adhuc convenire hendrerit. Debet incorrupte duo id. Debitis deleniti sadipscing at eos, et per sonet dicunt. Verear dolorem eam an, ea pro ancillae euripidis appellantur. Et minim dissentiunt nec, ut duo amet alia cetero. Vel ea mutat etiam signiferumque, putant impetus placerat at mel.
                div(style="font-family:Monda; font-size:14px;") Eum cu case sententiae philosophia, quo no inani essent definitionem, vim ut iudico tincidunt deseruisse. Ex dico laoreet nec. Ea mel malis facilisi maluisset. No duo populo incorrupte, pri invidunt maiestatis ad.
            div
                div(style="font-family:'Times New Roman', Times, serif; font-size:28px;") stimulate investment interest
                div(class="mb-4" style="font-family:Monda; font-size:14px;") Vim an adhuc convenire hendrerit. Debet incorrupte duo id. Debitis deleniti sadipscing at eos, et per sonet dicunt. Verear dolorem eam an, ea pro ancillae euripidis appellantur. Et minim dissentiunt nec, ut duo amet alia cetero. Vel ea mutat etiam signiferumque, putant impetus placerat at mel.

        div(class="mt-2" style="border:0px solid;")
            div(class="pl-3 pr-3 pb-3" style="float:left; height:260px; border:0px solid;")
                div(style="font-size:36px; border:0px solid;") Login
                div(class="mb-3" style="height:40px; border:0px solid;")
                    div(style="float:left; font-size:24px; width:130px; border:0px solid;") email
                    input(type="text" v-model="email" class="pl-2 pr-2" style="float:left; height:40px; width:340px; background-color:#FFFFFF; border:0px solid;")
                div(class="mb-3" style="height:40px; border:0px solid;")
                    div(style="float:left; font-size:24px; width:130px; border:0px solid;") password
                    input(type="password" v-model="password" class="pl-2 pr-2" style="float:left; height:40px; width:340px; background-color:#FFFFFF; border:0px solid;")
                div(class="text-right mb-3" style="border:0px solid;")
                    v-btn(@click="login()" class="white--text" style="font-size:24px; background-color:#444444; text-transform:none;") Login
                div(style="border:0px solid;")
                    v-btn(@click="changePassword()" style="font-family:Poppins; font-size:16px; background-color:#EBEBEB; color:#28407E; text-transform:none; border:1px solid #28407E;") Change Password

        ChangePassword(:openDialog="dialog")
</template>

<script>
import ChangePassword from "./ChangePassword.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "LoginPage",
    components: {
        ChangePassword,
    },
    data() {
        return {
            email: '',
            password: '',
            dialog: false,
        };
    },
    async mounted() {
        await this.loadCompanyCanonicalCName({ url: window.location.host });

        let tempName = this.getCompanyCanonicalCName;

        console.log(tempName);

        await this.loadCompany({
            short_name: tempName,
            language: 'sv',
        });

        document.title = this.getCompany.browser_tab_text;

        let link = document.querySelector("link[rel~='icon']");

        if(!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }

        link.href = this.getCompany.favicon;
    },
    computed: {
        ...mapGetters(['getUser', 'getCompany', 'getCompanies', 'getCompanyCanonicalCName', 'getCompanyName']),
    },
    methods: {
        ...mapActions(['loadLoginUser', 'loadCompany', 'loadCompanies', 'loadCompanyPrices', 'loadCompanyDetails', 'loadCompanyCanonicalCName']),
        ...mapMutations(['setLoggedIn', 'setCompanyName', 'setShowSnackbar', 'setIsListed']),
        changePassword() {
            this.dialog = true;

            setTimeout(() => {
                this.dialog = false;
            }, 1);
        },
        async login() {
            if(this.email === "" && this.password === "") {
                console.log('Empty email and password');
            } else {
                console.log('Logging in');
                // console.log(this.email);
                // console.log(this.password);

                await this.loadLoginUser({
                    email: this.email,
                    password: this.password,
                });

                // console.log(JSON.parse(JSON.stringify(this.getUser)));

                if(this.getUser !== null) {
                    localStorage.setItem('user', JSON.stringify(this.getUser));

                    await this.loadCompanyCanonicalCName({ url: window.location.host });

                    let tempName = this.getCompanyCanonicalCName;
                    // let tempName = '';

                    // if(this.getCompanyCanonicalCName.cnames !== undefined) {
                    //     console.log(this.getCompanyCanonicalCName.cnames[0]);

                    //     tempName = this.getCompanyCanonicalCName.cnames[0];
                    // } else {
                    //     console.log(window.location.host);

                    //     tempName = window.location.host;
                    // }

                    // tempName = tempName.split('.')[0].toLowerCase();

                    console.log(tempName);

                    this.setCompanyName(tempName);

                    console.log(this.getCompanyName);

                    await this.loadCompany({
                        short_name: tempName,
                        language: 'sv',
                    });

                    // console.log(JSON.parse(JSON.stringify(this.getUser)));
                    // console.log(JSON.parse(JSON.stringify(this.getCompany)));

                    if(this.getUser.company_id === this.getCompany.company_id) {
                        const company_id = this.getUser.company_id;

                        // console.log('company_id:', company_id);

                        if(this.getCompany.is_listed) {
                            await this.loadCompanyPrices(company_id);

                            this.setIsListed(true);
                        } else {
                            this.setIsListed(false);
                        }

                        await this.loadCompanyDetails();

                        this.addStyle(this.getCompany.css_styles);

                        console.log(JSON.parse(JSON.stringify(this.getCompany.languages_available)));

                        await this.loadCompanies(tempName);

                        this.setLoggedIn(true);

                        this.setShowSnackbar({
                            show: true,
                            message: 'Successfully logged in',
                            type: 'success',
                        });

                        this.$router.push({ path: '/backoffice/irHome' });
                    } else {
                        localStorage.removeItem('user');

                        this.setShowSnackbar({
                            show: true,
                            message: 'Wrong email or password...',
                            type: 'error',
                        });
                    }
                } else {
                    localStorage.removeItem('user');

                    this.setShowSnackbar({
                        show: true,
                        message: 'Wrong email or password...',
                        type: 'error',
                    });
                }
            }
        },
        addStyle(styleString) {
            const style = document.createElement('style');

            style.textContent = styleString;

            document.head.append(style);
        },
    },
}
</script>

<style scoped>
.cards {
    display: grid;
    grid-template-columns: 1fr 500px;
}
</style>
