<template lang="pug">
    div(v-if="getLoggedIn" style="width:800px; border:0px solid red;")
        div(style="border:0px solid;")
            codemirror(v-model="cssStyles" :options="cmOptions" style="font-size:12px;")
        div(class="text-right" style="border:0px solid;")
            v-btn(@click="setCssStyles()" height="20" width="70" :disabled="checkIfCssHasBeenChanged" :style="checkIfCssHasBeenChanged ? 'border:1px solid #7C7C7C;' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

// require component
import { codemirror } from "vue-codemirror";

// require styles
import "codemirror/lib/codemirror.css";

// language js
import "codemirror/mode/css/css.js";

// theme css
import "codemirror/theme/mbo.css";

export default {
    name: "StylingCss",
    components: {
        codemirror,
    },
    data() {
        return {
            cmOptions: {
                mode: 'css',
                theme: 'mbo',
                lineNumbers: true,
                lineWrapping: true,
                line: true,
            },
            cssStyles: '',
            cssStylesCopy: '',
        };
    },
    created() {
        this.cssStyles = JSON.parse(JSON.stringify(this.getCompany.css_styles));
        this.cssStylesCopy = JSON.parse(JSON.stringify(this.getCompany.css_styles));

        console.log(this.cssStyles);
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }
    },
    computed: {
        ...mapGetters(['getCompany', 'getLoggedIn', 'getCompanyName']),
        checkIfCssHasBeenChanged() {
            return (this.cssStyles === this.cssStylesCopy);
        },
    },
    methods: {
        ...mapActions(['loadCompany', 'updateCssStyles', 'loadCompanyPrices', 'loadCompanyDetails']),
        ...mapMutations(['setShowSnackbar']),
        async setCssStyles() {
            console.log('setCssStyles');

            const result = await this.updateCssStyles({
                company_id: this.getCompany.company_id,
                css_styles: this.cssStyles,
            });

            await this.updatesNewCompanyValues();

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated the css styling',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }
        },
        async updatesNewCompanyValues() {
            await this.loadCompany({
                short_name: this.getCompanyName,
                language: 'sv',
            });

            const company_id = this.getCompany.company_id;

            if(this.getCompany.is_listed) {
                await this.loadCompanyPrices(company_id);
            }

            await this.loadCompanyDetails();

            // await this.loadCompanies(this.getCompanyName);

            // this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            // this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            // this.pages = JSON.parse(JSON.stringify(this.getCompany.pages));
            // this.pagesCopy = JSON.parse(JSON.stringify(this.getCompany.pages));

            this.cssStyles = JSON.parse(JSON.stringify(this.getCompany.css_styles));
            this.cssStylesCopy = JSON.parse(JSON.stringify(this.getCompany.css_styles));
        },
    },
}
</script>

<style scoped>
>>> .CodeMirror {
    height: calc(100vh - 140px);
}
</style>
