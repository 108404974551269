<template lang="pug">
    div(v-if="getLoggedIn" class="mr-2" style="max-width:1230px; overflow:scroll; border:0px solid;")
        div(style="width:1230px; border:0px solid red;")
            div(style="height:337px; border:0px solid green;")
                div(class="pa-2" style="float:left; background-color:#E9E9E9; width:600px; height:330px; border:0px solid;")
                    div(class="mb-1" style="font-family:'Times New Roman', Times, serif; font-size:20px; color:#646464; height:20px; border:0px solid;")
                            div(style="float:left; position:relative; height:20px; width:470px; border:0px solid;")
                                div(style="position:absolute; bottom:-6px;") Information
                            div(style="float:left; position:relative; height:20px; width:112px; border:0px solid;")
                                v-btn(@click="openEditCompanyInformation()" height="20" width="70" style="position:absolute; right:0; font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Edit
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Name:
                        div(style="float:left; height:20px; border:0px solid;") {{ getCompany.name }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Org.nr:
                        div(style="float:left; height:20px; border:0px solid;") {{ getCompany.org_nr }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:40px; border:0px solid;")
                        div(style="float:left; height:40px; width:150px; border:0px solid;") Address:
                        div(style="float:left; height:40px; border:0px solid;")
                            div(v-html="getCompany.post_address")
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Telephone:
                        div(style="float:left; height:20px; border:0px solid;") {{ getCompany.ir_telephone }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") E-mail:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="`mailto:${getCompany.ir_email}`") {{ getCompany.ir_email }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Web Page:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="getCompany.ir_url" target="_blank") {{ getCompany.ir_url }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Home Page:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="getCompany.home_url" target="_blank") {{ getCompany.home_url }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Youtube link 1:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="`https://www.youtube.com/watch?v=${getCompany.video_url_1}`" target="_blank") {{ `https://www.youtube.com/watch?v=${getCompany.video_url_1}` }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Youtube link 2:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="`https://www.youtube.com/watch?v=${getCompany.video_url_2}`" target="_blank") {{ `https://www.youtube.com/watch?v=${getCompany.video_url_2}` }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") LinkedIn:
                        div(style="float:left; height:20px; border:0px solid;")
                            a(:href="getCompany.linkedin" target="_blank") {{ getCompany.linkedin }}
                    div(class="mb-1 ml-6" style="font-family:'Courier New'; font-size:12px; height:20px; border:0px solid;")
                        div(style="float:left; height:20px; width:150px; border:0px solid;") Browser Tab Text:
                        div(style="float:left; height:20px; border:0px solid;") {{ getCompany.browser_tab_text }}

                div(class="ml-6" style="float:left; width:600px; border:0px solid;")
                    div(class="pb-2 pt-4 pl-4" style="background-color:#E9E9E9; border:0px solid;")
                        div(style="display:table;")
                            div(style="display:table-cell; width:130px; border:0px solid;")
                                ImageUpload(@saveImage="saveImage" :encodedImage="getCompany.logo_img" :updateField="'logoImage'")
                            div(style="display:table-cell; width:300px; border:0px solid;")
                                ImageUpload(@saveImage="saveImage" :encodedImage="getCompany.banner_img" :updateField="'bannerImage'")
                            div(style="display:table-cell; border:0px solid;")
                                ImageUpload(@saveImage="saveImage" :encodedImage="getCompany.favicon" :updateField="'faviconImage'")
                    div(class="mt-4 pa-2" style="position:relative; background-color:#E9E9E9; height:146px; border:0px solid;")
                        div(style="float:left; font-family:'Courier New'; font-size:12px; color:#646464; width:150px; border:0px solid;") IR Page Languages
                        div(style="float:left; width:200px; height:135px; overflow-y:scroll; overflow-x:hidden; border:0px solid red;")
                            div(v-for="language in getCompany.languages_available" style="height:25px; border:0px solid;")
                                div(style="float:left; width:30px; height:25px; padding-top:1px; border:0px solid;")
                                    img(:src="require(`@/assets/${languageImage(language.language)}`)" style="width:20px;")
                                div(style="float:left; font-family:'Courier New'; font-size:12px; width:160px; height:25px; padding-top:3px; border:0px solid;") {{ languageTitle(language.language) }} ({{ language.language }})
                        v-btn(@click="dialog = true" :outlined="true" height="20" width="150" style="position:absolute; bottom:10px; right:10px; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") Add a language
            div(class="mt-5" style="height:252px; border:0px solid;")
                div(class="pa-2" style="float:left; width:600px; min-height:250px; background-color:#E9E9E9; border:0px solid;")
                    div(style="font-family:'Times New Roman', Times, serif; font-size:20px; color:#646464; border:0px solid;") Quick Pitch
                    div(style="border:0px solid;")
                        div(style="float:left; width:435px; border:0px solid;")
                            div(class="ml-6")
                                div(class="ir-overview-main-regular")
                                    h2 {{ getCompany.pitch_title }}
                                    div(style="font-size:10px; overflow-y:scroll; overflow-x:hidden; border:0px solid;") {{ getCompany.pitch_detail }}

                                template(v-if="showEditPitch")
                                    div(v-for="company in companies" class="mt-2" style="border:0px solid;")
                                        div(style="font-size:12px; color:#000000; border:0px solid;") {{ languageTitle(company.language) }}
                                        div(style="border:0px solid;")
                                            input(type="text" v-model="company.pitch_title" class="pl-1 pr-1" style="font-size:10px; width:100%; background-color:#FFFFFF; border:1px solid lightgrey;")
                                            textarea(rows="6" v-model="company.pitch_detail" class="pl-1 pr-1" style="font-size:10px; width:100%; background-color:#FFFFFF; resize:none; border:1px solid lightgrey;")
                                        div(class="text-right" style="border:0px solid;")
                                            v-btn(@click="savePitch(company)" height="20" width="70" :disabled="checkIfTitleHasChanged(company)" :style="checkIfTitleHasChanged(company) ? 'border:1px solid #7C7C7C;' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save

                        div(class="text-right" style="float:left; width:145px; border:0px solid;")
                            div(style="border:0px solid;")
                                v-btn(@click="showEditPitch = true" height="20" width="70" :disabled="showEditPitch" :style="showEditPitch ? 'border:1px solid #7C7C7C;' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Edit
                            div(v-if="showEditPitch" @click="showEditPitch = false" class="mt-4 mr-3" style="font-family:Poppins; font-size:12px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel

                div(class="ml-6 pa-2" style="float:left; width:600px; height:250px; background-color:#E9E9E9; border:0px solid;")
                    div(style="font-family:'Times New Roman', Times, serif; font-size:20px; color:#646464; border:0px solid;") Title Image
                    div(class="pl-6 mb-2" style="position:relative; border:0px solid;")
                        div(class="mr-2" style="font-family:'Courier New'; font-size:12px;") Goes to...
                        v-select(
                            @change="saveBespokePage()"
                            item-text="menu_display"
                            item-value="page_id"
                            v-model="selectedPageId"
                            :items="getCompany.pages"
                            solo
                            flat
                            dense
                            outlined
                            style="position:absolute; top:0; left:110px; width:260px;"
                        )
                    div(class="pt-1" style="border:0px solid;")
                        div(class="pl-6" style="float:left; width:400px; height:185px; overflow-y:scroll; border:0px solid;")

                            div(v-for="company in getCompanies" class="mr-2 mb-2" style="float:left; width:142px; border:0px solid;")
                                div(style="font-family:'Courier New'; font-size:12px; border:0px solid;") {{ languageTitle(company.language) }}
                                div(style="border:0px solid;")
                                    ImageUpload(@saveImage="saveImage" :id="company.title_image_id" :encodedImage="company.image" :company="company" :updateField="'titleImage'" :language="company.language")


        div(class="text-center" style="border-radius:0;")
            v-dialog(v-model="dialog" max-width="175" overlay-opacity="0")
                v-card(style="height:175px;")
                    v-card-text(class="pa-0 ma-0" style="border:0px solid;")
                        div(class="pt-4" style="font-family:Courier; border:0px solid;")

                            div(v-for="language in languages" class="ml-4" style="line-height:1; border:0px solid;")
                                div(style="height:20px; border:0px solid;")
                                    div(style="float:left; height:20px; width:12px; border:0px solid;")
                                        input(type="checkbox" v-model="language.selected" style="width:12px; height:12px;")
                                    div(class="ml-2" style="float:left; height:20px; font-size:12px; border:0px solid;") {{ language.text }}

                        div(class="text-right pr-4" style="border:0px solid;")
                            v-btn(@click="saveLanguages()" height="20" width="70" :disabled="changesMadeInLanguages" :style="changesMadeInLanguages ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Done

        div(class="text-center" style="border-radius:0;")
            v-dialog(v-model="dialog2" max-width="400" overlay-opacity="0")
                v-card(style="min-height:210px;")
                    v-card-text(class="pa-2 ma-0" style="background-color:#E9E9E9; border:0px solid;")
                        div(style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Name:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.name" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Org.nr:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.org_nr" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px; border:0px solid;")
                            div(style="display:table-cell; height:70px; border:0px solid;")
                                div(style="width:100px; border:0px solid;") Address:
                            div(style="display:table-cell; position:relative; height:70px; border:0px solid;")
                                div(style="position:absolute; overflow:hidden; border:0px solid;")
                                    textarea(type="text" rows="3" v-model="company.post_address" class="ml-1 pl-1 pr-1" style="width:280px; background-color:#FFFFFF; resize:none; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Telephone:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.ir_telephone" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") E-mail:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.ir_email" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Web Page:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.ir_url" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Home Page:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.home_url" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Youtube link 1:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.video_url_1" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Youtube link 2:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.video_url_2" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px; border:0px solid;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") LinkedIn:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.linkedin" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px; border:0px solid;")
                            div(style="display:table-cell; height:20px;")
                                div(style="width:100px; border:0px solid;") Browser Tab Text:
                            div(style="display:table-cell; height:20px;")
                                input(type="text" v-model="company.browser_tab_text" class="ml-1 pl-1 pr-1" style="width:280px; height:20px; background-color:#FFFFFF; border:1px solid lightgrey;")
                        div(class="mt-1" style="display:table; font-size:12px; border:0px solid;")
                            div(class="text-right" style="display:table-cell; height:20px; width:400px; border:0px solid;")
                                v-btn(@click="saveCompanyInformation()" height="20" width="70" :disabled="changesMadeInCompanyInformation" :style="changesMadeInCompanyInformation ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
</template>

<script>
import { getLanguageHeadlines, getSelectedLanguages } from "./../../helpers/util";
import ImageUpload from "./helpers/ImageUpload.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "IRHome",
    components: {
        ImageUpload,
    },
    data() {
        return {
            selectedPageId: 0,
            selectedCity: '',
            dialog: false,
            dialog2: false,
            showEditPitch: false,
            pitchTitle: '',
            languageHeadlines: getLanguageHeadlines(),
            languages: getSelectedLanguages(),
            languagesCopy: [],
            languagesString: '',
            languagesStringCopy: '',
            company: [],
            companyCopy: [],
            companies: [],
            companiesCopy: [],
            uploadedBase64EncodedImage: '',
        };
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.getCompanies)));

        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        this.languages = this.languages.map((l) => {
            this.getCompany.languages_available.map((c) => {
                if(l.code === c.language) {
                    l.selected = true;
                }
            });

            return l;
        });

        this.languagesCopy = JSON.parse(JSON.stringify(this.languages));

        this.languagesString = JSON.stringify(this.languages);
        this.languagesStringCopy = JSON.stringify(this.languages);
        this.selectedPageId = Number(this.getCompany.page_id);

        console.log(JSON.parse(JSON.stringify('languages', this.languages)));

        // console.log(JSON.parse(JSON.stringify(this.getCompany)));
        // console.log(JSON.parse(JSON.stringify(this.companies)));
    },
    computed: {
        ...mapGetters(['getCompany', 'getLoggedIn', 'getCompanies']),
        changesMadeInLanguages() {
            this.languagesString = JSON.stringify(this.languages);

            return (this.languagesString === this.languagesStringCopy);
        },
        changesMadeInCompanyInformation() {
            const name = this.company.name;
            const video_url_1 = this.company.video_url_1;
            const video_url_2 = this.company.video_url_2;
            const post_address = this.company.post_address;
            const home_url = this.company.home_url;
            const ir_url = this.company.ir_url;
            const ir_email = this.company.ir_email;
            const ir_telephone = this.company.ir_telephone;
            const org_nr = this.company.org_nr;
            const insider_trade_isins = this.company.insider_trade_isins;
            const linked_in = this.company.linkedin;
            const browser_tab_text = this.company.browser_tab_text;

            const nameCopy = this.companyCopy.name;
            const video_url_1Copy = this.companyCopy.video_url_1;
            const video_url_2Copy = this.companyCopy.video_url_2;
            const post_addressCopy = this.companyCopy.post_address;
            const home_urlCopy = this.companyCopy.home_url;
            const ir_urlCopy = this.companyCopy.ir_url;
            const ir_emailCopy = this.companyCopy.ir_email;
            const ir_telephoneCopy = this.companyCopy.ir_telephone;
            const org_nrCopy = this.companyCopy.org_nr;
            const insider_trade_isinsCopy = this.companyCopy.insider_trade_isins;
            const linked_inCopy = this.companyCopy.linkedin;
            const browser_tab_textCopy = this.companyCopy.browser_tab_text;

            return (
                name === nameCopy &&
                video_url_1 === video_url_1Copy &&
                video_url_2 === video_url_2Copy &&
                post_address === post_addressCopy &&
                home_url === home_urlCopy &&
                ir_url === ir_urlCopy &&
                ir_email === ir_emailCopy &&
                ir_telephone === ir_telephoneCopy &&
                org_nr === org_nrCopy &&
                insider_trade_isins === insider_trade_isinsCopy &&
                linked_in === linked_inCopy &&
                browser_tab_text === browser_tab_textCopy
            );
        },
    },
    methods: {
        ...mapActions(['updateLogoImage', 'updateTitleImage', 'updatePitch', 'addLanguage', 'updateBannerImage', 'updateCompanyInformation', 'updateFaviconImage']),
        ...mapMutations(['setShowSnackbar']),
        async saveCompanyInformation() {
            const saveObject = {
                company_id: this.company.company_id,
                name: this.company.name,
                video_url_1: this.company.video_url_1,
                video_url_2: this.company.video_url_2,
                post_address: this.company.post_address,
                home_url: this.company.home_url,
                ir_url: this.company.ir_url,
                ir_email: this.company.ir_email,
                ir_telephone: this.company.ir_telephone,
                org_nr: this.company.org_nr,
                insider_trade_isins: null,
                linked_in: this.company.linkedin,
                browser_tab_text: this.company.browser_tab_text,
            };

            // console.log(saveObject);

            const result = await this.updateCompanyInformation(saveObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated company information',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            document.title = this.getCompany.browser_tab_text;

            this.dialog2 = false;

            this.company = JSON.parse(JSON.stringify(this.getCompany));
            this.companyCopy = JSON.parse(JSON.stringify(this.getCompany));
        },
        openEditCompanyInformation() {
            this.company = JSON.parse(JSON.stringify(this.getCompany));
            this.companyCopy = JSON.parse(JSON.stringify(this.getCompany));

            console.log(JSON.parse(JSON.stringify(this.company)));

            this.dialog2 = true;
        },
        checkIfTitleHasChanged(company) {
            const pitch_title = company.pitch_title;
            const pitch_title_copy = this.companiesCopy.find((c) => c.language === company.language).pitch_title;

            const pitch_detail = company.pitch_detail;
            const pitch_detail_copy = this.companiesCopy.find((c) => c.language === company.language).pitch_detail;

            return (pitch_title === pitch_title_copy && pitch_detail === pitch_detail_copy);
        },
        saveBespokePage() {
            console.log(this.selectedPageId);

            //TODO: Use the endpoint from backend to set bespoke page
        },
        languageTitle(code) {
            return this.languageHeadlines.find((l) => l.code === code).text;
        },
        languageImage(code) {
            console.log(code);
            console.log(this.languageHeadlines.find((l) => l.code === code).image);
            return this.languageHeadlines.find((l) => l.code === code).image;
        },
        async savePitch(company) {
            console.log(JSON.parse(JSON.stringify(company)));

            const pitchSaveObject = {
                entity_id: company.pitch_id,
                company_id: company.company_id,
                language: company.language,
                title: company.pitch_title,
                detail: company.pitch_detail,
            };

            console.log(pitchSaveObject);

            const result = await this.updatePitch(pitchSaveObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated the pitch',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));
        },
        async saveImage(imageString) {
            console.log(imageString);

            if(imageString.updateField === "logoImage") {
                // update logo image

                const saveObject = {
                    company_id: this.getCompany.company_id,
                    logo_image: imageString.image,
                };

                console.log('save image:', saveObject);

                const result = await this.updateLogoImage(saveObject);

                console.log(result);

                if(result.status === 0) {
                    this.setShowSnackbar({
                        show: true,
                        message: 'Successfully updated the logo image',
                        type: 'success',
                    });
                } else {
                    this.setShowSnackbar({
                        show: true,
                        message: result.message,
                        type: 'error',
                    });
                }
            } else if(imageString.updateField === "titleImage") {
                // update title image

                const saveObject = {
                    entity_id: imageString.id,
                    company_id: this.getCompany.company_id,
                    language: imageString.language,
                    image: imageString.image,
                };

                // console.log('save image:', saveObject);

                const result = await this.updateTitleImage(saveObject);

                console.log(JSON.parse(JSON.stringify(result)));

                if(result.status === 0) {
                    this.setShowSnackbar({
                        show: true,
                        message: 'Successfully updated the title image',
                        type: 'success',
                    });
                } else {
                    this.setShowSnackbar({
                        show: true,
                        message: result.message,
                        type: 'error',
                    });
                }
            } else if(imageString.updateField === "bannerImage") {
                // update banner image

                const saveObject = {
                    company_id: this.getCompany.company_id,
                    image: imageString.image,
                };

                // console.log(saveObject);

                const result = await this.updateBannerImage(saveObject);

                console.log(JSON.parse(JSON.stringify(result)));

                if(result.status === 0) {
                    this.setShowSnackbar({
                        show: true,
                        message: 'Successfully updated the banner image',
                        type: 'success',
                    });
                } else {
                    this.setShowSnackbar({
                        show: true,
                        message: result.message,
                        type: 'error',
                    });
                }
            } else if(imageString.updateField === "faviconImage") {
                const saveObject = {
                    company_id: this.getCompany.company_id,
                    favicon_image: imageString.image,
                };

                // console.log(saveObject);

                const result = await this.updateFaviconImage(saveObject);

                console.log(JSON.parse(JSON.stringify(result)));

                if(result.status === 0) {
                    this.setShowSnackbar({
                        show: true,
                        message: 'Successfully updated the favicon image',
                        type: 'success',
                    });
                } else {
                    this.setShowSnackbar({
                        show: true,
                        message: result.message,
                        type: 'error',
                    });
                }

                let link = document.querySelector("link[rel~='icon']");

                if(!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.head.appendChild(link);
                }

                link.href = this.getCompany.favicon;

                this.company = JSON.parse(JSON.stringify(this.getCompany));
                this.companyCopy = JSON.parse(JSON.stringify(this.getCompany));
            }
        },
        async saveLanguages() {
            let resultObject = {
                status: -1,
                message: 'No language was saved'
            };

            for(const [i, language] of this.languages.entries()) {
                if(language.selected !== this.languagesCopy[i].selected) {
                    console.log('*********');
                    if(language.selected === false && this.languagesCopy[i].selected === true) {
                        const removeLanguageObject = {
                            company_id: this.getCompany.company_id,
                            language: language.code,
                        };

                        console.log(`remove language: ${language.code}`);
                        console.log('remove object', removeLanguageObject);
                    } else if(language.selected === true && this.languagesCopy[i].selected === false) {
                        const addLanguageObject = {
                            company_id: this.getCompany.company_id,
                            language: language.code,
                        };

                        const result = await this.addLanguage(addLanguageObject);

                        console.log(`add language: ${language.code}`);
                        console.log('add object', addLanguageObject);
                        console.log(result);

                        resultObject = result;
                    }
                    console.log('*********');
                }
            }

            if(resultObject.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully added language',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.languagesString = JSON.stringify(this.languages);
            this.languagesStringCopy = JSON.stringify(this.languages);

            this.languagesCopy = JSON.parse(JSON.stringify(this.languages));

            this.dialog = false;
        },
    },
}
</script>

<style scoped>
.v-btn {
    min-width: 0 !important;
}

>>> .v-text-field.v-text-field--enclosed .v-text-field__details,
>>> .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    padding-bottom: 4px;
    max-height: 20px;
    min-height: 20px;
}
</style>
