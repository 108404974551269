<template lang="pug">
    v-snackbar(v-model="snackbar" :timeout="timeout" centered style="z-index:9999999999999;")
        div {{ message }}
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// {
//     show: false,
//     message: '',
//     type: 'success',
// },

export default {
    name: "SmokeBar",
    props: {
        timeout: {
            type: Number,
            default: 1000
        }
    },
    data() {
        return {
            snackbar: false,
        };
    },
    computed: {
        ...mapGetters(['getShowSnackbar']),
        show() {
            return this.getShowSnackbar.show;
        },
        message() {
            return this.getShowSnackbar.message;
        },
        type() {
            return this.getShowSnackbar.type;
        },
    },
    watch: {
        show() {
            if(this.show) {
                this.snackbar = true;

                const showSnackbar = this.getShowSnackbar;

                showSnackbar.show = false;

                this.setShowSnackbar(showSnackbar);
            }
        },
    },
    methods: {
        ...mapMutations(['setShowSnackbar']),
    },
}
</script>

<style scoped>

</style>
