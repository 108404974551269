import Api from '@/services/Api';

export default {
    async getCompany(short_name: string, language: string): Promise<object> {
        return await Api().get(`/company/${short_name}/${language}`);
    },
    async getCompanyPrices(company_id: number): Promise<object> {
        return await Api().get(`/company-prices/${company_id}`);
    },
    async getCompanyDetails(company_id: number, language: string): Promise<object> {
        return await Api().get(`/company-details/${company_id}/${language}`);
    },
    async getCompanyNews(company_id: number, page: number): Promise<object> {
        return await Api().get(`/news/${company_id}/${page}`);
    },
    async setCeoComment(data: object) {
        return await Api().post('/stock-journal', data);
    },
    async setText(data: object) {
        return await Api().post('/text', data);
    },
    async updateTextTitleAndDescription(data: object) {
        return await Api().put('/comment-content', data);
    },
    async loginUser(data: object) {
        return await Api().post('/login', data);
    },
    async getCanonicalCName(data: object) {
        return await Api().post('/canonicalCName', data);
    },
    async insertNewBespokePage(data: object) {
        return await Api().post('/page', data);
    },
    async updateBespokePage(data: object) {
        return await Api().put('/page', data);
    },
    async updateCssStyles(data: object) {
        return await Api().put('/css-styles', data);
    },
    async updateLogoImage(data: object) {
        return await Api().put('/logo-image', data);
    },
    async updateBannerImage(data: object) {
        return await Api().put('/banner-image', data);
    },
    async updateTitleImage(data: object) {
        return await Api().put('/title-image', data);
    },
    async updatePitch(data: object) {
        return await Api().put('/pitch', data);
    },
    async addLanguage(data: object) {
        return await Api().post('/add-language', data);
    },
    async updateCompanyInformation(data: object) {
        return await Api().put('/company', data);
    },
    async updateWhyImage(data: object) {
        return await Api().put('/why-image', data);
    },
    async updateWhyTexts(data: object) {
        return await Api().put('/why', data);
    },
    async updateTeamBio(data: object) {
        return await Api().put('/team-bio', data);
    },
    async updateTeamNameEmail(data: object) {
        return await Api().put('/team', data);
    },
    async deleteTeamMember(team_id: number) {
        return await Api().delete(`/team/${team_id}`);
    },
    async updateTeamImage(data: object) {
        return await Api().put('/team-image', data);
    },
    async insertNewTeamMember(data: object) {
        return await Api().post('/team', data);
    },
    async updateCalendarTexts(data: object) {
        return await Api().put('/event-text', data);
    },
    async updateCalendarDateTime(data: object) {
        return await Api().put('/event', data);
    },
    async insertNewCalendar(data: object) {
        return await Api().post('/event', data);
    },
    async deleteCalendar(event_id: number) {
        return await Api().delete(`/event/${event_id}`);
    },
    async insertNewKpiItem(data: object) {
        return await Api().post('/kpii', data);
    },
    async updateKpiItem(data: object) {
        return await Api().put('/kpii', data);
    },
    async uploadMediaFile(data: object) {
        return await Api().post('/report', data);
    },
    async updateMediaFile(data: object) {
        return await Api().put('/report', data);
    },
    async addNewMarketComment(data: object) {
        return await Api().post('/comment', data);
    },
    async updateMarketCommentDate(data: object) {
        return await Api().put('/comment', data);
    },
    async deleteMarketComment(journal_id: number) {
        return await Api().delete(`/comment/${journal_id}`);
    },
    async updateKpiTitleAndDetail(data: object) {
        return await Api().put('/kpi', data);
    },
    async insertNewKpi(data: object) {
        return await Api().post('/kpi', data);
    },
    async updateFaviconImage(data: object) {
        return await Api().put('/favicon-image', data);
    },
    async deleteKpiItem(kpi_item_id: number) {
        return await Api().delete(`/kpii/${kpi_item_id}`);
    },
    async deleteKpi(kpi_id: number) {
        return await Api().delete(`/kpi/${kpi_id}`);
    },
    async openFile(data: object) {
        return await Api().post('/open-file', data);
    },
    async changePassword(data: object) {
        return await Api().post('/change-password', data);
    },
}
