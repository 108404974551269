<template lang="pug">
    div(v-if="getLoggedIn" class="mr-2" style="max-width:1350px; overflow:scroll; border:0px solid red;")
        div(style="width:1150px; border:0px solid;")
            div(style="display:table; border:0px solid blue;")
                div(style="display:table-cell; width:520px; border:0px solid;")
                    div(class="mb-6" style="border:0px solid green;")
                        v-btn(@click="addNewKpi()" height="25" elevation="0" style="font-family:Poppins; font-size:15px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;")
                            v-icon mdi-cog-outline
                            span(class="ml-3") Create new Custom KPI
                    div(v-for="kpi in kpis" :key="kpi.row_to_json.kpi_id" class="mb-2" style="height:255px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                        div(style="display:table; font-family:'Hind Vadodara'; width:520px; border:0px solid green;")
                            div(style="display:table-cell; font-size:16px; width:60%; border:0px solid;") {{ kpi.row_to_json.text_1 }}
                            div(class="text-right" style="display:table-cell; width:40%; border:0px solid;")
                                v-btn(@click="openEditKpi(kpi.row_to_json.kpi_id)" :disabled="kpiActive === kpi.row_to_json.kpi_id" height="20" width="70" :style="(kpiActive === kpi.row_to_json.kpi_id) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Edit
                        div(style="font-family:'Hind Vadodara'; width:520px; border:0px solid green;")
                            div(style="display:table; width:100%; border:0px solid lightblue;")
                                div(class="pr-9" style="display:table-cell; font-size:12px; width:50%; height:100%; border:0px solid;")
                                    span(v-html="getFirstSentence(kpi.row_to_json.text_2)")
                                div(style="display:table-cell; font-family:'IBM Plex Mono'; font-size:12px; width:50%; height:100%; border:0px solid;") Quarterly
                        div(v-if="kpiActive === kpi.row_to_json.kpi_id" style="font-family:'IBM Plex Mono'; font-size:12px; width:520px; border:0px solid;")
                            div(class="mb-6" style="display:table; border:0px solid;")
                                div(style="display:table-cell; width:230px; border:0px solid;")
                                    template(v-for="lang in getCompany.languages_available")
                                        template(v-for="kpilang in companies.find((c) => c.language === lang.language).kpis.filter((k) => k.row_to_json.kpi_id === kpi.row_to_json.kpi_id)")
                                            div(class="mb-2" style="border:0px solid;")
                                                div(style="border:0px solid;") {{ languageTitle(kpilang.row_to_json.language) }}
                                                div(style="border:0px solid;")
                                                    input(type="text" v-model="kpilang.row_to_json.text_1" style="width:100%; background-color:#FFFFFF; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                                div(class="mt-2" style="border:0px solid;")
                                                    textarea(rows="4" v-model="kpilang.row_to_json.text_2" style="width:100%; background-color:#FFFFFF; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                                div(class="text-right" style="border:0px solid;")
                                                    v-btn(@click="saveKpiTexts(kpilang.row_to_json)" height="20" width="70" :disabled="checkChangedHeadline(kpilang.row_to_json)" :style="checkChangedHeadline(kpilang.row_to_json) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                                div(style="position:relative; display:table-cell; width:180px; border:0px solid;")
                                    v-select(
                                        @change="saveQuaterly()"
                                        item-text="type"
                                        item-value="id"
                                        v-model="selectedId"
                                        :items="selectQuaterly"
                                        solo
                                        flat
                                        dense
                                        outlined
                                        style="position:absolute; top:18px; right:0; font-size:12px; width:150px;"
                                    )
                                div(class="text-right" style="position:relative; display:table-cell; width:110px; border:0px solid;")
                                    v-btn(height="20" width="70" style="position:absolute; top:18px; right:0; font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                                    div(@click="cancel()" style="position:absolute; bottom:50px; right:10px; font-family:Poppins; font-size:12px; background-color:#F9F8F8; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
                                    v-btn(@click="removeKpi(kpi.row_to_json.kpi_id)" height="20" width="70" style="position:absolute; bottom:8px; right:0; font-family:Poppins; font-size:12px; background-color:#EBEBEB; color:#27407E; text-transform:none; border:1px solid #27407E;") Delete
                div(style="display:table-cell; width:830px; border:0px solid lightgreen;")
                    div(style="height:50px; border:0px solid;") &nbsp;
                    template(v-for="kpi in kpis")
                        div(class="ml-2 pa-2 mb-2" style="height:255px; background-color:#E9E9E9; border:0px solid;")
                            div(style="display:table; font-family:'Courier New'; font-size:14px; border:0px solid;") {{ kpi.row_to_json.text_1 }}
                            div(style="display:table; font-family:'Courier New'; font-size:12px; font-weight:bold; border:0px solid;")
                                div(style="display:table-cell; width:80px; border:0px solid;") Period
                                div(style="display:table-cell; width:80px; border:0px solid;") Value
                                //- div(style="display:table-cell; width:180px; border:0px solid;") Comment
                                div(style="display:table-cell; width:60px; border:0px solid;")
                                //- div(class="pl-5" style="display:table-cell; width:430px; border:0px solid;") Schedule
                            div(style="display:table; font-family:'Courier'; font-size:12px; border:0px solid;")
                                div(style="display:table-cell; vertical-align:top; width:400px; border:0px solid;")
                                    div(style="max-height:200px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                                        template(v-for="val in kpi.row_to_json.values")
                                            div(style="display:table; height:25px; border:0px solid;")
                                                div(style="display:table-cell; width:80px; border:0px solid;") {{ val.period }}
                                                div(style="display:table-cell; width:80px; border:0px solid;") {{ val.value }}
                                                //- div(style="display:table-cell; width:180px; border:0px solid;")
                                                    //- It is not added yet for the moment.
                                                    //- Will it be added later?
                                                    //- div Kommentera om värde
                                                div(style="display:table-cell; position:relative; width:60px; border:0px solid;")
                                                    img(@click="openEditKpiPopup(kpi, val)" src="@/assets/edit-kpi-icon.svg" style="position:absolute; height:16px; cursor:pointer;")
                                div(style="display:table-cell; border:0px solid;")
                                    div(style="display:table; height:200px; border:0px solid;")
                                        //- div(style="display:table-cell; vertical-align:middle; border:0px solid;")
                                            div(style="width:100%; border:0px solid;")
                                                input(type="datetime-local" v-model="dateTime" style="background-color:#FFFFFF; height:10px;")
                                            div(class="text-right mt-1")
                                                v-btn(@click="saveReleaseDateAndTime()" height="20" width="70" :disabled="true" :style="'border:1px solid #7C7C7C;'" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                                        div(style="display:table-cell; width:165px; border:0px solid;")
                                            div(class="d-flex align-end justify-end" style="height:50%; border:0px solid;")
                                                div(style="width:120px; border:0px solid;")
                                                    CanvasGeneric(v-if="showChart" :values="kpi.row_to_json.values" :label="kpi.row_to_json.text_1" :color="'#3886E4'" style="width:100%; height:80px; border:0px solid;")
                                            div(class="d-flex align-end justify-end" style="height:50%; border:0px solid;")
                                                div(style="border:0px solid;")
                                                    v-btn(@click="addNewKpiItem(kpi.row_to_json)" height="20" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") add new KPI

        v-dialog(v-model="dialog" max-width="290" overlay-opacity="0")
            v-card
                v-card-text(class="pa-0 ma-0" style="border:0px solid;")
                    div(class="pa-2" style="font-family:'Courier'; font-size:12px; background-color:#ebebeb; box-shadow:4px 4px 8px 1px rgba(0, 0, 0, 0.15); border:1px solid lightgrey;")
                        div(style="display:table; border:0px solid;")
                            div(style="display:table-cell; border:0px solid;")
                                div(style="display:table; border:0px solid;")
                                    div(style="display:table-cell; width:100px; border:0px solid;") Period
                                    div(style="display:table-cell; width:100px; border:0px solid;") Value
                                    //- div(style="display:table-cell; width:220px; color:grey; border:0px solid;") Comment
                                div(style="display:table; border:0px solid;")
                                    div(style="display:table-cell; width:100px; border:0px solid;")
                                        input(type="text" v-model="editKpi.period" class="pl-1 pr-1" style="background-color:#FFFFFF; width:80px; border:1px solid:lightgrey;")
                                    div(style="display:table-cell; width:100px; border:0px solid;")
                                        input(type="text" v-model="editKpi.value" class="pl-1 pr-1" style="background-color:#FFFFFF; width:80px; border:1px solid:lightgrey;")
                                    //- div(style="display:table-cell; width:220px; border:0px solid;")
                                        div(style="display:table; border:0px solid;")
                                            div(style="display:table-cell; width:220px; border:0px solid;")
                                                div(v-for="lang in languagesAvailable" class="mb-1" style="border:0px solid;")
                                                    span(class="mr-1" style="color:grey;") {{ lang.language }}
                                                    input(type="text" class="pl-1 pr-1" :disabled="true" style="background-color:#e5e4e4; width:180px; border:1px solid:lightgrey;")

                            div(style="display:table-cell; position:relative; width:70px; border:0px solid;")
                                v-btn(@click="saveKpiValue()" height="20" width="70" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                                v-btn(@click="removeKpiValue()" height="20" width="70" style="position:absolute; bottom:0; left:0; font-family:Poppins; font-size:12px; background-color:#EBEBEB; color:#27407E; text-transform:none; border:1px solid #27407E;") Delete
</template>

<script>
import CanvasGeneric from "@/components/CanvasGeneric.vue";
import { getLanguageHeadlines } from "./../../helpers/util";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
    name: "CustomKpis",
    components: {
        CanvasGeneric
    },
    data() {
        return {
            showChart: true,
            companies: [],
            companiesCopy: [],
            kpis: [],
            kpisCopy: [],
            kpiActive: 0,
            selectedId: 1,
            dateTime: '',
            dialog: false,
            editKpi: {},
            editKpiCopy: {},
            selectedKpi: {},
            selectQuaterly: [
                {
                    id: 4,
                    type: 'Quaterly',
                },
                {
                    id: 3,
                    type: 'Yearly',
                },
            ],
            languageHeadlines: getLanguageHeadlines(),
        };
    },
    created() {
        this.kpis = JSON.parse(JSON.stringify(this.getKpis));
        this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));

        console.log('kpis');
        console.log('kpis');
        console.log('kpis');
        console.log('kpis');
        console.log(JSON.parse(JSON.stringify(this.kpis)));
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        this.dateTime = moment(new Date()).format('YYYY-MM-DDTHH:mm');

        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        // console.log(this.companies.find((c) => c.language === 'sv').kpis.find((k) => k.row_to_json.kpi_id === 8)).language;
        // console.log(this.companies.find((c) => c.language === 'sv').kpis.find((k) => k.row_to_json.kpi_id === 8)).text_2;
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies']),
        getKpis() {
            return this.getCompany.kpis;
        },
        languagesAvailable() {
            return this.getCompany.languages_available;
        },
        checkIfValueOrPeriodChanged() {
            if(this.editKpi.value !== undefined) {
                const period = this.editKpi.period;
                const value = this.editKpi.value.toString();

                const periodCopy = this.editKpiCopy.period;
                const valueCopy = this.editKpiCopy.value.toString();

                return (period === periodCopy && value === valueCopy);
            }

            return true;
        }
    },
    methods: {
        ...mapActions(['insertNewKpiItem', 'updateKpiItem', 'updateKpiTitleAndDetail', 'insertNewKpi', 'deleteKpiItem', 'deleteKpi']),
        ...mapMutations(['setShowSnackbar']),
        async addNewKpiItem(kpi) {
            const year = moment().year().toString();

            const insertObject = {
                company_id: kpi.company_id,
                kpi_id: kpi.kpi_id,
                kpi_item_id: null,
                period: year,
                value: 0
            };

            // console.log(insertObject);

            const result = await this.insertNewKpiItem(insertObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully inserted new kpi item',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.showChart = false;

            setTimeout(() => {
                this.showChart = true;
            }, 1);

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));
        },
        async saveKpiValue() {
            console.log('selectedKpi', JSON.parse(JSON.stringify(this.selectedKpi)));
            console.log('editKpi', JSON.parse(JSON.stringify(this.editKpi)));

            const updateObject = {
                company_id: this.selectedKpi.row_to_json.company_id,
                kpi_item_id: this.editKpi.kpi_item_id,
                kpi_id: this.selectedKpi.row_to_json.kpi_id,
                period: this.editKpi.period,
                value: this.editKpi.value,
            };

            console.log(updateObject);

            const result = await this.updateKpiItem(updateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.showChart = false;

            setTimeout(() => {
                this.showChart = true;
            }, 1);

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));

            this.dialog = false;
        },
        async removeKpiValue() {
            const kpi_item_id = this.editKpi.kpi_item_id;

            const result = await this.deleteKpiItem(kpi_item_id);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully deleted kpi item',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.showChart = false;

            setTimeout(() => {
                this.showChart = true;
            }, 1);

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));

            this.dialog = false;
        },
        openEditKpiPopup(kpi, val) {
            console.log('open edit here...');
            console.log('kpi', JSON.parse(JSON.stringify(kpi)));
            console.log('val', JSON.parse(JSON.stringify(val)));

            this.editKpi = JSON.parse(JSON.stringify(val));
            this.editKpiCopy = JSON.parse(JSON.stringify(val));
            this.selectedKpi = JSON.parse(JSON.stringify(kpi));

            this.dialog = true;
        },
        cancel() {
            this.kpiActive = 0;
            this.kpis = JSON.parse(JSON.stringify(this.kpisCopy));
            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));
        },
        async removeKpi(kpi_id) {
            const result = await this.deleteKpi(kpi_id);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully deleted kpi',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));
        },
        async addNewKpi() {
            const insertNewKpiObject = {
                company_id: Number(this.getCompany.company_id),
            };

            // console.log(insertNewKpiObject);

            const result = await this.insertNewKpi(insertNewKpiObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully added new kpi',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));
        },
        async saveKpiTexts(kpi) {
            const updateKpiText = {
                company_id: Number(this.getCompany.company_id),
                kpi_id: Number(kpi.kpi_id),
                language: kpi.language,
                title: kpi.text_1,
                detail: kpi.text_2,
            };

            // console.log(updateKpiText);

            const result = await this.updateKpiTitleAndDetail(updateKpiText);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated kpi texts',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.kpis = JSON.parse(JSON.stringify(this.getKpis));
            this.kpisCopy = JSON.parse(JSON.stringify(this.getKpis));
        },
        checkChangedHeadline(kpi) {
            const kpiCopy = this.companiesCopy.find((c) => c.language === kpi.language).kpis.find((k) => k.row_to_json.kpi_id === kpi.kpi_id && k.row_to_json.language === kpi.language);

            return (
                kpiCopy.row_to_json.text_1 === kpi.text_1 &&
                kpiCopy.row_to_json.text_2 === kpi.text_2
            );
        },
        openEditKpi(kpi_id) {
            console.log(kpi_id);

            this.kpiActive = kpi_id;
        },
        getFirstSentence(text) {
            const sentence = text.split(".")[0];

            return `${sentence}.`;
        },
        saveQuaterly() {
            console.log(this.selectedId);
        },
        languageTitle(code) {
            return this.languageHeadlines.find((l) => l.code === code).text;
        },
        saveReleaseDateAndTime() {
            console.log(this.dateTime);

            //TODO: Need a new date column to be able to save the schedule date
            // For the moment it's removed. Will add it when it's available in backend
        },
    },
}
</script>

<style scoped>
textarea {
    resize: none;
}

>>> .v-text-field.v-text-field--enclosed .v-text-field__details, 
>>> .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    padding-bottom: 4px;
    max-height: 20px;
    min-height: 20px;
}
</style>
