<template lang="pug">
    div(id="header-mobile-element" class="ir-menu-mobile")
        v-app-bar(class="ir-navbar-mobile")
            div(style="display:table; width:100%; border:0px solid;")
                div(style="display:table-cell; width:50%; border:0px solid;")
                    v-icon(@click.stop="drawer = !drawer" class="ir-navbar-text-mobile") mdi-menu
                div(class="text-right" style="display:table-cell; position:relative; width:50%; border:0px solid;")
                    img(@click.stop="drawer2 = !drawer2" :src="getSelectedLanguage.icon" style="position:absolute; right:0; top:4px; height:15px; border:0px solid;")

        //- left menu options
        v-navigation-drawer(v-model="drawer" class="navigation-drawer" :style="`height:${drawerHeight}px !important;`")
            template(v-if="getIsListed")
                div(@click="temporarilyHideCanvasGeneric(0, 'headline-overview')" :class="(activeOption === 0) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_overview') }}
                div(@click="temporarilyHideCanvasGeneric(1, 'headline-invest-in-us')" :class="(activeOption === 1) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_why_invest_in_us') }}
                div(@click="temporarilyHideCanvasGeneric(2, 'headline-leadership-team')" :class="(activeOption === 2) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_leadership_and_board') }}
                div(@click="temporarilyHideCanvasGeneric(3, 'headline-press-releases-and-news')" :class="(activeOption === 3) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_press_releases_and_news') }}
                div(@click="temporarilyHideCanvasGeneric(4, 'headline-earnings-and-reports')" :class="(activeOption === 4) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_reports_and_media') }}
                div(@click="temporarilyHideCanvasGeneric(5, 'headline-company-calendar')" :class="(activeOption === 5) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_company_calendar') }}
                div(@click="temporarilyHideCanvasGeneric(6, 'headline-corporate')" :class="(activeOption === 6) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_corporate') }}

            template(v-else)
                div(@click="temporarilyHideCanvasGeneric(0, 'headline-overview')" :class="(activeOption === 0) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_overview') }}
                div(@click="temporarilyHideCanvasGeneric(1, 'headline-invest-in-us')" :class="(activeOption === 1) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_why_invest_in_us') }}
                div(@click="temporarilyHideCanvasGeneric(2, 'headline-leadership-team')" :class="(activeOption === 2) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('ul_menu_management_and_board') }}
                div(@click="temporarilyHideCanvasGeneric(3, 'headline-company-calendar')" :class="(activeOption === 3) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_company_calendar') }}
                div(@click="temporarilyHideCanvasGeneric(4, 'headline-corporate')" :class="(activeOption === 4) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ $t('menu_corporate') }}

            //- listing of generic pages
            div(v-for="(page, index) in pages" :key="index" @click="temporarilyHideCanvasGeneric((7 + index), 'headline-generic-component'), setGenericHtml(page)" :class="(activeOption === (7 + index)) ? 'ir-menu-drawer-active-mobile' : ''" class="ir-menu-drawer-hover-mobile pl-2") {{ page.menu_display }}

        //- select language options
        v-navigation-drawer(v-model="drawer2" class="navigation-drawer" :style="`height:${drawerHeight}px !important;`")
            div(v-for="lang in getLanguages" :key="lang.language" class="pl-2" :class="activeOptionLanguage === lang.language ? 'ir-menu-drawer-active-mobile' : ''" style="border:0px solid;")
                div(@click="setLanguage(lang.language), activeOptionLanguage = lang.language" class="pt-2" style="display:table; height:50px; border:0px solid;")
                    div(style="display:table-cell; border:0px solid;")
                        img(:src="lang.icon" style="width:30px;")
                    div(class="pl-3" style="display:table-cell; border:0px solid;")
                        div(style="font-size:24px;") {{ lang.title }}
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getLanguagesForLanguageSelector } from "./../../helpers/util";
import i18n from "./../../plugins/i18n";

export default {
    name: "MenuMobile",
    data() {
        return {
            drawer: false,
            drawer2: false,
            activeOption: 0,
            activeOptionLanguage: '',
            selectedLanguageCode: null,
            items: getLanguagesForLanguageSelector(),
        };
    },
    created() {
        this.selectedLanguageCode = localStorage.getItem('language');
        this.activeOptionLanguage = this.selectedLanguageCode;
    },
    computed: {
        ...mapGetters(['getCompany', 'getGenericPage', 'getCompanyName', 'getIsListed']),
        pages() {
            return this.getCompany.pages;
        },
        drawerHeight() {
            const extraHeight = this.pages.length * 20;

            return 195 + extraHeight;
        },
        languagesAvailable() {
            return this.getCompany.languages_available;
        },
        getSelectedLanguage() {
            return this.items.find((l) => l.language.toLowerCase() === this.selectedLanguageCode.toLowerCase());
        },
        getLanguages() {
            return this.items.filter((i) => {
                return this.languagesAvailable.find((l) => l.language === i.language);
            });
        },
    },
    methods: {
        ...mapActions(['loadCompany', 'loadCompanyPrices', 'loadCompanyDetails']),
        ...mapMutations(['setGenericPage', 'setLanguageCode', 'setShowCanvasGenericMobile']),
        temporarilyHideCanvasGeneric(option, key) {
            this.setShowCanvasGenericMobile(false);

            setTimeout(() => {
                this.activeOption = option;
                this.openRouter(key);
            }, 1);

            setTimeout(() => {
                this.setShowCanvasGenericMobile(true);
            }, 1500);
        },
        openRouter(el) {
            this.$router.push({ path: '/x' });
            this.$router.push({ path: '/' });

            setTimeout(() => {
                const element = document.getElementById(el);

                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    // inline: 'center'
                });
            }, 50);
        },
        setGenericHtml(page) {
            this.setGenericPage(page);

            console.log(JSON.parse(JSON.stringify(this.getGenericPage)));
        },
        async setLanguage(language) {
            localStorage.setItem('language', language);

            this.selectedLanguageCode = localStorage.getItem('language');

            console.log(this.selectedLanguageCode);

            console.log({
                short_name: this.getCompanyName,
                language: this.selectedLanguageCode,
            });

            await this.loadCompany({
                short_name: this.getCompanyName,
                language: this.selectedLanguageCode,
            });

            const company_id = this.getCompany.company_id;

            if(this.getCompany.is_listed) {
                await this.loadCompanyPrices(company_id);
            }

            await this.loadCompanyDetails();

            i18n.locale = this.selectedLanguageCode;

            this.setLanguageCode(this.selectedLanguageCode);
        }
    }
};
</script>

<style scoped>
.navigation-drawer {
    position: absolute;
    top: 56px !important;
    width: 100% !important;
}
</style>
