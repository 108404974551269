/* eslint-disable */
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import CompanyCalendar from "@/views/content/regular/CompanyCalendar.vue";
// import Overview from "@/views/content/regular/Overview.vue";
import OverviewPlaceholder from "@/views/content/regular/OverviewPlaceholder.vue";
import InvestInUs from "@/views/content/regular/InvestInUs.vue";
import EarningsAndReports from "@/views/content/regular/EarningsAndReports.vue";
import LeadershipTeam from "@/views/content/regular/LeadershipTeam.vue";
import PressReleasesAndNews from "@/views/content/regular/PressReleasesAndNews.vue";
import Corporate from "@/views/content/regular/Corporate.vue";
import GenericComponent from "@/views/content/regular/GenericComponent.vue";
import IRHome from "@/components/backoffice/IRHome.vue";
import InvestmentCase from "@/components/backoffice/InvestmentCase.vue";
import Leadership from "@/components/backoffice/Leadership.vue";
import PressReleases from "@/components/backoffice/PressReleases.vue";
import Calendar from "@/components/backoffice/Calendar.vue";
import ReportsAndMedia from "@/components/backoffice/ReportsAndMedia.vue";
import BespokePage from "@/components/backoffice/BespokePage.vue";
import LoginPage from "@/components/backoffice/LoginPage.vue";
import CustomKpis from "@/components/backoffice/CustomKpis.vue";
import MarketCommentary from "@/components/backoffice/MarketCommentary.vue";
import FinancialHistory from "@/components/backoffice/FinancialHistory.vue";
import StylingCss from "@/components/backoffice/StylingCss.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    // backoffice
    {
        path: "/backoffice/login",
        name: "login",
        component: LoginPage,
    },
    {
        path: "/backoffice/irHome",
        name: "irHome",
        component: IRHome,
    },
    {
        path: "/backoffice/investmentCase",
        name: "investmentCase",
        component: InvestmentCase,
    },
    {
        path: "/backoffice/leadership",
        name: "leadership",
        component: Leadership,
    },
    {
        path: "/backoffice/pressReleases",
        name: "pressReleases",
        component: PressReleases,
    },
    {
        path: "/backoffice/calendar",
        name: "calendar",
        component: Calendar,
    },
    {
        path: "/backoffice/reportsAndMedia",
        name: "reportsAndMedia",
        component: ReportsAndMedia,
    },
    {
        path: "/backoffice/bespokePage",
        name: "bespokePage",
        component: BespokePage,
    },
    {
        path: "/backoffice/customKpis",
        name: "customKpis",
        component: CustomKpis,
    },
    {
        path: "/backoffice/marketCommentary",
        name: "marketCommentary",
        component: MarketCommentary,
    },
    {
        path: "/backoffice/financialHistory",
        name: "financialHistory",
        component: FinancialHistory,
    },
    {
        path: "/backoffice/stylingCss",
        name: "stylingCss",
        component: StylingCss,
    },

    // IR Pages
    // {
    //     path: "/",
    //     // path: "/:name",
    //     name: "home",
    //     component: Overview,
    // },
    {
        path: "/",
        name: "home",
        component: OverviewPlaceholder,
    },
    {
        path: "/investInUs",
        // path: "/:name/investInUs",
        name: "investInUs",
        component: InvestInUs,
    },
    {
        path: "/companyCalendar",
        name: "companyCalendar",
        component: CompanyCalendar,
    },
    {
        path: "/earningsAndReports",
        name: "earningsAndReports",
        component: EarningsAndReports,
    },
    {
        path: "/leadershipTeam",
        name: "leadershipTeam",
        component: LeadershipTeam,
    },
    {
        path: "/pressReleasesAndNews",
        name: "pressReleasesAndNews",
        component: PressReleasesAndNews,
    },
    {
        path: "/corporate",
        name: "corporate",
        component: Corporate,
    },
    {
        path: "/genericComponent/:page_id",
        name: "genericComponent",
        component: GenericComponent,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
