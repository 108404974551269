<template lang="pug">
    div(style="background-color:#F9F8F8; border:0px solid;")
        div(class="d-flex" :style="`height:${height}px;`" style="overflow:hidden; border:0px solid;")
            img(v-if="uploadedBase64EncodedImage !== ''" :src="uploadedBase64EncodedImage" style="width:100%; margin-left:auto; margin-right:auto;")
            img(v-else-if="encodedImage !== ''" :src="encodedImage" style="width:100%; margin-left:auto; margin-right:auto;")
            div(v-else class="d-flex justify-center align-center" style="font-size:12px; width:100%; border:1px dashed;")
                div(class="text-center" style="color:grey; border:0px solid;") Preview
        div(style="height:20px; background-color:#F9F8F8; border:0px solid;")
            div(class="mt-1" style="position:relative; background-color:#F9F8F8; height:20px; width:135px; margin-left:auto; margin-right:auto; border:0px solid;")
                v-btn(@click="upload()" :outlined="true" height="20" width="50" style="position:absolute; top:0; left:0; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") File
                v-btn(@click="saveLogo()" :outlined="true" height="20" width="50" class="ml-2" :disabled="uploadedBase64EncodedImage === ''" :style="uploadedBase64EncodedImage === '' ? 'border:1px solid #B3B3B3' : ''" style="position:absolute; top:0; right:0; font-family:Poppins; font-size:12px; color:#28407E; text-transform:none; border:1px solid #28407E;") Save
                input(@change="uploadLogo($event)" type="file" name="image" style="display:none;" ref="fileUploadElement")
</template>

<script>
import { mapMutations } from "vuex";

export default {
    name: "ImageUpload",
    props: {
        id: {
            type: Number,
            default: 0,
        },
        encodedImage: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: 120,
        },
        company: {
            type: Object,
            default() {
                return {};
            },
        },
        updateField: {
            type: String,
            default: '',
        },
        language: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            uploadedBase64EncodedImage: '',
        };
    },
    methods: {
        ...mapMutations(['setShowSnackbar']),
        upload() {
            const element = this.$refs.fileUploadElement;

            // console.log(element);

            element.click();
        },
        uploadLogo(e) {
            const fileInput = e.target;
            const file = fileInput.files[0];
            const reader = new FileReader();

            console.log('file', file);

            if((file.type !== 'image/png' && file.type !== 'image/jpeg')) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Only png or jpg image files are allowed',
                    type: 'error',
                });

                this.uploadedBase64EncodedImage = '';
            } else if(file.size > 2000000) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Image file to large. Maximum size 2 mb',
                    type: 'error',
                });

                this.uploadedBase64EncodedImage = '';
            } else {
                reader.addEventListener('load', () => {
                    // console.log(reader.result);

                    this.uploadedBase64EncodedImage = reader.result;
                });

                reader.readAsDataURL(file);
            }
        },
        saveLogo() {
            // console.log('updateField:', this.updateField);
            // console.log('company:', JSON.parse(JSON.stringify(this.company)));
            // console.log('uploadedBase64EncodedImage:', this.uploadedBase64EncodedImage);

            // Use backend here to store the image in the database
            this.$emit('saveImage', {
                id: this.id,
                company: this.company,
                language: this.language,
                updateField: this.updateField,
                image: this.uploadedBase64EncodedImage,
            });

            this.uploadedBase64EncodedImage = '';
        },
    },
}
</script>

<style scoped>

</style>
