<template lang="pug">
    div(class="ir-generic-component-regular" style="padding-bottom:25px; border:0px solid;")
        div(style="max-width:1000px;")
            div(v-html="getPageContent")
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "GenericComponent",
    mounted() {
        console.log(this.page_id);
        console.log(this.getPageContent);
    },
    computed: {
        ...mapGetters(['getCompany']),
        page_id() {
            return this.$route.params.page_id;
        },
        getPageContent() {
            return this.getCompany.pages.find((p) => Number(p.page_id) === Number(this.page_id)).html_page;
        },
    },
};
</script>

<!-- <style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style> -->
