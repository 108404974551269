import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {

        splash_screen_main_text: `
                                    <div style="font-size:30px; font-weight:600; height:35px; border:0px solid;">Seize the advantage</div>
                                    <div style="font-size:30px; height:35px; border:0px solid;">
                                        <span>Your go-to tool for better</span>
                                        <span style="font-weight:600;">decision making</span>
                                    </div>
                                    <div style="font-size:30px; border:0px solid;">
                                        <span>and</span>
                                        <span style="font-weight:600;">stock market navigation</span>
                                    </div>
                                    <div style="font-size:17px; margin-top:20px; border:0px solid;">
                                        <div>At edger you will find everything you need to research a stock and</div>
                                        <div>make better investment decisions. Most of the functionality is</div>
                                        <div>open, but if you want to store your information with us you need to</div>
                                        <div>
                                            <span>sign up,</span>
                                            <span style="text-decoration:underline;">which of course is free.</span>
                                        </div>
                                    </div>
                                `,
        active_filters: 'Active Filters',
        add_notes: 'Add Notes',
        no_active_filters: 'NO ACTIVE FILTERS',
        your_friends_email: 'Your friends e-mail',
        friends_email: 'Friends e-mail',
        create_your_own_tag: 'Create Your Own tag...',
        events_from_your_watchlist: 'Events from your Watchlist',
        theme_investment_companies: 'Investment Companies',
        theme_highest_yield: 'Highest Yield',
        theme_most_watched: 'Most Watched',
        theme_insiders: 'Insiders',
        theme_best_mtd: 'Best MTD',
        theme_worst_mtd: 'Worst MTD',
        theme_upcoming_reports: 'Upcoming Reports',
        theme_latest_reports: 'Latest Reports',
        theme_not_profitable: 'Not Profitable',
        theme_edgers_top_tips: 'Edgers Top Tips',
        theme_the_cab_driver: 'The Cab Driver',
        theme_performance_mtd: 'Performance MTD',
        theme_not_set: 'Not Set',
        try_for_free: 'TRY FOR FREE',
        home: 'Home',
        save_notes: 'Save Notes',
        columns: 'Columns',
        millions: 'Millions',
        report: 'Report',
        yield: 'Yield',
        clear_all: 'CLEAR ALL',
        topics: 'TOPICS',
        news_type: 'News Type',
        macro: 'Macro',
        save_research: 'Save Research',
        insiders_by_transactions: 'Insiders: By Transaction',
        insiders_silent_period: 'Insiders: Silent Period',
        my_research: 'My Research...',
        show_me_more: 'SHOW ME MORE',
        in_partnership_with: 'IN PARTNERSHIP WITH',
        news: 'News',
        news_all: 'All News',
        news_watched: 'Watched',
        Yes: 'Yes',
        Language: 'Language',
        Currency: 'Currency',
        Date_format: 'Date Format',
        Theme: 'Theme',
        User_settings: 'User Settings',
        User_account: 'Account',
        Watchlist: 'Watchlist',
        Name: 'Name',
        New: 'New',
        Create_an_account: 'Create an account',
        Log_in: 'Log In',
        Log_out: 'Log Out',
        Email: 'Email',
        Invite_Email: 'Your friends e-mail',
        Password: 'Password',
        Enter_email: 'Enter email',
        Enter_password: 'Enter Password',
        Remember_me: 'Remember Me',
        Dashboard: 'Dashboard',
        Portfolio: 'Portfolio',
        Screen: 'Screen',
        Equity_Research: 'Library',
        Admin: 'Admin',
        Chart: 'Chart',
        News: 'News',
        Markets: 'Markets',
        Ticker: 'Ticker',
        Sector: 'Sector',
        write_valid_email: 'Please write a valid email',
        email_has_been_sent: 'The email has been sent',
        Earnings_Date: 'Earnings Date',
        Earnings_Result: 'Earnings',
        Earnings_Media: 'Report media',
        "Market Cap": 'Market Cap',
        Conviction: 'Conviction',
        Watched_Since: 'Watched Since',
        Watchlists: 'Watchlists',
        Positions: 'Positions',
        History: 'History',
        Performance: 'Performance',
        Rows_per_page: 'Rows per Page',
        Entry_Date: 'Entry Date',
        Entry_Price: 'Entry Price',
        Entry_Quantity: 'Entry Qty',
        Exit_Date: 'Exit Date',
        Exit_Price: 'Exit Price',
        PL: 'P/L',
        Commission: 'Commission',
        Notes: 'Notes',
        Add: 'Add',
        Trade_Journal: 'Trade Journal',
        Year: 'Year',
        Wins: 'Wins',
        Losses: 'Losses',
        Best: 'Best',
        Max_DrawDawn: 'Max Drawdown',
        research: 'Research',
        Activity: 'Aktivity',
        Search: 'Search',
        No_data_available: 'No Data Available',
        Search_by_company_name: 'Search by Company Name',
        Small_letters: 'You will not beat the market with crappy tools... you are Welcome',
        Build_yourwatchlist: 'Build your watchlist',
        Skip_thisstep: 'Skip this step',
        Next: 'Next',
        Close: 'Close',
        share: 'Share this document',
        My_First_Watchlist: 'My first watchlist',
        My_First_Screener: 'My first screener',
        Investor_reports: 'Investor Reports',
        Research_notes: 'Research Notes',
        You_donot_letters: 'You do not have any research.',
        Add_watchlistRapportkollen: 'Add to Watchlist Rapportkollen',
        Add_to_watchlist: 'Add to watchlist',

        Income_Statement: 'Income Statement',
        Balance_Assets: 'Balance: Assets',
        Balance_Debts_Cash: 'Balance: Debt & Cash',
        sales: 'Insiders',
        Sales: 'Sales',
        last_report_date: 'Last Report Date',
        next_report_date: 'Next Report Date',
        Valuation: 'Valuation',
        valuation_multiplier: 'Valuation Multiplier',
        Enterprise_value: 'Enterprise Value',
        Market_cap: 'Market Cap M€',
        market_cap: 'Market Cap',
        upside: 'Upside',
        EV_EBIT: 'EV/EBIT',
        EV_EBITDA: 'EV/EBITDA',
        P_B: 'P/B',
        P_S: 'P/S',
        P_E: 'P/E',
        percent_growth: '% growth',
        Sales_growth_percent: 'Sales Growth %',
        Market_cap_cash_debt: 'Market Cap + (cash - debt)',
        Price_x_number_of_shares: 'Price X Number of shares',
        number_of_shares: 'Number of Shares',
        Price_nominal_value_of_assets: 'Price / nominal value of Assets',
        Price_sales_ttm: 'Price / Sales (TTM)',
        Price_earnings_ttm: 'Price / Earnings (TTM)',
        ttm: 'TTM',
        numerical_values: 'Numerical values',
        yesterday: 'yesterday',
        first_capital_yesterday: 'Yesterday',
        yesterday_first_capital: 'Yesterday',
        seven_days_ago: '7 days ago',
        thirty_days_ago: '30 days ago',
        no_filter: 'no filter',
        last_report: 'Last Report',
        today: 'today',
        first_capital_today: 'Today',
        first_capital_tomorrow: 'Tomorrow',
        within_seven_days: 'within 7 days',
        within_thirty_days: 'within 30 days',
        next_report: 'Next Report',
        no_sectors: 'No sectors',
        all_exchanges: 'All Exchanges',
        no_exchange: 'No Exchange',
        Materials_plus_g_and_a: 'Materials + G&A',
        Sales_costs: 'Sales - Costs',
        Assets: 'Assets',
        Liabillities: 'Liabillities',
        Earnings_share: 'Earnings / Share',
        Sales_share: 'Sales / Share',
        Net_income_equity: 'Net Income / Equity',
        Net_income_assets: 'Net Income / Assets',
        Gross_profit_growth_percent: 'Gross Profit Growth %',
        Gross_margin_percent: 'Gross Margin %',
        Gross_margin_growth_percent: 'Gross Margin Growth %',
        Cost_of_goods_sold: 'Cost of Goods Sold',
        costofgoodssold: 'Operational costs',
        gp: 'Gross Profit',
        ebitda: 'EBITDA',
        ebitda_growth_percent: 'EBITDA Growth %',
        ebitda_margin_percent: 'EBITDA Margin %',
        ebitda_margin_growth_percent: 'EBITDA Margin Growth %',
        ebit_growth_percent: 'EBIT Growth %',
        ebit_margin_percent: 'EBIT Margin %',
        ebit_margin_growth_percent: 'EBIT Margin Growth %',
        ebit: 'EBIT',
        EBIT: 'EBIT',
        ptp: 'Pre-tax profit',
        profit: 'Net profit',
        Profit: 'Profit',
        Profit_growth_percent: 'Profit Growth %',
        Profit_margin_percent: 'Profit Margin %',
        Profit_margin_growth_percent: 'Profit Margin Growth %',
        Intangable_assets: 'Intangable Assets',
        Material_assets: 'Material Assets',
        Financial_assets: 'Financial Assets',
        Tangible_assets: 'Tangible Assets',
        Current_assets: 'Current Assets',
        Cash: 'Cash',
        Total_equity: 'Total Equity',
        Long_term_debt: 'Long Term Debt',
        Short_term_debt: 'Short Term Debt',
        Total_debt: 'Total Debt',
        Total_equity_and_liabilities: 'Total Equity & Liabilities',
        Sales_per_share: 'Sales Per Share',
        Number_of_stocks: 'Number of stocks',
        Number_of_stocks_growth_percent: 'Number of stocks growth %',
        roe_percent: 'ROE %',
        roa_percent: 'ROA %',
        Net_debt_percent: 'Net debt %',
        Solidity_percent: 'Solidity %',
        Balance_sheet: 'Balance Sheet',
        Ratios: 'Ratios',

        intangibleasset: 'Intangible Assets',
        fixedasset: 'Tangible Assets',
        financialasset: 'Financial Assets',
        noncurrentasset: 'Non-Current Assets',
        cce: 'Cash & Equivalents',
        othercurrentassets: 'Other Current assets',
        totalcurrentassets: 'Total Current Assets',
        totalassets: 'Total Assets',

        shequity: 'Total Equity',
        ltliabilities: 'Non-Current Liabilities',
        curliabilities: 'Current Liabilities',
        totalliabilities: 'Total Liabilities',
        totalequityandliabilities: 'Total Equity & Liabilities',


        all_watched_items: "All Watched Items",
        all_watchlists: "All Watchlists",
        new_watchlist: "New Watchlist",
        watchlist_free_plan: "Your free plan has a maximum of 10 watched items",
        filter: "Filter",
        research_not_allocated_to_watchlist: "The stock for this Research has not been allocated to a watchlist yet.",
        research_free_plan_line_one: "Your free plan allows for up to",
        research_free_plan_line_two: "10 documents to be saved",
        research_free_plan: "Your free plan allows 10 Research Documents can be stored",
        research_free_need_more: "Need more ? Upgrade to Premium for more Research capacity",
        research_for_your_watchlist: "Write Research for your Watchlist",
        premium_plans: "Premium Plans",
        premium_plans_text1: "Your free account is limited to",
        premium_plans_text2: "1 Watchlist, 10 research notes and 10 trade plans.",



        welcome_to: "Welcome to",
        premium: "Premium",
        success_message_0: "A sincere thank you",
        success_message_1: "We are here to support you and your success. Tell us what you need - product features? more data? training & webinars?",
        success_message_2: "Please connect with Andrew and Petter, either via Chat below, or email",
        success_message_3: "If you are near Stockholm feel free to come by our office.",
        per_month_0: "0 SEK",
        per_year_0: "0 SEK",
        per_month: "120 SEK",
        per_year: "1152 SEK",

        Freemium: "Freemium",
        Premium: "Premium",
        monthly: "per month",
        annual: "per year ",
        pay_annually: "Pay annually (save 20%) ",

        motivation_freemium_sub: "You get access to:",
        motivation_1: "10 Trade Plans",
        motivation_2: "10 Research Documents",
        motivation_3: "10 Watchlists",
        motivation_4: "Data & Information",
        motivation_5: "Trading Journal",

        motivation_premium_sub: "Plan includes: ",
        motivation_p1: "Unlimited Trade Plans",
        motivation_p2: "Unlimited Research Documents",
        motivation_p3: "Unlimited Watchlists",
        motivation_p4: "Data & Information",
        motivation_p5: "Trading Journal",

        purchase: "Purchase",
        card_payment: "Card payment",
        payment_details: "Payment details",
        name: "Name",
        Pay: "Pay",
        read_more: "read more...",
        email: "Email",
        phone: "Phone",
        current: "Current",
        buy_now: "START NOW",

        Bullish: "Bullish",
        Bearish: "Bearish",
        Short: "Short",
        Value: "Value",
        Growth: "Growth",
        Story_Stock: "Stock Stock",
        Event_Trade: "Event Trade",
        Earnings: "Earnings",
        SaaS: "SaaS",
        Top_10: "Top 10",

        Previous: "Previous",
        Finish: "Finish",
        Financials: "Financials",
        My_Tags: 'Tags',
        Your_tags_here: 'Your tags here',
        Upgrade: 'Upgrade',
        hide: 'Hide',
        new_screener: 'New Screener',
        Recent: 'Latest',
        Save: 'Save',
        save_screener: 'Save Screener',
        FreePlanNotSaved: 'This Trade Plan will not be saved with your free plan.',
        FreePlanLimit: '(10 Trade Plan limit)',
        SignupPremium: 'We want to build World class investment tools but we must also pay for the electricity... sign-up for a premium plan',
        signup: 'Sign-up',
        login: 'Log in',
        about_us: 'About Us',
        visitor: 'Visitor',
        And: 'and',
        SendUsMessage: 'send us a message',
        TellUs: 'tell us how we can',
        BetterServeYou: 'better serve you',
        Card_Number: 'Card Number',
        Card_Expiry: 'MM/YY',
        Card_CVC: 'CVC',

        Help: 'Help',
        Create_account: "Create account",
        Your_eMail: "Your e-mail",
        Choose_your_password: "Choose your password",

        // need to unify strings. As example, UPPER is not needed because of CSS
        CREATE_ACCOUNT_Upper: "CREATE ACCOUNT",

        account_verified: "account verified",
        LOGIN_ACCOUNT_Upper: "LOGIN TO YOUR ACCOUNT",
        Login_account: "Login to your account",
        Your_password: "Your password",
        Forgotten_password: "Forgotten password",
        Continue_withGoogle: "Continue with Google",
        Continue_withFacebook: "Continue with Facebook",
        No_account: "Don't have an account?",
        Register: "Register",
        Have_account: "Already have an account?",
        confirm_code_via_email: "Confirm code via email",
        minimum_eight_characters: 'Minimum 8 Characters',
        No: 'No',
        Created: 'Created',
        Last_Modified: 'Last Modified',
        Delete: 'Delete',
            Notifications: 'Notifications',
        Newsletters: 'Watch Events & News',
        Weekly_Reports: 'Weekly Reports',
        Swedish: "Swedish",
        English: "English",
        Current_Password: "Current Password",
        New_Password: "New Password",
        Confirm_New_Password: "Confirm new password",
        Upgrade_to_annual_plan: "Upgrade to annual plan",
        Change_card: "Change Card",
        Transactions: "Transactions",
        Cancel_premium: "Cancel Premium",
        Reset_Password: "Reset Password",
        Privacy_and_Delete_Account: "Privacy & Delete Account",
        Remove_all_data_for_this_account_and_forget_me_forever: "Remove all data for this account... and forget me forever",
        Delete_Registration: "Delete Registration",
        Save_and_Close: "Save & Close",
        Current_Plan_Premium: "Current Plan: Premium",
        Transaction_Date: "Transaction Date",
        Product: "Product",
        Card: "Card",
        Price: "Price",
        Receipt: "Receipt",
        Invite_a_friend: "Invite a Friend",
        Invite: "Invite",
        pe: "P/E",
        last_earnings: "Last Earnings",
        next_earnings: "Next Earnings",
        eps: "EPS",
        price_last_close: "Price (last close)",
        Exchange: "Exchange",
        company_information: "Company Information",
        About: "About",
        ceo_comments: "CEO Comments & Reports",
        sector_peers_in: "Sector peers in",

        menu_overview: "Overview",
        menu_why_invest_in_us: "Why Invest in us?",
        menu_leadership_and_board: "Leadership & Board",
        menu_press_releases_and_news: "Press releases & News",
        menu_reports_and_media: "Reports & Media",
        menu_company_calendar: "Company calendar",
        menu_corporate: "Corporate",

        ul_menu_management_and_board: "Management & Board",
        ul_menu_why_ohg: "Why OHG?",

        event_name: "Event Name",
        period: "Period",
        time: "Time",

        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",

        file: "File",

        edger_module_add_to_watchlist_on_edger_finance: "Add to Watchlist on Edger Finance",
        edger_module_read_more: "Read more",
        edger_module_custom_kpis: "Custom KPIs and interface provided by edger.finance. Your go-to tool for navigating the stock market. You can easily add this company to your watchlist on Edger by using the watchlist button above.",

        overview: "OVERVIEW",
        insider_trades: "INSIDER TRADES",
        alternative_data: "ALTERNATIVE DATA",
        alternative_text: "Launching soon: Here you will be able to find Impact Metrics and Alternative KPIs, which will give you an edge in understanding this company.",
        analyst: 'Analysts',
        Revenue: "Revenue (Millions)",
        Date: "Date",
        Note: "Note",
        any_journal: "Begin to capture your thoughts, new information, fundamentals and price levels, about",
        Journal: "Journal",
        Filter: "Filter",
        Display: "Display",
        Quarterly: "Quarterly",
        quarterly_report: "Quarterly Report",
        annual_report: "Annual Report",
        full_year: "Full Year",
        List: "List",
        Charts: "Charts",
        configure_watchlist_columns: "Configure Watchlist Columns",
        rename_watchlist: "Rename Watchlist",
        delete_watchlist: "Delete Watchlist",
        add_to: "Add to",

        price_eod: "Price (EOD)",
        last_eps: 'Last Eps',
        last_sales: "Last Sales (Millions)",
        trade_plan: "Trade Plan",
        no_plan_defined: "no plan defined",
        active_between: "Active between",
        emails: "Emails",
        risk_return_ratio: "Risk/Return Ratio",
        view: "View",
        date_expired: "date expired",
        create_new_trade_plan: "Create new Trade Plan",
        plan_has_expired: "This plan has expired",
        target_price_met: "Target price has been met",
        entry_price_met: "Entry price has been met",
        stoploss_price_met: "Stoploss price has been met",
        email_alerts_silenced: "Email alerts have been silenced",
        enable_email_alerts: "re-enable email alerts",
        chart_view: "Chart View",
        Days: "Days",
        Count: "Count",
        Average: "Average",
        Day: "Day",
        Documents: "Documents",
        Missing: "Missing",
        research_analyser: "Research",
        Overlays: "Overlays",
        ma_twenty: "MA 20",
        ma_fifty: "MA 50",
        ma_twohundred: "MA 200",
        insiders_by_transaction: "Insiders: By Transaction",
        inisders_silent_period: "Inisders: Silent Period",
        Compare: "Compare",
        trade_plan_target_price: "Target price",
        trade_plan_entry_price: "Entry price",
        trade_plan_stoploss_price: "Stoploss Price",
        watchlist_add_new_notes: "Add new notes",

        one_year: "1Y",
        three_years: "3Y",
        five_years: "5Y",
        ten_years: "10Y",
        W_V: "W",

        instrument_name: "Instrument Name",
        Person: "Person",
        person_title: "Person Title",
        action: "Action",
        Volume: "Volume",
        qty_or_amount: "Qty or Amount",
        Annually: "Annually",

        no_name_screener_not: "saved No name Screener, not saved",
        company_info: "Company Information",
        add_columns: "Add Columns",
        rename_screener: "Rename Screener",
        delete_screener: "Delete Screener",

        Reset: "Reset",
        apply: "Apply",
        confirm_with_password: "Confirm with password",
        Confirm_Delete: "Confirm Delete",
        Cancel: "Cancel",
        enter_user_account: "Enter your user account’s verified email address and we will send you a verification code.",
        confirm_code: "Confirm Code",
        email_secret_code: "Email secret code",

        Title: 'Title',

        No_sectors_selected: 'No sectors selected.',

        sweden: 'Sweden',
        finland: 'Finland',
        norway: 'Norway',
        denmark: 'Denmark',

        no_account_selected_yet: 'No account selected yet',

        Type: 'Type',
        Quantity: 'Quantity',
        Amount: 'Amount',

        label_leader: 'Leader',
        label_my_balance: 'Balance',
        label_average: 'Average',

        sector_basic_materials: 'Basic Materials',
        sector_consumer_discretionary: 'Consumer Discretionary ',
        sector_consumer_staples: 'Consumer Staples',
        sector_financials: 'Financials',
        sector_health_care: 'Health Care',
        sector_industrials: 'Industrials',
        sector_oil_and_gas: 'Oil & Gas',
        sector_technology: 'Technology',
        sector_telecom: 'Telecom',
        sector_utilities: 'Utilities',
        sector_aluminum_and_steel: 'Aluminum & Steel',
        sector_chemicals: 'Chemicals',
        sector_forestry_and_paper: 'Forestry & Paper',
        sector_industrial_metals: 'Industrial Metals',
        sector_mining: 'Mining',
        sector_precious_metals: 'Precious Metals',
        sector_auto_and_equipment: 'Auto & Equipment',
        sector_casinos_and_gambling: 'Casinos & Gambling',
        sector_gaming: 'Gaming',
        sector_homefurnishing_retail: 'Homefurnishing Retail',
        sector_media: 'Media',
        sector_retail: 'Retail',
        sector_travel_and_leisure: 'Travel & Leisure',
        sector_food_and_beverage: 'Food & Beverage',
        sector_food_and_drug_retailers: 'Food & Drug Retailers',
        sector_health_products: 'Health Products',
        sector_personal_and_household_goods: 'Personal & Household Goods',
        sector_banks: 'Banks',
        sector_financial_services: 'Financial Services',
        sector_holding_companies: 'Holding Companies',
        sector_insurance: 'Insurance',
        sector_real_estate: 'Real Estate',
        sector_biotechnology: 'Biotechnology',
        sector_healthcare_facilities: 'Healthcare Facilities',
        sector_medical_equipment: 'Medical Equipment',
        sector_medical_supplies: 'Medical Supplies',
        sector_pharmaceuticals: 'Pharmaceuticals',
        sector_aerospace_and_defense: 'Aerospace & Defense',
        sector_automobiles_and_parts: 'Automobiles & Parts',
        sector_business_consultants: 'Business Consultants',
        sector_business_support_and_services: 'Business Support & Services',
        sector_construction_and_infrastructure: 'Construction & Infrastructure',
        sector_construction_and_materials: 'Construction & Materials',
        sector_education: 'Education',
        sector_electronic_components: 'Electronic Components',
        sector_energy_and_recycling: 'Energy & Recycling',
        sector_industrial_components: 'Industrial Components',
        sector_industrial_conglomerates: 'Industrial Conglomerates',
        sector_industrial_machinery: 'Industrial Machinery',
        sector_installation: 'Installation',
        sector_outsourcing: 'Outsourcing',
        sector_transportation: 'Transportation',
        sector_oil_and_gas_drilling: 'Oil & Gas Drilling',
        sector_oil_and_gas_equipment_and_services: 'Oil & Gas Equipment & Services',
        sector_oil_and_gas_exploration_and_production: 'Oil & Gas Exploration & Production',
        sector_oil_and_gas_storage_and_transportation: 'Oil & Gas storage & Transportation',
        sector_communications: 'Communications',
        sector_computer_hardware: 'Computer Hardware',
        sector_financial_technology: 'Financial Technology',
        sector_hardware_and_equipment: 'Hardware & Equipment',
        sector_it_consulting: 'IT Consulting',
        sector_it_services: 'IT Services',
        sector_measuring_and_analysis: 'Measuring & Analysis',
        sector_security_and_surveillance: 'Security & Surveillance',
        sector_semiconductors: 'Semiconductors',
        sector_software: 'Software',
        sector_telecommunications_services: 'Telecommunications Services',
        sector_telecommunications: 'Telecommunications',
        sector_electric_utilities: 'Electric Utilities',
        sector_renewable_energy: 'Renewable Energy',
        sector_sect_industry: 'Sector / Industry',
        sector_no_sector: 'No Sector',

        competition_portfolio: 'Competition Portfolio',
        positions: 'Portfolio',
        competition_by_invitation: 'Competition by Invitation ',
        invitation_code: 'Invitation Code',
        register: 'REGISTER',
        host_a_competition: 'Do you want to host a competition for your group? Write to  Petter@tradersAlloy.com',
        screen_name: 'Screen Name',
        start_now: 'START NOW',
        competitions: 'Competitions ',
        cash : 'Cash',
        my_transactions: 'MY TRANSACTIONS',
        my_positions: 'MY POSITIONS ',
        competition_broker: 'Competition Broker ',
        buy: 'BUY',
        sell: 'SELL',
        done: 'Done',
        amount_gt_cash: 'Not Enough Cash Available ',
        stock: 'Stock',
        search_by_company_name: 'Search by Company Name',
        quantity: 'Quantity ',
        total_buy_price: 'Total Buy Price',
        submit_buy_order: 'SUBMIT BUY ORDER',
        in_my_positions: 'In My Positions',
        total_sell_price: 'Total Sell Price',
        submit_sell_order: 'SUBMIT SELL ORDER',
        competition_standings: 'Competition Standings',
        chart: 'CHART',
        table: 'TABLE',
        rank : 'Rank',
        user: 'User',
        balance : 'Balance ',
        top_holding : 'Top Holding',
        exposure: 'Exposure',
        available_cash: 'Available Cash',
        transactions: 'Transactions',
        buy_sell_transactions: 'BUY & SELL TRANSACTIONS',
        paired_transactions: 'PAIRED TRANSACTIONS',
        account: 'Account',
        date: 'Date',
        ticker: 'Ticker',
        qty: 'Quantity',
        price: 'Price',
        transaction_type: 'Transaction Type',
        amount: 'Amount',
        currency: 'Currency ',
        no_rows : 'No Rows To Show ',
        entry: 'ENTRY',
        exit: 'EXIT',
        result: 'RESULT',
        profit_loss: 'Profit/Loss',
        notes: 'Notes',
        price_today: 'Price Today',
        pl_pct: 'P/L %',
        pl_sum: 'P/L(Sum)',
        dairy_notes: 'Dairy Notes',
        add_journal_notes: 'Click to add Journal Notes',
        journal_notes: 'Journal Notes',
        rows: 'Rows',
        average: 'Average',
        trades_per_week: 'Trades per week',
        value: 'Value',
        conviction_sector_exposure: 'Conviction vs Sector vs Exposure',
        exposure_by_sector: 'Exposure by Sector',
        insufficient_cash: 'Insufficient Cash',

        estimations: 'Estimations',
        estimates: 'Estimates',
        estimate: 'Estimate',

        visitors_list: 'Visitor\'s List',

        description: 'Description',

        memorandum: 'Memorandum',
        subscribe_for_shares_here: 'Subscribe shares here',
        ceo_vision: 'CEO - Vision',
        sign_up_to_keep_up_to_date_with_the_news: 'Sign up to keep up to date with the news!',
        company_is_intended: 'is intended to be listed in the near future. Sign up for our newsletter to keep up with the latest news.',
        subscribe: 'Subscribe',
        in_numbers: 'in numbers',

        tag_analytics:'Analysis',
        tag_rec:'recommendation',
        tag_stocks:'stocks',
        tag_tech:'tech',
        tag_macro:'macro',
        tag_fwn_fx:'FX',
        tag_industrials:'industrials',
        tag_financials:'financials',
        tag_fwn_consumergoods:'consumer goods',
        tag_healthcare:'health care',
        tag_retail:'retail',
        tag_commodities:'commodities',
        tag_interim:'interim',
        tag_fwn_consumerservices:'consumer services',
        tag_realestate:'real estate',
        tag_fwn_bank:'bank',
        tag_energy:'energy',
        tag_lifescience:'life science',
        tag_ma:'M & A',
        tag_insider:'insider',
        tag_medtech:'med-tech',
        tag_leisure:'leisure',
        tag_automotive:'automotive',
        tag_media:'media',
        tag_telecom:'telecom',
        tag_fwn_biotech:'biotech',
        tag_fwn_corporateactions:'corporate actions',
        tag_fwn_ngmexchange:'NGM',
        tag_exx:'eex',
        tag_oil:'oil',
        tag_fwn_construction:'construction',
        tag_fwn_forestrypaper:'forestry',
        tag_gaming:'gaming',
        tag_fwn_software:'software',
        tag_foods:'foods',
        tag_funding:'funding ',
        tag_ecommerce:'e-Commerce',
        tag_renewable:'renewable',
        tag_fwn_coronavirus:'Corona',
        tag_fwn_fashion:'fashion',
        tag_fwn_guidance:'guidance',
        tag_betting:'betting',
        tag_estimates:'estimates',
        tag_ipo:'IPO',
        tag_dividend:'dividend',
        tag_fwn_commercialservic:'service',
        tag_wind:'wind',
        tag_fwn_summary:'summary',
        tag_fwn_capitalgoods:'capital goods',
        tag_fwn_semiconductor:'semi-conductor',
        tag_politics:'politics',
        tag_fwn_airlines:'airlines',
        tag_fwn_personalgoods:'personal goods',
        tag_fwn_steel:'steel',
        tag_fwn_logistics:'logistics',
        tag_fwn_casino:'casino',
        tag_transports:'transports',
        tag_basemetals:'base metals',
        tag_fwn_privateequity:'private equity',
        tag_services:'services',
        tag_calendar:'calendar',
        tag_cryptocurrency:'crypto-currency',
        tag_fwn_xltrade:'xl trade',
        tag_precious:'precious',
        tag_fwn_sustainability:'sustainability',
        tag_defence:'defence',
        tag_copper:'copper',
        tag_fwn_intradag:'intra-day',
        tag_fwn_beverage:'beverage',
        tag_fwn_aluminium:'aluminum ',
        tag_agriculture:'agriculture',
        tag_fwn_stockholmbullets:'Stockholm Bullets',
        tag_fwn_sports:'sports',
        tag_fwn_geopolitics:'geo-politics',
        tag_fwn_hydrogen:'hydrogen',
        tag_fwn_insurance:'insurance',
        tag_fwn_commercialvehicl:'commercial vehicle',
        tag_fwn_oott:'oott',
        tag_gold:'gold',
        tag_fwn_zinc:'zinc',
        tag_fwn_itconsultants:'it consultants',
        tag_fwn_healthservices:'health services',
        tag_fwn_tobacco:'tobacco',
        tag_aviation:'aviation',
        tag_fintech:'fin-tech',
        tag_fwn_buybacks:'buy-backs',
        tag_fwn_esport:'e-sport',
        tag_fwn_finwire:'finwire',
        tag_currencies:'currencies',
        tag_fwn_chemicals:'chemicals',
        tag_fwn_cleantech:'clean-tech',
        tag_fwn_utilities:'utilities',
        tag_fwn_cybersecurity:'cyber-security',
        tag_fwn_silver:'silver',
        tag_oilservice:'oil services',
        tag_fwn_pepins:'Pepins',
        tag_fwn_blockchain:'block chain',
        tag_fwn_spac:'SPAC',
        tag_fwn_nickel:'nickel',
        tag_exclusive:'exclusive',
        tag_fwn_hotel:'hotel',
        tag_materials:'materials',
        tag_biometrics:'biometrics',
        tag_hedgefunds:'hedge-funds',
        tag_fwn_seafood:'seafood',
        tag_fwn_lead:'lead',
        tag_fwn_solar:'solar',
        tag_fwn_smallcap:'small cap',
        tag_fixedincome:'fixed income',
        tag_space:'space',
        tag_fwn_climate:'climate',
        tag_mutualfunds:'mutual funds',
        tag_fwn_coal:'coal',
        tag_fwn_recsummary:'summary',
        tag_pharmaceuticals:'Pharmaceuticals',
        tag_shipping:'Shipping',
        tag_ta_pressrelease: 'Press Release',
        tag_pressrelease: 'Press Release',
        // pressrelease: 'Press Release',
        save_as: 'Save As'
    },
    'sv': {
        splash_screen_main_text: `
                                    <div style="font-size:30px; font-weight:600; height:35px; border:0px solid;">Ta till vara på möjligheten</div>
                                    <div style="font-size:30px; height:35px; border:0px solid;">
                                        <span>Ditt verktyg för bättre beslut</span>
                                        <!--<span style="font-weight:600;">aktiemarknaden</span>-->
                                    </div>
                                    <div style="font-size:30px; border:0px solid;">
                                        <span>och</span>
                                        <span style="font-weight:600;">för att navigera aktiemarknaden</span>
                                    </div>
                                    <div style="font-size:17px; margin-top:20px; border:0px solid;">
                                        <div>Hos Edger hittar du allt du behöver för att analysera en aktie och</div>
                                        <div>fatta bättre investeringsbeslut. Nästan hela plattformen är</div>
                                        <div>öppen men om du vill spara information behöver du</div>
                                        <div>
                                            <span>skapa ett konto,</span>
                                            <span style="text-decoration:underline;">vilket såklart är kostnadsfritt.</span>
                                        </div>
                                    </div>
                                `,
        save_as: 'Spara Som',
        save_research: 'Spara Analys',
        active_filters: 'Aktiva Filter',
        no_active_filters: 'INGA AKTIVA FILTER',
        topics: 'ÄMNEN',
        news_type: 'Nyhets typ',
        show_me_more: 'LÅT MIG UTFORSKA',
        macro: 'Makro',
        in_partnership_with: 'I PARTNERSKAP MED',
        news_all: 'Alla Nyheter',
        news_watched: 'Bevakade',
        clear_all: 'RENSA',
        my_research: 'Min Analys...',
        insiders_by_transactions: 'Insynshandel: Transaktioner',
        insiders_silent_period: 'Insynshandel: Tyst Period',
        journal_notes: 'Dagboks Anteckningar',
        save_notes: 'Spara Anteckningar',
        report: 'Rapport',
        create_your_own_tag: 'Skapa din egen tagg...',
        yield: 'Direktavkastning',
        your_friends_email: 'Din väns e-post',
        friends_email: 'e-post',
        events_from_your_watchlist: 'Händelser från dina Bevakningslistor',
        theme_investment_companies: 'Investmentbolag',
        theme_highest_yield: 'Högst Direktavkastning',
        theme_most_watched: 'Mest Bevakade',
        theme_insiders: 'Insynshandel',
        theme_best_mtd: 'Bäst MTD',
        theme_worst_mtd: 'Sämst MTD',
        theme_upcoming_reports: 'Kommande Rapporter',
        theme_latest_reports: 'Senaste Rapporter',
        theme_not_profitable: 'Inte Lönsamma',
        theme_edgers_top_tips: 'Edgers Favoriter',
        theme_the_cab_driver: 'Taxichauffören',
        millions: 'Miljoner',
        theme_performance_mtd: 'Utveckling MTD',
        theme_not_set: 'Ingen data',
        home: 'Hem',
        try_for_free: 'PROVA GRATIS',
        add_notes: 'Lägg Till Anteckningar',

        Cancel: "Avsluta",
        Confirm_Delete: "Bekräfta",
        confirm_with_password: "Bekräfta med lösenord",
        Reset: "Återställ",
        apply: "Tillämpa",
        Invite: "Bjud in",
        Invite_a_friend: "Bjud in en vän",
        Transaction_Date: "Transaktionsdatum",
        Product: "Produkt",
        Card: "Kort",
        Price: "Pris",
        Receipt: "Kvitto",
        Current_Plan_Premium: "Nuvarande Plan: Premium",
        Save_and_Close: "Spara & Stäng",
        Delete_Registration: "Radera Konto",
        Remove_all_data_for_this_account_and_forget_me_forever: "Radera all data och det här kontot",
        Privacy_and_Delete_Account: "Sekretess & Radera Konto",
        Reset_Password: "Återställ Lösenord",
        Cancel_premium: "Avsluta Premium",
        Transactions: "Transaktioner",
        Change_card: "Ändra Kort",
        Upgrade_to_annual_plan: "Uppgradera till en årsplan",
        Confirm_New_Password: "Bekräfta lösenord",
        New_Password: "Nytt Lösenord",
        Current_Password: "Nuvarande Lösenord",
        English: "Engelska",
        Swedish: "Svenska",
        Newsletters: 'Nyhetsbrev',
        Weekly_Reports: 'Veckorapport',
        Notifications: 'Notiser',
        Delete: 'Radera',
        Last_Modified: 'Senast Redigerad',
        Created: 'Skapad',
        Yes: 'Ja',
        No: 'Nej',
        Language: 'Språk',
        Currency: 'Valuta',
        Date_format: 'Datumformat',
        Theme: 'Tema',
        User_settings: 'Inställningar',
        User_account: 'Konto',
        Watchlist: 'Bevakningslista',
        Name: 'Namn',
        New: 'Ny',
        Create_an_account: 'Skapa Konto',
        Log_in: 'Logga In',
        Log_out: 'Logga Ut',
        Email: 'Epost',
        Password: 'Lösenord',
        Enter_email: 'Ange e-post',
        Enter_password: 'Ange lösenord',
        Remember_me: 'Kom ihåg mig',
        Dashboard: 'Dashboard',
        Portfolio: 'Portfölj',
        Screen: 'Screener',
        Equity_Research: 'Bibliotek',
        Admin: 'Admin',
        Chart: 'Graf',
        News: 'Nyheter',
        Markets: 'Marknader',
        Ticker: 'Kortnamn',
        Sector: 'Sektor',
        write_valid_email: 'Ange en korrekt E-post adress',
        email_has_been_sent: 'Inbjudan har skickats',
        Earnings_Date: 'Rapportdatum',
        Earnings_Result: 'Resultat',
        Earnings_Media: 'Rapportmedia',
        Market_Cap: 'Börsvärde',
        Conviction: 'Övertygelse',
        Watched_Since: 'Bevakad Sedan',
        Watchlists: 'Bevakningslistor',
        Positions: 'Positioner',
        History: 'Historik',
        Performance: 'Utveckling',
        Rows_per_page: 'Rader per sida',
        Entry_Date: 'Inköpsdatum',
        Entry_Price: 'Inköpspris',
        Entry_Quantity: 'Kvantitet',
        Exit_Date: 'Försäljningsdatum',
        Exit_Price: 'Försäljningspris',
        PL: 'Resultat',
        Commission: 'Courtage',
        Notes: 'Anteckningar',
        Add: 'Lägg Till',
        Trade_Journal: 'Investeringsdagbok',
        Year: 'År',
        Wins: 'Vinster',
        Losses: 'Förluster',
        Best: 'Största Vinst',
        Max_DrawDawn: 'Största Förlust',
        Activity: 'Aktivitet',
        Search: 'Sök',
        share: 'Share this document',
        My_First_Watchlist: 'Min första watchlist',
        My_First_Screener: 'Min första screener',
        No_data_available: 'Ingen data tillgänglig',
        Search_by_company_name: 'Sök efter företagets namn',
        Small_letters: 'Beslutsstöd för dina investeringar',
        Build_yourwatchlist: 'Skapa din bevakningslista',
        Skip_thisstep: 'Hoppa över det här steget',
        Next: 'Nästa',
        Close: 'Stäng',
        Investor_reports: 'Rapporter',
        Research_notes: 'Analys',
        You_donot_letters: 'Du har inte och undersöker.',
        Add_watchlistRapportkollen: 'Lägg till i Bevakningslista',
        Add_to_watchlist: 'Lägg till i Bevakningslista',
        My_Tags: 'Taggar',
        Your_tags_here: 'Dina taggar här',

        Income_Statement: 'Resultaträkning',
        Balance_Assets: 'Balansräkning: Tillgångar',
        Balance_Debts_Cash: 'Balansräkning: Eget Kapital och Skulder',
        sales: 'Salers: Omsättning',
        Sales: 'Omsättning',
        last_report_date: 'Senaste Rapportdatum',
        next_report_date: 'Nästa Rapportdatum',
        Valuation: 'Värdering',
        valuation_multiplier: 'Värdering multiplikator',
        Enterprise_value: 'Enterprise Value',
        Market_cap: 'Börsvärde M€',
        market_cap: 'Börsvärde',
        upside: 'Uppsida',
        EV_EBIT: 'EV/EBIT',
        EV_EBITDA: 'EV/EBITDA',
        P_B: 'P/B',
        P_S: 'P/S',
        P_E: 'P/E',
        percent_growth: '% tillväxt',
        Sales_growth_percent: 'Omsättningstillväxt %',
        Market_cap_cash_debt: 'Börsvärde + (kassa - skulder)',
        Price_x_number_of_shares: 'Pris X Antal aktier',
        number_of_shares: 'Antal aktier',
        Price_nominal_value_of_assets: 'Pris / nominellt värde på tillgångar',
        Price_sales_ttm: 'Pris / Omsättning (TTM)',
        Price_earnings_ttm: 'Pris / Förtjänster (TTM)',
        ttm: 'TTM',
        numerical_values: 'Numeriska värden',
        yesterday: 'igår',
        first_capital_yesterday: 'Igår',
        yesterday_first_capital: 'Igår',
        seven_days_ago: '7 dagar sedan',
        thirty_days_ago: '30 dagar sedan',
        no_filter: 'inget filter',
        last_report: 'Senaste Rapporten',
        today: 'idag',
        first_capital_today: 'Idag',
        first_capital_tomorrow: 'Imorgon',
        within_seven_days: 'inom 7 dagar',
        within_thirty_days: 'inom 30 dagar',
        next_report: 'Nästa Rapport',
        no_sectors: 'Inga sektorer',
        all_exchanges: 'Alla utbyten',
        no_exchange: 'Inget utbyte',
        Materials_plus_g_and_a: 'Material + O&K',
        Sales_costs: 'Omsättning - Kostnader',
        Assets: 'Tillgångar',
        Liabillities: 'Skulder',
        Earnings_share: 'Vinst / Per Aktie',
        Sales_share: 'Omsättning / Akite',
        Net_income_equity: 'Vinst efter skatt / Eget Kapital',
        Net_income_assets: 'Vinst efter skatt / Tillgångar',
        Gross_profit_growth_percent: 'Bruttovinststillväxt %',
        Gross_margin_percent: 'Bruttomarginal %',
        Gross_margin_growth_percent: 'Bruttomarginalstillväxt %',
        Cost_of_goods_sold: 'Kostnad Såld Vara',
        costofgoodssold: 'Operationella kostnader',
        gp: 'Bruttoresultat',
        ebitda: 'EBITDA',
        ebitda_growth_percent: 'EBITDA Tillväxt %',
        ebitda_margin_percent: 'EBITDA-Marginal %',
        ebitda_margin_growth_percent: 'EBITDA-Marginal Tillväxt %',
        ebit_margin_percent: 'EBIT-Marginal %',
        ebit_growth_percent: 'EBIT Tillväxt %',
        ebit_margin_growth_percent: 'EBIT-Marginal Tillväxt %',
        ebit: 'Rörelseresultat',
        EBIT: 'EBIT',
        ptp: 'Vinst före skatt',
        profit: 'Vinst',
        Profit: 'Vinst',
        Profit_growth_percent: 'Vinst Tillväxt %',
        Profit_margin_percent: 'Vinst Marginal %',
        Profit_margin_growth_percent: 'Vinst Marginal Tillväxt %',
        Intangable_assets: 'Immateriella Tillgångar',
        Material_assets: 'Materiella Tillgångar',
        Financial_assets: 'Finansiella Tillgångar',
        Tangible_assets: 'Anläggningstillgångar',
        Current_assets: 'Omsättningstillgångar',
        Cash: 'Kassa',
        Total_equity: 'Totalt Kapital',
        Long_term_debt: 'Långfristiga Skulder',
        Short_term_debt: 'Kortfristiga Skulder',
        Total_debt: 'Totala Skulder',
        Total_equity_and_liabilities: 'Eget Kapital & Skulder',
        Sales_per_share: 'Omsättning Per Aktie',
        Number_of_stocks: 'Antal Aktier',
        Number_of_stocks_growth_percent: 'Antal Aktier Tillväxt %',
        roe_percent: 'ROE %',
        roa_percent: 'ROA %',
        Net_debt_percent: 'Nettoskuld %',
        Solidity_percent: 'Soliditet %',
        Balance_sheet: 'Balansräkning',
        Ratios: 'Ratios',

        intangibleasset: 'Immateriella tillgångar',
        fixedasset: 'Materiella tillgångar',
        financialasset: 'Finansiella tillgångar',
        noncurrentasset: 'Anläggningstillgångar',
        cce: 'Kassa/Bank',
        othercurrentassets: 'Övriga Omsättningstillgångar',
        totalcurrentassets: 'Omsättningstillgångar',
        totalassets: 'Totala Tillgångar',

        shequity: 'Eget Kapital',
        ltliabilities: 'Långfristiga Skulder',
        curliabilities: 'Kortfristiga Skulder',
        totalliabilities: 'Nettoskuld',
        totalequityandliabilities: 'Eget Kapital och Skulder',


        all_watched_items: "Alla bevakade bolag",
        all_watchlists: "Alla Bevakningslistor",
        new_watchlist: "Ny Bevakningslista",
        watchlist_free_plan: "Din gratis prenumeration tillåter maximalt 10 bevakningslistor",
        hide: "Dölj",
        filter: "Filtrera",
        research: "Analys",
        research_not_allocated_to_watchlist: "Aktien för dessa analyser har inte blviit kopplad till någon bevakningslista ännu.",
        research_free_plan_line_one: "Din gratis prenumeration tillåter",
        research_free_plan_line_two: "dig att få spara 10 dokument",
        research_free_plan: "Din gratis prenumeration tillåter 10 sparade analyser",
        research_free_need_more: "Vill du spara fler ? Uppgradera till premium för att kunna spara fler analyser",
        research_for_your_watchlist: "Skriv Analyser för aktier som du bevakar",
        premium_plans: "Premium",
        premium_plans_text1: "Din gratisprenumeration är begränsad till",
        premium_plans_text2: "1 Bevakningslista, 10 analysdokument och 10 trade plans.",

        Freemium: "Freemium",
        Premium: "Premium",
        monthly: "per månad",
        annual: "per år ",
        pay_annually: "Betala Årsvis (Spara 20%) ",

        welcome_to: "Välkommen till",
        premium: "Premium",
        success_message_0: "Ett stort tack!",
        success_message_1: "Vi finns här för att stötta dig och din utveckling. Berätta gärna vad du behöver - fler funktioner? Mer data? Utbildning & webbinar?",
        success_message_2: "Hör gärna av dig till Andrew och Petter, antingen via chatten nedan eller e-post",
        success_message_3: "Om du är i närheten av Stockholm är du varmt välkommen att komma förbi vårt kontor.",
        per_month_0: "0 SEK",
        per_year_0: "0 SEK",
        per_month: "120 SEK",
        per_year: "1152 SEK",


        motivation_freemium_sub: "Du får tillgång till:",
        motivation_1: "10 Handlingsplaner",
        motivation_2: "10 Analysdokument",
        motivation_3: "10 Bevakningslistor",
        motivation_4: "Data & Information",
        motivation_5: "Investeringsdagbok",

        motivation_premium_sub: "Premium inkluderar: ",
        motivation_p1: "Obegränsat antal Handlingsplaner",
        motivation_p2: "Obegränsat antal Analysdokument",
        motivation_p3: "Obegränsat antal Bevakningslistor",
        motivation_p4: "Data & Information",
        motivation_p5: "Investeringsdagbok",

        purchase: "KÖP",
        card_payment: "Kortbetalning",
        payment_details: "Betalningsuppgifter",
        name: "Namn",
        Pay: "Betala",
        read_more: "Läs mer...",
        email: "Epost",
        phone: "Telefonnummer",
        current: "Nuvarande",
        buy_now: "KÖP",


        Bullish: "Hausse",
        Bearish: "Baisse",
        Short: "Korta",
        Value: "Värde",
        Growth: "Tillväxt",
        Story_Stock: "Tema",
        Event_Trade: "Event",
        Earnings: "Rapport",
        SaaS: "SaaS",
        Top_10: "Top 10",

        Previous: "Gå tillbaka",
        Finish: "Avsluta",

        Financials: "Finansiell data",
        Upgrade: 'uppgradera',
        new_screener: 'Ny Screener',
        Recent: 'Senaste',
        Save: 'Spara',
        save_screener: 'Spara Screener',
        FreePlanNotSaved: 'För att kunna spara fler Trade Plans behöver du uppgradera till Premium',
        FreePlanLimit: '(10 Trade Plans ingår i Freemium)',
        SignupPremium: 'Vi strävar efter att bygga investeringsverktyg i världsklass men behöver ditt stöd för att komma framåt',
        signup: 'Registrera',
        login: 'Logga in',
        about_us: 'Om oss',
        visitor: 'Besökare',
        And: 'och',
        SendUsMessage: 'Hör gärna av dig',
        TellUs: 'berätta ',
        BetterServeYou: 'hur vi kan hjälpa dig som investerare',
        Card_Number: 'Kortnummer',
        Card_Expiry: 'MM/YY',
        Card_CVC: 'CVC',

        Help: 'Hjälp',
        Create_account: "Skapa nytt konto",
        Your_eMail: "Ange din e-post",
        Choose_your_password: "Skapa ett lösenord",
        CREATE_ACCOUNT_Upper: "SKAPA NYTT KONTO",
        account_verified: "Kontot verifierat",
        Login_account: "LOGGA IN PÅ DITT KONTO",
        Your_password: "Ange ditt lösenord",
        Forgotten_password: "Har du glömt ditt lösenord",
        Continue_withGoogle: "Fortsätt med Google",
        Continue_withFacebook: "Fortsätt med Facebook",
        No_account: "Har du inget konto?",
        Register: "Registrera dig här",
        Have_account: "Har du redan ett konto?",
        confirm_code_via_email: "verifiera koden i din Epost",
        minimum_eight_characters: 'Minst 8 tecken',
        pe: "P/E",
        last_earnings: "Senaste Rapport",
        next_earnings: "Nästa Rapport",
        eps: "EPS",
        price_last_close: "Pris (Senaste)",
        Exchange: "Marknadsplats",
        company_information: "Bolagsfakta",
        About: "Om Bolaget",
        ceo_comments: "VD ord & Rapporter",
        sector_peers_in: "Liknande Bolag i",

        menu_overview: "Översikt",
        menu_why_invest_in_us: "Varför investera i oss?",
        menu_leadership_and_board: "Ledning & Styrelse",
        menu_press_releases_and_news: "Pressmeddelanden & nyheter",
        menu_reports_and_media: "Rapporter & Media",
        menu_company_calendar: "Kalender",
        menu_corporate: "Bolagsdokument",

        ul_menu_management_and_board: "Ledning & Styrelse",
        ul_menu_why_ohg: "Varför OHG?",

        event_name: "Händelse",
        period: "Period",
        time: "Tid",

        monday: "Måndag",
        tuesday: "Tisdag",
        wednesday: "Onsdag",
        thursday: "Torsdag",
        friday: "Fredag",
        saturday: "Lördag",
        sunday: "Söndag",

        file: "Fil",

        edger_module_add_to_watchlist_on_edger_finance: "Lägg till i bevakningslistan på Edger Finance",
        edger_module_read_more: "Läs mer",
        edger_module_custom_kpis: "Skräddarsydda nyckeltal och gränssnittet tillhandahålls av edger.finance. Ditt verktyg för att navigera aktiemarknaden. Du kan enkelt lägga till företaget i din bevakningslista på Edger genom att klicka på knappen för bevakningslista ovan.",

        overview: "ÖVERSIKT",
        insider_trades: "INSYNSHANDEL",
        alternative_data: "ALTERNATIV DATA",
        alternative_text: "Här kan du inom kort hitta hållbarhetsdata och alternativa nyckeltal för att lättare kunna förstå vad det är som gör det här bolaget unikt.",
        analyst: 'Analytiker',
        Revenue: "Intäkter (Miljoner)",
        Date: "Datum",
        Note: "Anteckning",
        any_journal: "Börja samla dina tankar och ny infroamtion om",
        Journal: "Dagbok",
        Filter: "Filter",
        Display: "Visa",
        Quarterly: "Kvartalsvis",
        quarterly_report: "Kvartalsrapport",
        annual_report: "Årlig rapport",
        full_year: "Årsvis",
        List: "Lista",
        Charts: "Graf",
        configure_watchlist_columns: "Konfigurera Kolumner ",
        rename_watchlist: "Byt namn på Bevakningslista",
        delete_watchlist: "Radera Bevakningslista",
        add_to: "Lägg till",

        price_eod: "Pris (EOD)",
        last_eps: "Senaste EPS",
        last_sales: "Senaste Omsättning (M)",
        trade_plan: "Handlingsplan",
        no_plan_defined: "ingen plan definierad",
        active_between: "Aktiv mellan",
        emails: "Epost",
        risk_return_ratio: "Risk/Avkastning Ratio",
        view: "Vy",
        date_expired: "utdaterat datum",
        create_new_trade_plan: "Skapa en ny Handlingsplan",
        plan_has_expired: "Denna handlingsplan har upphört",
        target_price_met: "Target price har uppfyllts",
        entry_price_met: "Entry price har uppfyllts",
        stoploss_price_met: "Stoploss price har uppfyllts",
        email_alerts_silenced: "E-post larmen har stängts av",
        enable_email_alerts: "återställ E-post larmen",
        chart_view: "Graf Vy",
        Days: "Dagar",
        Count: "Antal",
        Average: "Genomsnitt",
        Day: "Dag",
        Documents: "Dokument",
        Missing: "Saknas",
        research_analyser: "Analyser",
        Overlays: "Inställningar",
        ma_twenty: "MA 20",
        ma_fifty: "MA 50",
        ma_twohundred: "MA 200",
        insiders_by_transaction: "Insynsdata: Transaktioner",
        inisders_silent_period: "Insynsdata: Tyst Period",
        Compare: "Jämför",
        trade_plan_target_price: "Target",
        trade_plan_entry_price: "Entry",
        trade_plan_stoploss_price: "Stopploss",
        watchlist_add_new_notes: "Ny anteckning",

        one_year: "1 ÅR",
        three_years: "3 ÅR",
        five_years: "5 ÅR",
        ten_years: "10 ÅR",
        W_V: "V",

        instrument_name: "Instrument ",
        Person: "Person",
        person_title: "Titel",
        action: "Händelse",
        Volume: "Volym",
        qty_or_amount: "Kvantitet eller Antal",
        Annually: "Årsvis",
        no_name_screener_not: "Inget namn, Screener sparas inte",
        company_info: "Bolagsinformation",
        add_columns: "Lägg till Kolumner",
        columns: 'Kolumner',
        rename_screener: "Byt namn på Screener",
        delete_screener: "Radera Screener",
        enter_user_account: "Skriv in din verifierade Epost-adress så skickar vi en verifierings kod.",
        confirm_code: "Bekräfta Kod",
        email_secret_code: "Skicka verifieringskod",

        Title: 'Titel',

        No_sectors_selected: 'Inga sektorer valda.',

        sweden: 'Sverige',
        finland: 'Finland',
        norway: 'Norge',
        denmark: 'Danmark',

        no_account_selected_yet: 'Inget konto är valt ännu',

        Type: 'Sort',
        Quantity: 'Kvantitet',
        Amount: 'Belopp',

        news: 'Nyheter',

        label_leader: 'Ledaren',
        label_my_balance: 'Balans',
        label_average: 'Medel',

        sector_basic_materials: 'Material',
        sector_consumer_discretionary: 'Sällanköpsvaror',
        sector_consumer_staples: 'Stapelvaror',
        sector_financials: 'Finans',
        sector_health_care: 'Hälsovård',
        sector_industrials: 'Industri',
        sector_oil_and_gas: 'Olja & Gas',
        sector_technology: 'Teknik',
        sector_telecom: 'Telekom',
        sector_utilities: 'Energiförsörjning',
        sector_aluminum_and_steel: 'Aluminium & Stål',
        sector_chemicals: 'Kemikalier',
        sector_forestry_and_paper: 'Skog & Papper',
        sector_industrial_metals: 'Industrimetaller',
        sector_mining: 'Gruvor',
        sector_precious_metals: 'Ädelmetaller',
        sector_auto_and_equipment: 'Fordon & Utrustning',
        sector_casinos_and_gambling: 'Betting & Casino',
        sector_gaming: 'Gaming',
        sector_homefurnishing_retail: 'Hus & Hem',
        sector_media: 'Media',
        sector_retail: 'Detaljhandel',
        sector_travel_and_leisure: 'Resor & Nöjen',
        sector_food_and_beverage: 'Mat & Dryck',
        sector_food_and_drug_retailers: 'Livsmedel',
        sector_health_products: 'Hälsoprodukter',
        sector_personal_and_household_goods: 'Hushålsnäravaror',
        sector_banks: 'Banker',
        sector_financial_services: 'Finansiella tjänster',
        sector_holding_companies: 'Investmentbolag',
        sector_insurance: 'Försäkring',
        sector_real_estate: 'Fastigheter',
        sector_biotechnology: 'Bioteknik',
        sector_healthcare_facilities: 'Sjukhus & Vårdhem',
        sector_medical_equipment: 'Medicinsk Utrustning',
        sector_medical_supplies: 'Sjukvårdsprodukter',
        sector_pharmaceuticals: 'Läkemedel',
        sector_aerospace_and_defense: 'Millitär & Försvar',
        sector_automobiles_and_parts: 'Bilar & Fordonsdelar',
        sector_business_consultants: 'Affärskonsulter',
        sector_business_support_and_services: 'Äffärsstöd & Tjänster',
        sector_construction_and_infrastructure: 'Byggnation & Infrastruktur',
        sector_construction_and_materials: 'Byggnation & Material',
        sector_education: 'Utbildning',
        sector_electronic_components: 'Elektroniska komponeneter',
        sector_energy_and_recycling: 'Energi & Återvinning',
        sector_industrial_components: 'Industrikomponeneter',
        sector_industrial_conglomerates: 'Industrikonglomerat',
        sector_industrial_machinery: 'Industrimaskiner',
        sector_installation: 'Installation',
        sector_outsourcing: 'Bemanning',
        sector_transportation: 'Transport',
        sector_oil_and_gas_drilling: 'Olja & Gas Borrning',
        sector_oil_and_gas_equipment_and_services: 'Olja & Gas Utrustning & Tjänster',
        sector_oil_and_gas_exploration_and_production: 'Olja & Gas Exploatering & Produktion',
        sector_oil_and_gas_storage_and_transportation: 'Olja & Gas Lagring & Transport',
        sector_communications: 'Kommunikation',
        sector_computer_hardware: 'Datorer & Hårdvara',
        sector_financial_technology: 'Finansteknik',
        sector_hardware_and_equipment: 'Hårdvara & Utrustning',
        sector_it_consulting: 'IT-Konsulter',
        sector_it_services: 'IT-Tjänster',
        sector_measuring_and_analysis: 'Mätning & Analys',
        sector_security_and_surveillance: 'Säkerhet & Övervakning',
        sector_semiconductors: 'Halvledare',
        sector_software: 'Mjukvara',
        sector_telecommunications_services: 'Telekommunikationstjänster',
        sector_telecommunications: 'Telekommunikation',
        sector_electric_utilities: 'Energi',
        sector_renewable_energy: 'Förnybar Energi',
        sector_sect_industry: 'Sektor / Industri',
        sector_no_sector: 'Ingen Sektor',

        competition_portfolio: 'Aktietävling',
        positions: 'Portfölj',
        competition_by_invitation: 'Inbjudan till tävling',
        invitation_code: 'Anmälningskod',
        register: 'REGISTRERA',
        host_a_competition: 'Vill du annordna en aktietävling? Hör av dig till oss: petter@tradersalloy.com',
        screen_name: 'Namn',
        start_now: 'STARTA',
        competitions: 'Tävling ',
        account: 'Konto',
        user: 'Namn',
        cash : 'Likvida medel',
        balance: 'Saldo',
        exposure: 'Exponering',
        available_cash: 'Tillgängliga medel',
        my_transactions: 'MINA TRANSAKTIONER',
        my_positions: 'MIN PORTFÖLJ',
        competition_broker: 'Lägg Order',
        buy: 'KÖP',
        sell: 'SÄLJ',
        done: 'Färdigt',
        amount_gt_cash: 'Du har inte tillräckligt med likvida medel',
        stock: 'Aktie',
        search_by_company_name: 'Sök efter företagets namn',
        total_buy_price: 'Belopp',
        submit_buy_order: 'LÄGG KÖPORDER',
        in_my_positions: 'Antal på kontot',
        total_sell_price: 'Belopp',
        submit_sell_order: 'LÄGG SÄLJORDER',
        competition_standings: 'Topplista',
        chart: 'GRAF',
        table: 'TABELL',
        rank : 'Rank',
        top_holding : 'Favorit',
        transactions: 'Transaktioner',
        buy_sell_transactions: 'KÖP & SÄLJTRANSAKTIONER',
        paired_transactions: 'MATCHADE TRANSAKTIONER',
        qty: 'Kvantitet',
        transaction_type: 'Transaktionsyp',
        amount: 'Belopp',
        currency: 'Valuta',
        no_rows : 'Inga transaktioner har skett ännu',
        entry: 'KÖP',
        exit: 'SÄLJ',
        result: 'RESULTAT',
        commission : 'Courtage',
        profit_loss: 'Vinst/Förlust',
        notes: 'Anteckningar ',
        ticker: 'Kortnamn',
        date: 'Datum',
        price: 'Kurs',
        price_today: 'Dagens kurs',
        pl: 'Resultat',
        pl_pct: 'Resultat %',
        pl_sum: 'Total V/F',
        dairy_notes: 'Anteckningar ',
        add_journal_notes: 'Ny Anteckning',
        rows: 'Rader',
        average: 'Genomsnitt',
        trades_per_week: 'Transaktioner per vecka',
        conviction_sector_exposure: 'Övertygelse jämfört med Exponering',
        exposure_by_sector: 'Fördelning per Sektor',
        insufficient_cash: 'Otillräckliga kontanter',

        estimations: 'Uppskattningar',
        estimates: 'Uppskattningar',
        estimate: 'Uppskattning',

        visitors_list: 'Exempellista',

        description: 'Beskrivning',

        memorandum: 'Memorandum',
        subscribe_for_shares_here: 'Teckna aktier här',
        ceo_vision: 'VD - Vision',
        sign_up_to_keep_up_to_date_with_the_news: 'Skriv upp dig för att hänga med i nyheter!',
        company_is_intended: 'avses att listas inom en snar framtid. Skriv upp dig på vårt nyhetsbrev för att hänga med i det senaste.',
        subscribe: 'Prenumerera',
        in_numbers: 'i siffror',

        tag_analytics:'Analytiker',
        tag_rec:'Rekommendation',
        tag_stocks:'Aktier',
        tag_tech:'Teknik',
        tag_macro:'Makro',
        tag_fwn_fx:'Valuta',
        tag_industrials:'Industri',
        tag_financials:'Finans',
        tag_fwn_consumergoods:'Konsumentvaror',
        tag_healthcare:'Hälsovård',
        tag_retail:'Detaljhandel',
        tag_commodities:'Råvaror',
        tag_interim:'Tillfällig',
        tag_fwn_consumerservices:'Konsumenttjänster',
        tag_realestate:'Fastigheter',
        tag_fwn_bank:'Bank',
        tag_energy:'Energi',
        tag_lifescience:'Lifescience',
        tag_ma:'M & A',
        tag_insider:'Insysnsperson',
        tag_medtech:'Medicinteknik ',
        tag_leisure:'Fritid',
        tag_automotive:'Transport',
        tag_media:'Media',
        tag_telecom:'Telekom',
        tag_fwn_biotech:'Bioteknik',
        tag_pharmaceuticals:'Pharmaceuticals',
        tag_fwn_corporateactions:'Företagshändelse',
        tag_fwn_ngmexchange:'NGM',
        tag_exx:'EEX',
        tag_oil:'Olja',
        tag_fwn_construction:'Byggnation',
        tag_fwn_forestrypaper:'Skogsbruk',
        tag_gaming:'Gaming',
        tag_fwn_software:'Mjukvara',
        tag_foods:'Mat',
        tag_funding:'Finansiering',
        tag_ecommerce:'e-Handel',
        tag_renewable:'Förnybart',
        tag_fwn_coronavirus:'Corona',
        tag_fwn_fashion:'Mode',
        tag_fwn_guidance:'Guidning',
        tag_betting:'Betting',
        tag_estimates:'Estimat',
        tag_ipo:'IPO',
        tag_dividend:'Utdelning',
        tag_fwn_commercialservic:'Kommersiella tjänster',
        tag_shipping:'Frakt',
        tag_wind:'Vind',
        tag_fwn_summary:'Summering',
        tag_fwn_capitalgoods:'Kapitalvaror',
        tag_fwn_semiconductor:'Halvledare',
        tag_politics:'Politik',
        tag_fwn_airlines:'Flygbolag',
        tag_fwn_personalgoods:'Heminredning',
        tag_fwn_steel:'Stål',
        tag_fwn_logistics:'Logistik',
        tag_fwn_casino:'Casino',
        tag_transports:'Transport',
        tag_basemetals:'Basmetaller',
        tag_fwn_privateequity:'Private Equity',
        tag_services:'Tjänster',
        tag_calendar:'Kalender',
        tag_cryptocurrency:'Kryptovalutor',
        tag_fwn_xltrade:'XL Trade',
        tag_precious:'Dyrbar',
        tag_fwn_sustainability:'Hållbarhet',
        tag_defence:'Försvar',
        tag_copper:'Koppar',
        tag_fwn_intradag:'Intradag',
        tag_fwn_beverage:'Dryck',
        tag_fwn_aluminium:'Aluminium',
        tag_agriculture:'Jordbruk',
        tag_fwn_stockholmbullets:'Stockholms Nyheter',
        tag_fwn_sports:'Sport',
        tag_fwn_geopolitics:'Geopolitik',
        tag_fwn_hydrogen:'Vätgas',
        tag_fwn_insurance:'Försäkring',
        tag_fwn_commercialvehicl:'Kommersiella Fordon',
        tag_fwn_oott:'OOTT',
        tag_gold:'Guld',
        tag_fwn_zinc:'Zinc',
        tag_fwn_itconsultants:'IT-Konsulter',
        tag_fwn_healthservices:'Hälsovårdstjänster',
        tag_fwn_tobacco:'Tobak',
        tag_aviation:'Flyg',
        tag_fintech:'Fintech',
        tag_fwn_buybacks:'Återköp',
        tag_fwn_esport:'e-Sport',
        tag_fwn_finwire:'Finwire',
        tag_currencies:'Valutor',
        tag_fwn_chemicals:'Kemikalier',
        tag_fwn_cleantech:'Cleantech',
        tag_fwn_utilities:'Energiförsörjning',
        tag_fwn_cybersecurity:'IT-Säkerhet',
        tag_fwn_silver:'Silver',
        tag_oilservice:'Oljetjänster',
        tag_fwn_pepins:'Pepins',
        tag_fwn_blockchain:'Blockkedja',
        tag_fwn_spac:'SPAC',
        tag_fwn_nickel:'Nickel',
        tag_exclusive:'Exklusivt',
        tag_fwn_hotel:'Hotell',
        tag_materials:'Material',
        tag_biometrics:'Biometri',
        tag_hedgefunds:'Hedgefonder',
        tag_fwn_seafood:'Fiske',
        tag_fwn_lead:'Bly',
        tag_fwn_solar:'Solenergi',
        tag_fwn_smallcap:'Småbolag',
        tag_fixedincome:'Obligationer',
        tag_space:'Rymd',
        tag_fwn_climate:'Klimat',
        tag_mutualfunds:'Fonder',
        tag_fwn_coal:'Kål',
        tag_fwn_recsummary:'Summering',
        tag_ta_pressrelease: 'Pressmedelande',
        tag_pressrelease: 'Pressmedelande',
        // pressrelease: 'Press Medelande',
    }
};

const i18n = new VueI18n({
    locale: 'sv', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages,
    silentTranslationWarn: true
});

export default i18n;
