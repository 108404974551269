import { render, staticRenderFns } from "./MenuMobile.vue?vue&type=template&id=65d224ce&scoped=true&lang=pug&"
import script from "./MenuMobile.vue?vue&type=script&lang=js&"
export * from "./MenuMobile.vue?vue&type=script&lang=js&"
import style0 from "./MenuMobile.vue?vue&type=style&index=0&id=65d224ce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d224ce",
  null
  
)

export default component.exports