export default {
    state: {
        prices: [],
        cachedPrices: [],
        pricesWithFrequency: [],
        compareDatasOnChart: [],
        blockWhileAddingOverlay: false,
        showDisplayButton: {
            ma20: false,
            ma50: false,
            ma200: false,
            earningDate: false,
            insidersSilentPeriod: false,
            insidersByTransaction: false,
            textTool: false,
            newsTool: false
        },
        addCompare: false,
        showOverlays: [
            {
                overlay: "MA 20",
                name: "ma20",
                display: false
            },
            {
                overlay: "MA 50",
                name: "ma50",
                display: false
            },
            {
                overlay: "MA 200",
                name: "ma200",
                display: false
            },   
            {
                overlay: "Earning Date",
                name: "earningDate",
                display: false
            },
            {
                overlay: "Insiders: Silent Period",
                name: "insidersSilentPeriod",
                display: false
            },   
            {
                overlay: "Insiders: By Transaction",
                name: "insidersByTransaction",
                display: false
            },   
            // {
            //     overlay: "Portfolio Transactions",
            //     name: "portfolioTransactions",
            //     display: false
            // },
            // {
            //     overlay: "MA 20",
            //     name: 'journalNotes',
            //     display: false
            // },
            // {
            //     overlay: "MA 20",
            //     name: 'news',
            //     display: false
            // },
            {
                overlay: "Journal Notes",
                name: 'textTool',
                display: true
            },
            {
                overlay: "News",
                name: `newsTool`,
                display: false
            }
        ]
    },

    getters: {
        // getPrices(state) {
        //     return state.prices;
        // },
        // getCachedPrices(state) {
        //     return state.cachedPrices;
        // },
        // getPricesWithFrequency(state) {
        //     return state.pricesWithFrequency;
        // },
        // getLatestPriceToday(state) {
        //     return state.prices[state.prices.length - 1][4];
        // },
        // getCompareDatasOnChart(state) {
        //     return state.compareDatasOnChart;
        // },
        getShowOverlays(state) {
            return state.showOverlays;
        },
        // getBlockWhileAddingOverlay(state) {
        //     return state.blockWhileAddingOverlay;
        // },
        // getAddCompare(state) {
        //     return state.addCompare;
        // },
        getShowDisplayButton(state) {
            return state.showDisplayButton;
        }
    },

    mutations: {
        // setPrices(state, payload) {
        //     state.prices = payload;
        // },
        // setCachedPrices(state, payload) {
        //     state.cachedPrices = payload;
        // },
        // setPricesWithFrequency(state, payload) {
        //     state.pricesWithFrequency = payload;
        // },
        // setCompareDatasOnChart(state, payload) {
        //     state.compareDatasOnChart = payload;
        // },
        setShowOverlays(state, payload) {
            state.showOverlays = payload;
        },
        // setBlockWhileAddingOverlay(state, payload) {
        //     state.blockWhileAddingOverlay = payload;
        // },
        // setAddCompare(state, payload) {
        //     state.addCompare = payload;
        // },
        setShowDisplayButton(state, payload) {
            state.showDisplayButton = payload;
        }
    },

    // actions: {
    //     async loadPrices({ commit, state }, stock_id) {
    //         const prices = (await ApiService.getPrices(stock_id)).data.ohlcv;

    //         commit("setPrices", prices);

    //         const priceObject = {
    //             stock_id: stock_id,
    //             prices: prices
    //         };

    //         let cachedPricesTemp = state.cachedPrices;

    //         cachedPricesTemp.push(priceObject);

    //         cachedPricesTemp = _.uniqBy(cachedPricesTemp, 'stock_id');

    //         state.cachedPrices = cachedPricesTemp;
    //     },
    // }
};
