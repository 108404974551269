<template lang="pug">
    div(class="mt-2" style="position:relative; font-size:14px; line-height:1.6; border:0px solid;")
        //- div {{ getIsListed }}
        div(@click="openPage('/backoffice/irHome')" class="cursor pl-2" :class="activePage === '/backoffice/irHome' ? 'active-link' : ''" style="border:0px solid;") IR Home
        div(@click="openPage('/backoffice/investmentCase')" class="cursor pl-2" :class="activePage === '/backoffice/investmentCase' ? 'active-link' : ''" style="border:0px solid;") Investment Case
        div(@click="openPage('/backoffice/customKpis')" class="cursor pl-2" :class="activePage === '/backoffice/customKpis' ? 'active-link' : ''" style="border:0px solid;") Custom KPIs
        div(v-if="getIsListed" @click="openPage('/backoffice/marketCommentary')" class="cursor pl-2" :class="activePage === '/backoffice/marketCommentary' ? 'active-link' : ''" style="border:0px solid;") Market Commentary
        div(@click="openPage('/backoffice/leadership')" class="cursor pl-2" :class="activePage === '/backoffice/leadership' ? 'active-link' : ''" style="border:0px solid;") Leadership
        div(@click="openPage('/backoffice/calendar')" class="cursor pl-2" :class="activePage === '/backoffice/calendar' ? 'active-link' : ''" style="border:0px solid;") Calendar
        div(v-if="getIsListed" @click="openPage('/backoffice/pressReleases')" class="cursor pl-2" :class="activePage === '/backoffice/pressReleases' ? 'active-link' : ''" style="border:0px solid;") Press Releases
        div(v-if="getIsListed" @click="openPage('/backoffice/reportsAndMedia')" class="cursor pl-2" :class="activePage === '/backoffice/reportsAndMedia' ? 'active-link' : ''" style="border:0px solid;") Reports & Media
        //- div(@click="openPage('/backoffice/financialHistory')" class="cursor pl-2" :class="activePage === '/backoffice/financialHistory' ? 'active-link' : ''" style="border:0px solid;") Financial History
        div(@click="openPage('/backoffice/bespokePage')" class="cursor pl-2" :class="activePage === '/backoffice/bespokePage' ? 'active-link' : ''" style="border:0px solid;") Bespoke Page
        div(@click="openPage('/backoffice/stylingCss')" class="cursor pl-2" :class="activePage === '/backoffice/stylingCss' ? 'active-link' : ''" style="position:absolute; bottom:0; width:100%; border:0px solid;") Styling CSS
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "MenuBackoffice",
    data() {
        return {
            activePage: "/backoffice",
        };
    },
    mounted() {
        console.log(`/backoffice/${this.$route.name}`);

        this.activePage = `/backoffice/${this.$route.name}`;
    },
    computed: {
        ...mapGetters(['getIsListed']),
    },
    methods: {
        openPage(page) {
            if(this.activePage !== page) {
                this.activePage = page;

                this.$router.push({ path: page });
            }
        },
    },
}
</script>

<style scoped>
.cursor {
    cursor: pointer;
}

.active-link {
    background-color: #D9D9D9;
}
</style>
