<template lang="pug">
    div(class="ir-company-calendar-main-regular")
        div(style="max-width:1000px; border:0px solid;")
            div(class="container-element mt-2" style="border:0px solid;")
                //- header
                div(class="header-block")
                    div(class="col-block pl-2" style="float:left; width:25%; font-size:12px; font-weight:bold; height:25px; background-color:#edebeb; padding-top:3px; border:0px solid;") {{ $t('event_name') }}
                    div(class="col-block" style="float:left; width:25%; font-size:12px; font-weight:bold; height:25px; background-color:#edebeb; padding-top:3px; border:0px solid;") {{ $t('description') }}
                    div(class="col-block" style="float:left; width:20%; font-size:12px; font-weight:bold; height:25px; background-color:#edebeb; padding-top:3px; border:0px solid;") {{ $t('date') }}
                    div(class="col-block" style="float:left; width:20%; font-size:12px; font-weight:bold; height:25px; background-color:#edebeb; padding-top:3px; border:0px solid;") {{ $t('time') }}
                    div(class="col-block" style="float:left; width:10%; font-size:12px; font-weight:bold; height:25px; background-color:#edebeb; padding-top:3px; border:0px solid;") {{ $t('file') }}
                
                //- body
                div(v-for="calendar in mergedCalendarAndCalendarFinancialReporting" class="row-block")
                    div(class="col-block ellipsis pl-2 mt-1 mb-1" style="float:left; width:25%; font-size:12px; height:25px; border:0px solid;") {{ calendar.name }}
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:25%; font-size:12px; height:25px; border:0px solid;") {{ calendar.description !== undefined ? calendar.description : '' }}
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:20%; font-size:12px; height:25px; border:0px solid;") {{ calendar.date }}
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:20%; font-size:12px; height:25px; border:0px solid;") {{ calendar.time }}
                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; font-size:12px; height:25px; border:0px solid;")
                        v-icon(@click="calendarData(calendar)" size="16") mdi-calendar-text
</template>

<script>
import { mapGetters } from "vuex";
import * as ics from "ics";
import moment from "moment";
import _ from "lodash";
import { saveAs } from "file-saver";

export default {
    name: "CompanyCalendar",
    data() {
        return {
            mergedCalendarAndCalendarFinancialReporting: [],
        };
    },
    mounted() {
        this.setMergedCalendarAndCalendarFinancialReporting();

        console.log(moment().format('YYYY-MM-DD'));
        console.log(JSON.parse(JSON.stringify(this.mergedCalendarAndCalendarFinancialReporting)));
    },
    computed: {
        ...mapGetters(['getCompany', 'getLanguageCode']),
        todaysDate() {
            return moment().format('YYYY-MM-DD');
        },
        calendars() {
            let calendar = this.getCompany.calendar;

            return calendar;
        },
        calendarFinancialReporting() {
            let onlyFutureFinancialReportings = this.getCompany.calendarFinancialReporting.filter((r) => r.date_report > this.todaysDate);

            return onlyFutureFinancialReportings;
        },
    },
    watch: {
        getLanguageCode() {
            this.setMergedCalendarAndCalendarFinancialReporting();
        }
    },
    methods: {
        calendarData(calendar) {
            const date = calendar.date;
            const year = Number(date.split('-')[0]);
            const month =  Number(date.split('-')[1]);
            const day =  Number(date.split('-')[2]);
            const time = calendar.time !== undefined ? calendar.time : '';
            const timeEnd = calendar.time_end !== undefined ? calendar.time_end : '';
            const start = time !== '' ? [year, month, day, Number(time.split(':')[0]), Number(time.split(':')[1])] : [year, month, day];
            const companyName = this.getCompany.name;
            const duration = moment.duration(moment(`${date} ${timeEnd}`).diff(moment(`${date} ${time}`)));
            const hours = Math.floor(duration.asHours());
            const hoursToMinutes = hours * 60;
            const minutes = duration.asMinutes() - hoursToMinutes;

            const durationObject = {};

            if(hours > 0) {
                durationObject.hours = hours;
            }

            if(minutes > 0) {
                durationObject.minutes = minutes;
            }

            const event = {
                start: start,
                // start: [year, month, day, 6, 30],
                // duration: { hours: 6, minutes: 30 },
                duration: durationObject,
                title: `${companyName} - ${calendar.name}`,
                description: calendar.description,

                // Before adding location it need to be added as a column in table company_calendar
                // location: 'Folsom Field, University of Colorado (finish line)',
                
                // url: 'http://www.bolderboulder.com/',
                // geo: { lat: 40.0095, lon: 105.2669 },
                // categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
                status: 'CONFIRMED',
                // busyStatus: 'BUSY',
                // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
                // attendees: [
                    // { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
                    // { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
                // ]
            };

            ics.createEvent(event, (error, value) => {
                if(error) {
                    console.log(error);
                } else {
                    const blob = new Blob([value], {type: "text/calendar;charset=utf8"});

                    console.log(blob);

                    saveAs(blob, `${calendar.name}.ics`);
                }
            });
        },
        getDayName(date) {
            return this.$t(moment(date).format('dddd').toLowerCase());
        },
        getEventName(type) {
            let eventName = "";

            if(type === 3) {
                eventName = "annual_report";
            } else if(type === 4) {
                eventName = "quarterly_report";
            }

            return (eventName !== "") ? this.$t(eventName) : "Not set";
        },
        setMergedCalendarAndCalendarFinancialReporting() {
            const todaysDate = moment().format('YYYY-MM-DD');

            let mergedCalendar = this.calendars.concat(this.calendarFinancialReporting);

            for(const calendar of mergedCalendar) {
                if(calendar.name === undefined) {
                    calendar.name = `${this.getEventName(calendar.type_report)} ${calendar.period}`;
                }

                if(calendar.date === undefined) {
                    calendar.date = calendar.date_report;
                }
            }

            mergedCalendar = _.orderBy(mergedCalendar, ["date"], ["asc"]);
            mergedCalendar = mergedCalendar.filter((c) => c.date > todaysDate);

            this.mergedCalendarAndCalendarFinancialReporting = mergedCalendar;
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
