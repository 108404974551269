<template lang="pug">
    div(class="text-center" style="font-family:Arial, Helvetica, sans-serif;")
        v-dialog(v-model="dialog" width="550")
            v-card(style="position:relative;")
                v-card-text
                    div(style="height:550px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                        div(class="pt-4 mb-3" style="font-size:18px; font-weight:bold;") {{ title }}
                        div(style="font-size:12px; color:#1C1B1F; line-height:1.2;")
                            div(v-html="text")
                            div(v-if="closeText" class="text-center" style="border:0px solid;")
                                div(@click="dialog = false" class="mt-8" style="cursor:pointer;") Close
                v-icon(v-if="closeIcon" @click="dialog = false" style="position:absolute; top:7px; right:7px;") mdi-close
</template>

<script>
export default {
    name: "PopupInformation",
    props: {
        openDialog: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        closeIcon: {
            type: Boolean,
            default: false
        },
        closeText: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    watch: {
        openDialog() {
            if(this.openDialog) {
                this.dialog = this.openDialog;
            }
        }
    },
}
</script>

<style scoped>

</style>
