<template lang="pug">
    div(class="ir-invest-in-us-main-mobile" style="border:0px solid;")
        div(id="headline-invest-in-us" class="mb-2")
            h2 {{ $t('menu_why_invest_in_us') }}
        div(class="container-element" style="border:0px solid;")
            div(v-for="why in whys" :key="why.why_id" class="row-block mb-2 pb-2")
                div(class="mb-2" style="border:0px solid;")
                    img(:src="why.img" style="width:100%;")
                div(:style="checkIfOpened(why.why_id) ? `overflow:hidden; max-height:${heightMethod(why.why_id) > 200 ? heightMethod(why.why_id) + 40 : 200}px; transition:max-height 0.3s ease-out;` : `overflow:hidden; max-height:200px; transition:max-height 0.3s ease-in;`" style="position:relative; border:0px solid;")
                    template(v-if="checkIfThereIsMoreThanTopParagraphs(why.detail)")
                        h2(@click="setElementHeight(why.why_id)" class="pl-2 pr-6 pb-1" style="border:0px solid;") {{ why.title }}
                    template(v-else)
                        h2(class="pl-2 pr-6 pb-1" style="border:0px solid;") {{ why.title }}
                    div(class="mt-1 pl-2 pr-2 pb-2" style="border:0px solid;")
                        div(v-if="checkIfOpened(why.why_id)" v-html="why.detail")
                        div(v-else v-html="getTopParagraph(why.detail)")
                        div(:id="`whyElement2${why.why_id}`" v-html="why.detail" style="visibility:hidden;")
                    template(v-if="checkIfThereIsMoreThanTopParagraphs(why.detail)")
                        v-icon(@click="setElementHeight(why.why_id)" v-if="checkIfOpened(why.why_id)" style="position:absolute; top:0; right:0;") mdi-chevron-up
                        v-icon(@click="setElementHeight(why.why_id)" v-else style="position:absolute; top:0; right:0;") mdi-chevron-down
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "InvestInUsMobile",
    data() {
        return {
            openedWhys: [],
            height: 218,
        };
    },
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.whys)));
    },
    computed: {
        ...mapGetters(['getCompany']),
        whys() {
            return this.getCompany.whys;
        },
    },
    methods: {
        getTopParagraph(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let topParagraphString = '';

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    topParagraphString += `<${el.localName} class="${el.className}">${el.innerHTML}</${el.localName}>\n\n`;
                }
            }

            return topParagraphString;
        },
        checkIfThereIsMoreThanTopParagraphs(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let numberOfParagraphsTotally = 0;
            let numberOfParagraphsWithClass = 0;

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    numberOfParagraphsWithClass += 1;
                }

                if(el.localName === 'p') {
                    numberOfParagraphsTotally += 1;
                }
            }

            return (numberOfParagraphsWithClass !== numberOfParagraphsTotally);
        },
        heightMethod(id) {
            if(this.openedWhys.find((w) => w.id === id) !== undefined) {
                return this.openedWhys.find((w) => w.id === id).height;
            }

            return 218;
        },
        checkIfOpened(id) {
            return this.openedWhys.find((w) => w.id === id) !== undefined;
        },
        setElementHeight(id) {
            const element = document.getElementById(`whyElement2${id}`);

            console.log(`whyElement2${id}`, element.offsetHeight);

            this.height = element.offsetHeight;

            if(this.openedWhys.find((w) => w.id === id) === undefined) {
                const tempObject = {
                    id: id,
                    height: this.height
                };

                this.openedWhys.push(tempObject);
            } else {
                this.openedWhys = this.openedWhys.filter((w) => w.id !== id);
            }
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
