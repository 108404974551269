<template lang="pug">
    div(v-if="getLoggedIn" class="ir-leadership-team-main-regular" style="width:950px; border:0px solid red;")
        div(class="mb-2")
            v-btn(@click="insertMember()" height="20" width="70" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#EF9A1B; text-transform:none; border:1px solid #FB993F;") Insert
        div(style="border:0px solid;")
            //- div(style="font-family:'Times New Roman', Times, serif; font-size:20px; color:#000000; border:0px solid;") Leadership
            template(v-for="member in team")
                div(:key="member.team_id")
                    div(class="row-block" :class="member.odd ? 'odd' : ''" style="height:120px; border:0px solid;")
                        //- div(style="height:120px;")
                        div(style="float:left; width:120px; height:120px; overflow:hidden; border:0px solid;")
                            img(:src="member.photo_img" style="height:120px;")
                        div(class="pt-1 pl-4 pr-4" style="float:left; width:590px; height:120px; overflow-y:scroll; overflow-x:hidden; border:0px solid;")
                            h2(style="border:0px solid;") {{ member.name }}, {{ member.position }}
                            div(class="mt-1") {{ member.bio }}
                        div(class="pt-1 pr-2" style="float:left; font-family:'Courier New'; font-size:12px; width:150px; height:120px; border:0px solid;")
                            div(class="ellipsis" :title="member.name") {{ member.name }}
                            div(class="ellipsis" :title="member.position") {{ member.position }}
                            //- div(class="ellipsis" :title="member.telephone") {{ member.telephone }}
                            div(class="ellipsis" :title="member.email") {{ member.email }}
                            div(class="ellipsis" :title="`Sort by order: ${member.order_by}`") Sort by order: {{ member.order_by }}
                        div(class="text-center pt-1" style="float:left; background-color:#F9F8F8; width:90px; height:120px; border:0px solid;")
                            v-btn(@click="updateMember(member.team_id)" height="20" width="70" :disabled="activeMember === member.team_id" :style="activeMember === member.team_id ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Edit

                    div(v-if="activeMember === member.team_id" style="color:#000000; width:950px; border:0px solid black;")
                        div(class="mt-1 mb-4" :style="`height:${editContainerHeight}px;`" style="border:0px solid black;")
                            div(style="position:relative; float:left; overflow:hidden; background-color:#F9F8F8; width:140px; height:100%; border:0px solid black;")
                                ImageUpload(@saveImage="saveImage" :id="member.team_id" :encodedImage="member.photo_img" :company="getCompany" :updateField="'team'" :height="140")
                                //- img(:src="member.photo_img" style="height:100%; border:0px solid black;")
                                v-btn(@click="deleteMember(member.team_id)" height="20" width="70" style="position:absolute; bottom:29px; right:0; font-family:Poppins; font-size:12px; background-color:#EBEBEB; color:#27407E; text-transform:none; border:1px solid #27407E;") Delete
                            div(class="pl-2" style="float:left; background-color:#F9F8F8; width:590px; border:0px solid black;")

                                template(v-for="lang in getCompany.languages_available")
                                    template(v-for="memberlang in companies.find((c) => c.language === lang.language).team.filter((t) => t.team_id === member.team_id)")
                                        div(style="border:0px solid;")
                                            div(style="border:0px solid;") {{ languageTitle(memberlang.language) }}
                                            div(style="border:0px solid;")
                                                input(type="text" v-model="memberlang.position" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                            div(class="mt-1" style="border:0px solid;")
                                                textarea(rows="6" v-model="memberlang.bio" style="background-color:#FFFFFF; resize:none; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                            div(class="text-right")
                                                v-btn(@click="saveMemberTexts(memberlang)" :disabled="memberlang.position === checkIfChangedTeamText(memberlang.team_id, 'position', memberlang.language) && memberlang.bio === checkIfChangedTeamText(memberlang.team_id, 'bio', memberlang.language)" height="20" width="70" :style="memberlang.position === checkIfChangedTeamText(memberlang.team_id, 'position', memberlang.language) && memberlang.bio === checkIfChangedTeamText(memberlang.team_id, 'bio', memberlang.language) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save

                                //- div(style="border:0px solid;")
                                //-     div(style="border:0px solid;") Svenska
                                //-     div(style="border:0px solid;")
                                //-         input(type="text" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                //-     div(class="mt-1" style="border:0px solid;")
                                //-         textarea(rows="6" style="background-color:#FFFFFF; resize:none; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                //-     div(class="text-right")
                                //-         v-btn(height="20" width="70" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                            div(style="float:left; background-color:#F9F8F8; width:150px; border:0px solid black;")
                                div(class="ml-1" style="border:0px solid;")
                                    div(style="border:0px solid;")
                                        input(type="text" v-model="member.name" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                    div(class="mt-1" style="border:0px solid;")
                                        input(type="text" v-model="member.email" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                    div(class="mt-1" style="border:0px solid;")
                                        input(type="text" v-model="member.linkedin" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                    //- div(class="mt-1" style="border:0px solid;")
                                        input(type="text" v-model="member.telephone" style="background-color:#FFFFFF; width:100%; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                    div(class="mt-2 pl-1" style="border:0px solid;")
                                        div(style="height:20px; border:0px solid;")
                                            div(style="float:left; width:60%; border:0px solid;") Board Member:
                                            div(style="float:left; position:relative; width:40%; border:0px solid;")
                                                v-switch(class="switch pa-0 ma-0" v-model="member.is_board_member" hide-details style="position:absolute; top:-4px; right:-9px;")
                                    div(class="pl-1" style="border:0px solid;")
                                        div(style="height:20px; border:0px solid;")
                                            div(style="float:left; width:60%; border:0px solid;") Sort by Order:
                                            div(class="text-right" style="float:left; width:40%; border:0px solid;")
                                                input(type="text" v-model="member.order_by" style="background-color:#FFFFFF; width:50px; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")
                                        
                                        //- div(style="height:20px; border:0px solid;")
                                            div(style="float:left; width:60%; border:0px solid;") LinkedIn:
                                            div(class="text-right" style="float:left; width:40%; border:0px solid;")
                                                input(type="text" style="background-color:#FFFFFF; width:50px; padding-left:5px; padding-right:5px; border:1px solid lightgrey;")

                                        div(class="text-right mt-1" style="border:0px solid;")
                                            v-btn(@click="saveMember(member)" height="20" width="70" :disabled="checkIfChangedMember(member)" :style="checkIfChangedMember(member) ? 'border:1px solid grey' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                            div(class="text-center" style="float:left; background-color:#F9F8F8; width:70px; border:0px solid black;")
                                div(@click="cancel()" style="font-family:Poppins; font-size:12px; background-color:#F9F8F8; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
</template>

<script>
import ImageUpload from "./helpers/ImageUpload.vue";
import { getLanguageHeadlines } from "./../../helpers/util";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

export default {
    name: "Leadership",
    components: {
        ImageUpload,
    },
    data() {
        return {
            activeMember: 0,
            // loggedIn: false,
            team: [],
            teamCopy: [],
            companies: [],
            companiesCopy: [],
            languageHeadlines: getLanguageHeadlines(),
        };
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }

        // this.loggedIn = userLoggedInCheck();

        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        this.team = JSON.parse(JSON.stringify(this.members));
        this.teamCopy = JSON.parse(JSON.stringify(this.members));

        // console.log(JSON.parse(JSON.stringify(this.getCompanies)));
        // console.log(JSON.parse(JSON.stringify(this.companies.find((c) => c.language === 'sv').team.filter((t) => t.team_id === 15))));
        // console.log(JSON.parse(JSON.stringify(this.companies.find((c) => c.language === 'sv').team.filter((t) => t.team_id === 15))));

        // console.log(this.checkIfChangedTeamText(10, 'position', 'sv'));
        // console.log(this.checkIfChangedMember({
        //     team_id: 16,
        //     name: "Arnold Andersson",
        //     email: "aa@katalysen.com",
        //     is_board_member: null,
        //     order_by: 30,
        // }));
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies']),
        members() {
            let membersTemp = this.getCompany.team;
            let odd = true;

            membersTemp = _.orderBy(membersTemp, ["order_by"], ["asc"]);

            for(const member of membersTemp) {
                odd = !odd;
                member.odd = odd;
            }

            return membersTemp;
        },
        editContainerHeight() {
            return this.getCompany.languages_available.length * 154;
        },
    },
    methods: {
        ...mapActions(['updateTeamBio', 'updateTeamNameEmail', 'deleteTeamMember', 'updateTeamImage', 'insertNewTeamMember']),
        ...mapMutations(['setShowSnackbar']),
        checkIfChangedTeamText(id, type, language) {
            return this.companiesCopy.find((c) => c.language === language).team.find((t) => t.team_id === id)[type];
        },
        checkIfChangedMember(member) {
            const editedMemberName = (member.name !== null) ? member.name : '';
            const editedMemberEmail = (member.email !== null) ? member.email : '';
            const editedMemberLinkedIn = (member.linkedin !== null) ? member.linkedin : '';
            const editedMemberBoardMember = (member.is_board_member !== null) ? member.is_board_member : false;
            const editedMemberOrderBy = member.order_by;

            const memberTemp = this.teamCopy.find((t) => t.team_id === member.team_id);
            const memberName = (memberTemp.name !== null) ? memberTemp.name : '';
            const memberEmail = (memberTemp.email !== null) ? memberTemp.email : '';
            const memberLinkedIn = (memberTemp.linkedin !== null) ? memberTemp.linkedin : '';
            const memberBoardMember = (memberTemp.is_board_member !== null) ? memberTemp.is_board_member : false;
            const memberOrderBy = memberTemp.order_by;

            // console.log(`${memberName} = ${editedMemberName}`);
            // console.log(`${memberEmail} = ${editedMemberEmail}`);
            // console.log(`${memberBoardMember} = ${editedMemberBoardMember}`);
            // console.log(`${memberOrderBy} = ${editedMemberOrderBy}`);

            return (
                memberName.toString() === editedMemberName.toString() &&
                memberEmail.toString() === editedMemberEmail.toString() &&
                memberLinkedIn.toString() === editedMemberLinkedIn.toString() &&
                memberBoardMember.toString() === editedMemberBoardMember.toString() &&
                memberOrderBy.toString() === editedMemberOrderBy.toString()
            );
        },
        async insertMember() {
            const insertObject = {
                company_id: this.getCompany.company_id,
            };

            // console.log(insertObject);

            const result = await this.insertNewTeamMember(insertObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully inserted new team member',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.team = JSON.parse(JSON.stringify(this.members));
            this.teamCopy = JSON.parse(JSON.stringify(this.members));
        },
        updateMember(team_id) {
            console.log(team_id);

            this.team = JSON.parse(JSON.stringify(this.teamCopy));
            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));

            this.team = JSON.parse(JSON.stringify(this.teamCopy));
            this.activeMember = team_id;
        },
        cancel() {
            this.activeMember = 0;
            this.team = JSON.parse(JSON.stringify(this.teamCopy));
            this.companies = JSON.parse(JSON.stringify(this.companiesCopy));
        },
        async deleteMember(team_id) {
            const result = await this.deleteTeamMember(team_id);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully deleted team member',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.team = JSON.parse(JSON.stringify(this.members));
            this.teamCopy = JSON.parse(JSON.stringify(this.members));
        },
        languageTitle(code) {
            return this.languageHeadlines.find((l) => l.code === code).text;
        },
        async saveMemberTexts(obj) {
            const updateMemberTitleAndDetailObject = {
                team_id: obj.team_id,
                company_id: obj.company_id,
                language: obj.language,
                title: obj.position,
                bio: obj.bio,
            };

            // console.log(updateMemberTitleAndDetailObject);

            const result = await this.updateTeamBio(updateMemberTitleAndDetailObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated member bio',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.team = JSON.parse(JSON.stringify(this.members));
            this.teamCopy = JSON.parse(JSON.stringify(this.members));
        },
        async saveMember(member) {
            const updateMemberObject = {
                team_id: member.team_id,
                company_id: member.company_id,
                name: member.name,
                email: member.email,
                linkedin: member.linkedin,
                is_board_member: member.is_board_member,
                order_by: Number(member.order_by),
            };

            // console.log(updateMemberObject);

            const result = await this.updateTeamNameEmail(updateMemberObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated member stats',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.team = JSON.parse(JSON.stringify(this.members));
            this.teamCopy = JSON.parse(JSON.stringify(this.members));
        },
        async saveImage(imageString) {
            const imageUpdateObject = {
                team_id: imageString.id,
                company_id: this.getCompany.company_id,
                image: imageString.image,
            };

            // console.log(imageUpdateObject);

            const result = await this.updateTeamImage(imageUpdateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated member image',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.team = JSON.parse(JSON.stringify(this.members));
            this.teamCopy = JSON.parse(JSON.stringify(this.members));
        },
    },
}
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.switch {
    transform: scale(0.6);
}
</style>
