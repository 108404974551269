var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"trading-vue-legend",style:(_vm.calc_style)},_vm._l((_vm.indicators),function(ind,index){return _c('div',{key:index,staticClass:"t-vue-ind"},[(
      (ind.id === 'Splines_0' && _vm.getShowDisplayButton.ma20) ||
      (ind.id === 'Splines_1' && _vm.getShowDisplayButton.ma50) ||
      (ind.id === 'Splines_2' && _vm.getShowDisplayButton.ma200) ||
      //(ind.id === 'Earnings_0' && getShowDisplayButton.earningDate) ||
      (ind.id === 'InsiderDot_0' && _vm.getShowDisplayButton.insidersByTransaction) ||
      (ind.id === 'Earnings1_0' && _vm.getShowDisplayButton.insidersSilentPeriod) ||
      (ind.id === 'TextTool_0' && _vm.getShowDisplayButton.textTool) ||
      (ind.id === 'NewsTool_0' && _vm.getShowDisplayButton.newsTool)
      )?_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"t-vue-iname"},[_vm._v(_vm._s(ind.name))]),_c('button-group',{attrs:{"buttons":_vm.common.buttons,"ov_id":ind.id,"grid_id":_vm.grid_id,"index":ind.index,"tv_id":_vm.common.tv_id,"display":ind.v},on:{"legend-button-click":_vm.button_click}}),(ind.v && ind.name!='Insiders: By Transaction')?_c('span',{staticClass:"t-vue-ivalues"},_vm._l((ind.values),function(v,vIndex){return _c('span',{key:vIndex,staticClass:"t-vue-lspan t-vue-ivalue",style:({ color: v.color })},[_vm._v(_vm._s(v.value))])}),0):_vm._e(),(ind.unk)?_c('span',{staticClass:"t-vue-unknown"},[_vm._v("(Unknown type)")]):_vm._e()],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }