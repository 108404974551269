<template lang="pug">
    div
        Overview(v-if="getIsListed")
        Ul_overview(v-else)
</template>

<script>
import Overview from "./Overview.vue";
import Ul_overview from "./unlisted/Ul_overview.vue";
import { mapGetters } from "vuex";

export default {
    name: "OverviewPlaceholder",
    components: {
        Overview,
        Ul_overview,
    },
    computed: {
        ...mapGetters(['getCompany', 'getIsListed']),
    },
}
</script>

<style scoped>

</style>
