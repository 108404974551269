<template lang="pug">
    div(class="text-center")
        v-dialog(v-model="dialog" width="800")
            v-card(style="font-family:Courier; background-color:#EEEDED; border:1px solid lightgrey;")
                v-card-text
                    v-container(fluid)
                        v-row
                            v-col(cols="12" style="border:0px solid;")
                                div(style="font-size:36px;") Change Password Form
                            v-col(cols="4" style="font-size:18px; border:0px solid;") Email
                            v-col(cols="8" style="border:0px solid;")
                                input(type="text" v-model="email" class="pl-2 pr-2" style="background-color:#ffffff; width:100%; height:40px; border:1px solid lightgrey;")
                            v-col(cols="4" style="font-size:18px; border:0px solid;") Current Password
                            v-col(cols="8" style="border:0px solid;")
                                input(type="password" v-model="currentPassword" class="pl-2 pr-2" style="background-color:#ffffff; width:100%; height:40px; border:1px solid lightgrey;")
                            v-col(cols="4" style="font-size:18px; border:0px solid;") New Password
                            v-col(cols="8" style="border:0px solid;")
                                input(type="password" v-model="newPassword" class="pl-2 pr-2" style="background-color:#ffffff; width:100%; height:40px; border:1px solid lightgrey;")
                            v-col(cols="4" style="font-size:18px; border:0px solid;") Confirm New Password
                            v-col(cols="8" style="border:0px solid;")
                                input(type="password" v-model="confirmNewPassword" class="pl-2 pr-2" style="background-color:#ffffff; width:100%; height:40px; border:1px solid lightgrey;")
                            v-col(cols="12" class="text-right" style="border:0px solid;")
                                v-btn(@click="updatePassword()" style="font-family:Poppins; font-size:16px; background-color:#EBEBEB; color:#28407E; text-transform:none; border:1px solid #28407E;") Change Password
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
    name: "ChangePassword",
    props: {
        openDialog: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            dialog: false,
            email: "",
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        };
    },
    watch: {
        openDialog() {
            if(this.openDialog) {
                this.dialog = this.openDialog;
            }
        },
        dialog() {
            if(!this.dialog) {
                this.email = '';
                this.currentPassword = '';
                this.newPassword = '';
                this.confirmNewPassword = '';
            }
        },
    },
    methods: {
        ...mapActions(['changePassword']),
        ...mapMutations(['setShowSnackbar']),
        async updatePassword() {
            if(this.email === '') {
                this.setShowSnackbar({
                    show: true,
                    message: 'You haven\'t inserted email yet',
                    type: 'error',
                });

                return;
            } else if(this.currentPassword === '') {
                this.setShowSnackbar({
                    show: true,
                    message: 'You haven\'t inserted current password yet',
                    type: 'error',
                });

                return;
            } else if(this.newPassword === '') {
                this.setShowSnackbar({
                    show: true,
                    message: 'You haven\'t inserted new password yet',
                    type: 'error',
                });

                return;
            } else if(this.newPassword !== this.confirmNewPassword) {
                this.setShowSnackbar({
                    show: true,
                    message: 'New password and confirm password isn\'t the same',
                    type: 'error',
                });

                return;
            }

            const updateObject = {
                email: this.email,
                password: this.currentPassword,
                newPassword: this.newPassword,
            };

            const result = await this.changePassword(updateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Password Successfully Changed',
                    type: 'success',
                });

                this.dialog = false;
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: 'Failed to Change Password',
                    type: 'error',
                });
            }
        },
    },
}
</script>

<style scoped>

</style>
