export const userLoggedInCheck = (redirect: boolean = true): boolean => {
    if(localStorage.getItem('user') === null) {
        if(redirect) {
            window.location.href = '/backoffice/login';
        }

        return false;
    }

    return true;
};

export const getLanguageHeadlines = () => {
    return [
        {
            code: 'sv',
            text: 'Swedish',
            image: 'swedish-flag-icon.svg',
        },
        {
            code: 'en',
            text: 'English',
            image: 'english-flag-icon.svg',
        },
        {
            code: 'de',
            text: 'German',
            image: 'german-flag-icon.jpg',
        },
        {
            code: 'no',
            text: 'Norwegian',
            image: 'norwegian-flag-icon.png',
        },
        {
            code: 'da',
            text: 'Danish',
            image: 'danish-flag-icon.png',
        },
        {
            code: 'fi',
            text: 'Finnish',
            image: 'finnish-flag-icon.jpg',
        },
    ];
};

export const getSelectedLanguages = () => {
    return [
        {
            code: 'en',
            text: 'English',
            selected: false,
        },
        {
            code: 'sv',
            text: 'Swedish',
            selected: false,
        },
        {
            code: 'de',
            text: 'German',
            selected: false,
        },
        {
            code: 'no',
            text: 'Norwegian',
            selected: false,
        },
        {
            code: 'da',
            text: 'Danish',
            selected: false,
        },
        {
            code: 'fi',
            text: 'Finnish',
            selected: false,
        },
    ];
};

export const getLanguagesForLanguageSelector = () => {
    return [
        {
            title: 'Svenska',
            language: 'sv',
            icon: '/images/swedish-flag-icon.svg'
        },
        {
            title: 'English',
            language: 'en',
            icon: '/images/english-flag-icon.svg'
        },
        {
            title: 'German',
            language: 'de',
            icon: '/images/german-flag-icon.jpg'
        },
        {
            title: 'Norwegian',
            language: 'no',
            icon: '/images/norwegian-flag-icon.png'
        },
        {
            title: 'Danish',
            language: 'da',
            icon: '/images/danish-flag-icon.png'
        },
        {
            title: 'Finnish',
            language: 'fi',
            icon: '/images/finnish-flag-icon.jpg'
        },
    ];
};
