<template lang="pug">
    div(class="ir-overview-main-mobile" style="border:0px solid;")
        div(class="container-element" style="border:0px solid;")
            v-container(fluid class="row-block" style="border:0px solid;")
                v-row(style="border:0px solid;")
                    v-col(cols="12" class="pa-0 mb-1")
                        div(id="headline-overview" class="mb-2" style="border:0px solid;")
                            h2 {{ getCompany.pitch_title }}
                        div(style="border:0px solid;")
                            div(class="mb-2" style="border:0px solid;") {{ getCompany.pitch_detail }}
                    template(v-if="getCompany.video_url_2 !== ''")
                        v-col(cols="4" class="pl-0 pt-0 pb-0 pr-1" style="height:calc(26.5vw); border:0px solid green;")
                            iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                            img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                        v-col(cols="4" class="pl-1 pt-0 pb-0 pr-0" style="height:calc(26.5vw); border:0px solid green;")
                            iframe(v-if="getCompany.video_url_2.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_2}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                            img(v-else :src="getCompany.video_url_2" style="height:100%; width:100%;")
                    template(v-else)
                        v-col(cols="8" class="pa-0" style="height:calc(26.5vw); border:0px solid green;")
                            iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                            img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                    v-col(cols="4" class="pl-1 pt-0 pb-0 pr-0")
                        img(@click="openGenericPage(getCompany.page_id, 'headline-generic-component')" :src="getCompany.image" style="width:100%; border:0px solid green;")

            v-container(fluid class="row-block mt-3" style="border:0px solid;")
                v-row(class="pa-2" style="border-radius:10px; box-shadow: 1px 3px 4px 0px rgba(0, 0, 0, 0.3); border:0px solid;")
                    v-col(class="pa-0" cols="12")
                        div(class="text-right mb-2" style="border:0px solid;")
                            v-btn(@click="openEdgerFinance()" small elevation="0" class="pl-1 pr-2")
                                v-icon(size="15") mdi-eye-outline
                                span(class="ml-1") {{ $t('edger_module_add_to_watchlist_on_edger_finance') }}
                    v-col(class="pa-0" cols="12" sm="7" md="8" lg="8" xl="8" style="background-color:#FFFFFF; border:0px solid;")
                        div(id="mobile-chart-element" style="overflow:hidden; width:100%; height:100%; border:0px solid;")
                            ChartPrice(
                                v-if="showMe"
                                :legendView="false"
                                :single="true"
                                :leftToolbar="false"
                                :showrightSideBarLine="false"
                                :width="width - 3"
                                :height="height - 30"
                                page="main"
                                style="border:0px solid red;"
                                ref="chartPrice2"
                            )

                    v-col(class="pa-0" cols="12" sm="5" md="4" lg="4" xl="4" style="border:0px solid red;")
                        div(class="right-menu" style="position:relative; height:100%; border:0px solid green;")
                            div(style="border:0px solid;")
                                v-container(fluid)
                                    v-row(style="border:0px solid green;")
                                        v-col(v-for="kpi in kpis" :key="kpi.row_to_json.kpi_id" cols="6" class="sparkline pa-2" style="border:0px solid;")
                                            div(class="ellipsis pl-2" style="border:0px solid;") {{ kpi.row_to_json.text_1 }}
                                            CanvasGeneric(v-if="color !== null" :values="kpi.row_to_json.values" :color="color" :label="kpi.row_to_json.text_1" style="width:100%; max-height:120px; border:0px solid;")
                                            div(class="mt-2 pl-2" style="color:#000000; overflow-y:scroll; overflow-x:hide; border:0px solid;")
                                                span(v-html="getFirstSentence(kpi.row_to_json.text_2)")
                                                div(@click="openInformation(kpi.row_to_json.text_1, kpi.row_to_json.text_2)" class="ml-1" style="text-decoration:underline; cursor:pointer;") {{ $t('edger_module_read_more') }}

                                    v-row(style="border:0px solid green;")
                                        div(style="border:0px solid red;")
                                            div(style="float:left; width:calc(100% - 55px); color:#000000; border:0px solid;")
                                                div(class="bottom-right-text ml-2 mr-2 pa-1" style="border:0px solid;") {{ $t('edger_module_custom_kpis') }}
                                            div(@click="openEdgerFinanceIR()" style="float:left; width:55px; color:#000000; border:0px solid;")
                                                div(style="font-size:8px;") Powered by
                                                div
                                                    img(src="@/assets/edger-finance-logo.png" style="width:43px;")

        PopupInformation(:openDialog="dialog" :title="title" :text="text" :closeIcon="true" :closeText="true")
</template>

<script>
import ChartPrice from "@/components/ChartPrice.vue";
import CanvasGeneric from "@/components/CanvasGeneric.vue";
import PopupInformation from "@/components/PopupInformation.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "OverviewMobile",
    components: {
        ChartPrice,
        CanvasGeneric,
        PopupInformation,
    },
    data() {
        return {
            widthOnContainer: 0,
            width: 0,
            height: 0,
            color: null,
            title: '',
            text: '',
            dialog: false,
            showMe: true,
        };
    },
    mounted() {
        this.color = this.getColor();

        window.addEventListener('resize', this.onResize);

        setTimeout(() => {
            this.onResize();
        }, 100);

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapGetters(['getCompany', 'getShowDisplayButton']),
        kpis() {
            return this.getCompany.kpis;
        },
         getShowDisplayButtonString() {
            return JSON.stringify(this.getShowDisplayButton);
        },
    },
    watch: {
        getShowDisplayButtonString() {
            this.showMe = false;

            setTimeout(() => {
                this.showMe = true;
            }, 1);
        },
    },
    methods: {
        ...mapMutations(['setGenericPage']),
        openEdgerFinance() {
            window.open('https://edger.finance', '_blank');
        },
        openEdgerFinanceIR() {
            window.open('https://ir.edger.finance/', '_blank');
        },
        openInformation(title, text) {
            this.dialog = true;

            setTimeout(() => {
                this.dialog = false;
            }, 200);

            console.log(title);
            console.log(text);

            this.title = title;
            this.text = text.replace(/\n/g, "<br />");
        },
        getFirstSentence(text) {
            const sentence = text.split(".")[0];

            return `${sentence}.`;
        },
        getColor() {
            const element = document.getElementsByClassName('ir-overview-main-mobile')[0];
            const style = getComputedStyle(element);

            return style.color;
        },
        onResize() {
            if(document.getElementById('mobile-chart-element') !== null) {
                const containerElement = document.getElementsByClassName('container-element')[0];
                const element = document.getElementById('mobile-chart-element');

                console.log(containerElement);

                element.style.height = '300px';

                this.widthOnContainer = containerElement.offsetWidth;
                this.width = element.offsetWidth;
                this.height = element.offsetHeight;

                this.$refs.chartPrice2.updateChart();
            }
        },
        openGenericPage(page_id, el) {
            const page = this.getCompany.pages.find((p) => Number(p.page_id) === Number(page_id));

            this.setGenericPage(page);

            this.$router.push({ path: '/x' });
            this.$router.push({ path: '/' });

            setTimeout(() => {
                const element = document.getElementById(el);

                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    // inline: 'center'
                });
            }, 50);
        },
    },
};
</script>

<style scoped>
>>> text {
    color: #000000;
}

>>> .trading-vue-data-toolbar-item {
    padding: 3px;
    margin-right: 2px;
}

.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.fade-out-and-fade-in {
    animation: fade-out-fade-in 2s infinite;
}

@keyframes fade-out-fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>
