/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

import Companies from "./modules/Companies";
import StockPrice from "./modules/StockPrice";
import Events from "./modules/Events";
import Users from "./modules/Users";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        Companies,
        StockPrice,
        Events,
        Users,
    },
});
