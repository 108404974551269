<template lang="pug">
    div(class="ir-invest-in-us-main-regular" style="padding-bottom:25px; border:0px solid;")
        div(style="max-width:1000px; border:0px solid;")
            div(class="container-element")
                div(v-for="why in whys" :key="why.why_id" class="row-block mb-2" :style="checkIfOpened(why.why_id) ? `overflow:hidden; height:${heightMethod(why.why_id) > 180 ? heightMethod(why.why_id) + 60 : 218}px; transition:height 0.3s ease-out;` : `overflow:hidden; height:250px; transition:height 0.3s ease-in;`" style="border:0px solid black;")
                    div(:style="checkIfOpened(why.why_id) ? `height:${heightMethod(why.why_id) > 218 ? heightMethod(why.why_id) + 60 : 222.5}px;` : `height:4000px;`" style="position:relative; float:left; width:calc(100% - 250px); border:0px solid;")
                        div(class="ml-2 mr-6 pl-2 pt-3" style="border:0px solid;")
                            template(v-if="checkIfThereIsMoreThanTopParagraphs(why.detail)")
                                h2(@click="setElementHeight(why.why_id)" class="mb-6 pr-6" style="cursor:pointer;") {{ why.title }}
                            template(v-else)
                                h2(class="mb-6 pr-6") {{ why.title }}
                            div(:style="checkIfOpened(why.why_id) ? `height:${heightMethod(why.why_id) > 218 ? heightMethod(why.why_id) + 60 : 222.5}px;` : `height:222.5px;`" style="border:0px solid;")
                                div(v-if="checkIfOpened(why.why_id)" v-html="why.detail")
                                div(v-else v-html="getTopParagraph(why.detail)")
                                div(:id="`whyElement${why.why_id}`" v-html="why.detail" class="pb-1" style="visibility:hidden;")
                        template(v-if="checkIfThereIsMoreThanTopParagraphs(why.detail)")
                            v-icon(@click="setElementHeight(why.why_id)" v-if="checkIfOpened(why.why_id)" style="position:absolute; top:10px; right:10px;") mdi-chevron-up
                            v-icon(@click="setElementHeight(why.why_id)" v-else style="position:absolute; top:10px; right:10px;") mdi-chevron-down
                    div(style="float:left; width:250px; height:250px; overflow:hidden; border:0px solid;")
                        img(:src="why.img" style="height:250px;")
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "InvestInUs",
    data() {
        return {
            openedWhys: [],
            height: 180,
        };
    },
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.whys)));
    },
    computed: {
        ...mapGetters(['getCompany']),
        whys() {
            return this.getCompany.whys;
        },
    },
    methods: {
        heightMethod(id) {
            if(this.openedWhys.find((w) => w.id === id) !== undefined) {
                return this.openedWhys.find((w) => w.id === id).height;
            }

            return 218;
        },
        checkIfOpened(id) {
            return this.openedWhys.find((w) => w.id === id) !== undefined;
        },
        setElementHeight(id) {
            const element = document.getElementById(`whyElement${id}`);

            console.log(`whyElement${id}`, element.offsetHeight);

            this.height = element.offsetHeight;

            if(this.openedWhys.find((w) => w.id === id) === undefined) {
                const tempObject = {
                    id: id,
                    height: this.height
                };

                this.openedWhys.push(tempObject);
            } else {
                this.openedWhys = this.openedWhys.filter((w) => w.id !== id);
            }
        },
        getTopParagraph(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let topParagraphString = '';

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    topParagraphString += `<${el.localName} class="${el.className}">${el.innerHTML}</${el.localName}>\n\n`;
                }
            }

            return topParagraphString;
        },
        checkIfThereIsMoreThanTopParagraphs(text) {
            const parser = new DOMParser();
            const parsedHtml = parser.parseFromString(text, 'text/html'); //mimeType of html is text/html
            const listEls = parsedHtml.querySelectorAll('*');

            let numberOfParagraphsTotally = 0;
            let numberOfParagraphsWithClass = 0;

            for(const el of listEls) {
                if(el.className === 'top-paragraph') {
                    numberOfParagraphsWithClass += 1;
                }

                if(el.localName === 'p') {
                    numberOfParagraphsTotally += 1;
                }
            }

            return (numberOfParagraphsWithClass !== numberOfParagraphsTotally);
        },
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.second-step {
    display: none;
}
</style>
