<template lang="pug">
    div(class="ir-earnings-and-reports-main-regular")
        div(style="max-width:1000px; border:0px solid;")
            div(class="container-element" style="border:0px solid;")
                div(v-for="(year, index) in years" :key="year" class="mb-1")
                    v-expansion-panels(:value="(index < 2) ? 0 : 1" flat tile)
                        v-expansion-panel
                            v-expansion-panel-header(style="background-color: #edebeb;")
                                div(class="header-block")
                                    div(class="col-block ellipsis pl-2" style="float:left; width:32%; border:0px solid;") {{ year }}
                                    div(class="col-block ellipsis" style="float:left; width:17%; font-weight:bold; padding-top:7px; border:0px solid;") Report Date
                                    div(class="col-block ellipsis" style="float:left; width:17%; font-weight:bold; padding-top:7px; border:0px solid;") Report
                                    div(class="col-block ellipsis" style="float:left; width:17%; font-weight:bold; padding-top:7px; border:0px solid;") Slides
                                    div(class="col-block ellipsis" style="float:left; width:17%; font-weight:bold; padding-top:7px; border:0px solid;") Video
                                    //- div(class="col-block ellipsis" style="float:left; width:25%; font-weight:bold; padding-top:7px; border:0px solid;") PDF Source
                            v-expansion-panel-content
                                div(v-for="item in getReportsOnYear(year)" :key="item.period" class="row-block")
                                    div(class="col-block ellipsis pl-2 pr-2 mt-1 mb-1" style="float:left; width:31.2%; height:20px; border:0px solid;") {{ getCompany.name }} {{ item.period }}
                                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; width:16.5%; height:20px; border:0px solid;") {{ item.date_report }}
                                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; width:16.7%; height:20px; border:0px solid;")
                                        a(v-if="item.pdf_link_local !== undefined && item.pdf_link_local !== '' && item.pdf_link_local !== null" :href="`${hostAddress}/${getCompany.short_name}/${item.pdf_link_local}`" target="_blank")
                                            img(src="@/assets/pdf-icon.png" style="height:16px;")
                                        //- div(v-if="item.pdf_link_local !== undefined && item.pdf_link_local !== '' && item.pdf_link_local !== null")
                                            img(@click="watchOrDownloadFile(item.pdf_link_local)" src="@/assets/pdf-icon.png" style="height:16px; cursor:pointer;")
                                        a(v-else :href="`https://documents.millistream.com/${item.pdf_link}`" target="_blank")
                                            img(src="@/assets/pdf-icon.png" style="height:16px;")
                                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; width:16.5%; height:20px; border:0px solid;")
                                        a(v-if="item.slides_link_local !== undefined && item.slides_link_local !== '' && item.slides_link_local !== null" :href="`${hostAddress}/${getCompany.short_name}/${item.slides_link_local}`" target="_blank")
                                            img(src="@/assets/slides-icon.png" style="height:16px;")
                                        //- div(v-if="item.slides_link_local !== undefined && item.slides_link_local !== '' && item.slides_link_local !== null")
                                            img(@click="watchOrDownloadFile(item.slides_link_local)" src="@/assets/slides-icon.png" style="height:16px; cursor:pointer;")
                                    div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; width:16.6%; height:20px; border:0px solid;")
                                        a(v-if="item.video_link !== undefined && item.video_link !== '' && item.video_link !== null" :href="item.video_link" target="_blank")
                                            img(src="@/assets/media-play-icon.svg" style="height:16px;")
                                    //- div(class="col-block ellipsis mt-1 mb-1" style="float:left; width:10%; width:26.8%; height:20px; border:0px solid;")
                                        span(v-if="item.pdf_link_local !== undefined") PDF uploaded
                                        span(v-else) PDF from Millistream
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
    name: "EarningsAndReports",
    data() {
        return {
            counter: 0,
        };
    },
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.reports)));
        console.log(this.years);
        console.log(JSON.parse(JSON.stringify(this.getReportsOnYear('2022'))));
    },
    computed: {
        ...mapGetters(['getCompany', 'getCompanyName']),
        reports() {
            return this.getCompany.reports;
        },
        years() {
            let yearsTemp = [];

            for(const item of this.reports) {
                yearsTemp.push(this.getYear(item.period));
            }

            yearsTemp = _.uniq(yearsTemp);
            yearsTemp = yearsTemp.sort().reverse();

            return yearsTemp;
        },
        hostAddress() {
            return `${window.location.protocol}//${window.location.host}`;
        },
    },
    methods: {
        ...mapActions(['openFile']),
        downloadFile(link, file_name) {
            const element = document.createElement('a');

            element.setAttribute('href', link);

            if(file_name.split('.')[1] !== 'pdf') {
                element.setAttribute('download', file_name);
            }

            element.setAttribute('target', '_blank');

            element.style.display = 'none';

            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        },
        async watchOrDownloadFile(file_name) {
            const openFileObject = {
                short_name: this.getCompanyName,
                file_name: file_name,
            };

            const base64string = await this.openFile(openFileObject);
            const base64 = (base64string);

            let blob;

            if(file_name.split('.')[1] === 'pdf') {
                blob = this.base64ToBlob(base64, 'application/pdf');
            } else {
                blob = this.base64ToBlob(base64);
            }
            
            const url = URL.createObjectURL(blob);

            this.downloadFile(url, file_name);
        },
        base64ToBlob( base64, type = 'application/octet-stream' ) {
            const binStr = atob( base64 );
            const len = binStr.length;
            const arr = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }

            return new Blob([arr], {type: type});
        },
        getYear(stringValue) {
            return Number(stringValue.substring(0, 4));
        },
        getReportsOnYear(year) {
            const yearTemp = Number(year);
            let reportsTemp = [];

            for(const report of this.reports) {
                if(this.getYear(report.period) === yearTemp) {
                    reportsTemp.push(report);
                }
            }

            reportsTemp = _.orderBy(reportsTemp, ['date_report', 'period'], ['desc', 'asc']);

            return reportsTemp;
        }
    },
};
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.v-expansion-panels .v-expansion-panel-header {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    min-height: auto;
}

.v-expansion-panels .v-expansion-panel--active .v-expansion-panel-header {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

>>> .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

.ir-earnings-and-reports-main-regular .header-block .col-block:nth-child(2) {
    opacity: 0;
}

.ir-earnings-and-reports-main-regular .header-block .col-block:nth-child(3) {
    opacity: 0;
}

.ir-earnings-and-reports-main-regular .header-block .col-block:nth-child(4) {
    opacity: 0;
}

.ir-earnings-and-reports-main-regular .header-block .col-block:nth-child(5) {
    opacity: 0;
}

.ir-earnings-and-reports-main-regular .header-block .col-block:nth-child(6) {
    opacity: 0;
}

.ir-earnings-and-reports-main-regular .v-expansion-panel-header--active .header-block .col-block:nth-child(2) {
    opacity: 1;
}

.ir-earnings-and-reports-main-regular .v-expansion-panel-header--active .header-block .col-block:nth-child(3) {
    opacity: 1;
}

.ir-earnings-and-reports-main-regular .v-expansion-panel-header--active .header-block .col-block:nth-child(4) {
    opacity: 1;
}

.ir-earnings-and-reports-main-regular .v-expansion-panel-header--active .header-block .col-block:nth-child(5) {
    opacity: 1;
}

.ir-earnings-and-reports-main-regular .v-expansion-panel-header--active .header-block .col-block:nth-child(6) {
    opacity: 1;
}
</style>
