<template lang="pug">
    div(v-if="getLoggedIn" style="width:766px; border:0px solid red;")
        div(style="border:0px solid;")
            //- head
            div(style="font-family:'Courier New'; font-size:12px; color:#474747; height:20px; border:0px solid;")
                div(style="float:left; font-weight:bold; height:20px; width:150px; border:0px solid;") Date Time
                div(style="float:left; font-weight:bold; height:20px; width:330px; border:0px solid;") Title
                div(style="float:left; font-weight:bold; height:20px; width:140px; border:0px solid;") Language
                div(style="float:left; font-weight:bold; height:20px; width:140px; border:0px solid;") Type
                //- div(style="float:left; font-weight:bold; height:20px; width:120px; border:0px solid;") Reviewed

            //- body
            div(v-for="page in news" style="font-family:Courier; font-size:12px; color:#474747; height:20px; border:0px solid;")
                div(class="ellipsis" style="float:left; height:20px; width:150px; border:0px solid;") {{ page.date }} {{ page.time.substring(0, 5) }}
                div(class="ellipsis" style="float:left; height:20px; width:330px; border:0px solid;") {{ page.title }}
                div(class="ellipsis" style="float:left; height:20px; width:140px; border:0px solid;") {{ getLanguageName(page.language) }}
                div(class="ellipsis" style="float:left; height:20px; width:140px; border:0px solid;") {{ page.type }}
                //- div(class="ellipsis" style="float:left; height:20px; width:120px; border:0px solid;") VD
</template>

<script>
import { getSelectedLanguages } from "./../../helpers/util";
import { mapGetters } from "vuex";

export default {
    name: "PressReleases",
    data() {
        return {
            languages: getSelectedLanguages(),
        };
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }
    },
    computed: {
        ...mapGetters(['getCompany', 'getLoggedIn']),
        news() {
            return this.getCompany.news.news;
        },
    },
    methods: {
        getLanguageName(code) {
            return this.languages.find((l) => l.code === code).text;
        },
    },
}
</script>

<style scoped>
.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
