import ApiService from "@/services/ApiService";

interface IUser {
    email: string
    password: string
}

export default {
    state: {
        user: {},
        loggedIn: false,
    },
    getters: {
        getUser(state: any) {
            return state.user;
        },
        getLoggedIn(state: any) {
            return state.loggedIn;
        },
    },
    mutations: {
        setUser(state: any, payload: any) {
            state.user = payload;
        },
        setLoggedIn(state: any, payload: any) {
            state.loggedIn = payload;
        },
    },
    actions: {
        async loadLoginUser({ commit }: any, user: IUser) {
            const res: any = (await ApiService.loginUser(user)).data;

            commit('setUser', res);
        },
        async changePassword({ commit }, data: any) {
            const result: any = (await ApiService.changePassword(data)).data;

            return result;
        },
    }
};
