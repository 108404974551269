<template lang="pug">
    div(v-if="getLoggedIn" style="position:relative; border:0px solid red;")
        div(style="width:calc(100vw - 245px); overflow:scroll; border:0px solid red;")
            div(style="width:975px; color:#474747; border:0px solid;")
                div(class="mb-6" style="border:0px solid;")
                    v-btn(@click="saveNewCalendar()" height="25" elevation="0" style="font-family:Poppins; font-size:15px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;")
                        v-icon mdi-cog-outline
                        span(class="ml-3") Create new Calendar Event

                //- head block
                div(style="font-family:'Courier New'; font-weight:bold; font-size:12px; height:20px; border:0px solid;")
                    div(style="float:left; height:20px; width:400px; border:0px solid;") Event Title
                    div(style="float:left; height:20px; width:170px; border:0px solid;") Financial Period
                    div(style="float:left; height:20px; width:170px; border:0px solid;") Type
                    div(style="float:left; height:20px; width:170px; border:0px solid;") Date Time
                    div(style="float:left; height:20px; width:60px; border:0px solid;")

                //- main block
                div(v-for="cal in calendar" style="font-family:Courier; font-size:12px; height:20px; border:0px solid;")
                    template(v-if="cal.date !== undefined")
                        div
                            div(style="float:left; height:20px; width:400px; border:0px solid;") {{ cal.name }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ cal.period }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ cal.type }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ cal.date }}
                            div(style="float:left; position:relative; height:20px; width:60px; border:0px solid;")
                                img(@click="openEditCalendarPopup(cal)" src="@/assets/edit-kpi-icon.svg" style="position:absolute; height:16px; cursor:pointer;")
                    template(v-else)
                        div(style="color:#bbbbbb;")
                            div(style="float:left; height:20px; width:400px; border:0px solid;") {{ getTypeText(cal) }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ cal.period }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ getType(cal.type_report) }}
                            div(style="float:left; height:20px; width:170px; border:0px solid;") {{ cal.date_report }}
                            div(style="float:left; height:20px; width:60px; border:0px solid;")

        //- popup window
        div(v-if="activeCalendarId !== 0" id="calendarPopupElement" class="pl-2 pr-2 pt-1 pb-2" style="position:absolute; top:70px; font-family:Courier; font-size:12px; background-color:#ebebeb; z-index:99999; box-shadow:4px 4px 8px 1px rgba(0, 0, 0, 0.15); border:0px solid lightgrey;")
            div(style="display:table;")
                div(style="display:table-cell; border:0px solid;")
                    div(style="display:table;")
                        div(style="display:table-cell; width:180px; border:0px solid;") Title
                        div(style="display:table-cell; width:150px; border:0px solid;") Description
                    div(style="display:table;")
                        div(style="display:table-cell;")
                            template(v-for="(langcalendar, index) in getCompany.languages_available")
                                template(v-for="calendar in calendarsOnLang(langcalendar.language)")
                                    div(style="display:table;")
                                        div(style="display:table-cell; position:relative; width:175px; border:0px solid;")
                                            div(style="position:absolute; top:0; border:0px solid;")
                                                span(class="mr-1") {{ calendar.language }}
                                                input(type="text" v-model="calendar.name" class="pl-1 pr-1" style="width:150px; background-color:#FFFFFF; border:1px solid lightgrey;")
                                        div(style="display:table-cell; width:150px; border:0px solid;")
                                            textarea(rows="3" v-model="calendar.description" style="width:150px; background-color:#FFFFFF; border:1px solid lightgrey;")
                                            div(:class="getCompany.languages_available.length > (index + 1) ? 'mb-4' : ''" class="text-right" style="display:table; width:100%; border:0px solid;")
                                                div(style="display:table-cell; border:0px solid;")
                                                v-btn(@click="updateCalendarOnLanguage(calendar)" width="80" height="20" :disabled="updateCompanyCheck(calendar)" :style="updateCompanyCheck(calendar) ? 'border:1px solid #7C7C7C;' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                div(class="pl-2 pr-1" style="display:table-cell; border:0px solid;")
                    div(style="display:table-cell; border:0px solid;")
                        div Date
                        div
                            input(type="text" v-model="date" placeholder="yyyy-mm-dd" class="pl-1 pr-1" style="font-family:'IBM Plex Mono'; font-size:10px; background-color:#FFFFFF; height:20px; width:100px; border:1px solid lightgrey;")
                div(class="pr-1" style="display:table-cell; border:0px solid;")
                    div(style="display:table-cell; border:0px solid;")
                        div Time
                        div
                            input(type="text" v-model="time" placeholder="--:--" :disabled="date === ''" class="pl-1 pr-1" style="font-family:'IBM Plex Mono'; font-size:10px; background-color:#FFFFFF; height:20px; width:79px; border:1px solid lightgrey;")
                div(class="pr-1" style="display:table-cell; border:0px solid;")
                    div(style="display:table-cell; border:0px solid;") End Time
                    div
                        input(type="text" v-model="timeEnd" placeholder="--:--" :disabled="date === '' || time === ''" class="pl-1 pr-1" style="font-family:'IBM Plex Mono'; font-size:10px; background-color:#FFFFFF; height:20px; width:79px; border:1px solid lightgrey;")
                    div(class="text-right mt-1" style="border:0px solid;")
                        v-btn(@click="updateDateAndTime()" width="80" height="20" :disabled="updateDateCheck()" :style="updateDateCheck() ? 'border:1px solid #7C7C7C;' : ''" style="font-family:Poppins; font-size:12px; background-color:#28407E; color:#FFFFFF; text-transform:none; border:1px solid #FB993F;") Save
                div(style="display:table-cell; position:relative; border:0px solid;")
                    div(class="text-center" style="border:0px solid;")
                        div(@click="cancel()" style="font-family:Poppins; font-size:12px; width:80px; color:#CF8631; cursor:pointer; border:0px solid;") Cancel
                    div(style="position:absolute; bottom:0; border:0px solid;")
                        v-btn(@click="removeCalendar()" width="80" height="20" style="font-family:Poppins; font-size:12px; text-transform:none; background-color:#EBEBEB; color:#27407E; border:1px solid #27407E;") Delete
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
    name: "Calendar",
    data() {
        return {
            calendar: [],
            calendarCopy: [],
            companies: [],
            companiesCopy: [],
            activeCalendarId: 0,
            date: '',
            dateCopy: '',
            time: '',
            timeCopy: '',
            timeEnd: '',
            timeEndCopy: '',
            type: [
                {
                    type: 3,
                    text: 'Full Year Report',
                    typeText: 'Full Year',
                },
                {
                    type: 4,
                    text: 'Interim Report',
                    typeText: 'Interim',
                }
            ],
        };
    },
    created() {
        this.companies = JSON.parse(JSON.stringify(this.getCompanies));
        this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

        this.calendar = JSON.parse(JSON.stringify(this.getCompany.calendar));
        this.calendarCopy = JSON.parse(JSON.stringify(this.getCompany.calendar));
        
        console.log(JSON.parse(JSON.stringify(this.getCompany)));
        console.log(JSON.parse(JSON.stringify(this.getCompany.languages_available)));

        this.calendar = this.calendar.map((c) => {
            c.d = (c.date !== undefined) ? c.date : c.date_report;

            return c;
        });

        this.calendar = _.orderBy(this.calendar, ['d'], ['desc']);

        console.log(JSON.parse(JSON.stringify(this.calendar)));
    },
    mounted() {
        if(!this.getLoggedIn) {
            window.location.href = '/backoffice/login';
        }
    },
    computed: {
        ...mapGetters(['getLoggedIn', 'getCompany', 'getCompanies']),
    },
    methods: {
        ...mapActions(['updateCalendarTexts', 'updateCalendarDateTime', 'insertNewCalendar', 'deleteCalendar']),
        ...mapMutations(['setShowSnackbar']),
        async updateDateAndTime() {
            console.log('updateDateAndTime');

            const updateObject = {
                event_id: this.activeCalendarId,
                company_id: this.getCompany.company_id,
                date: this.date,
                time: this.time,
                time_end: this.timeEnd,
            };

            console.log(updateObject);

            const result = await this.updateCalendarDateTime(updateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated calendar date and time',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.dateCopy = this.date;
            this.timeCopy = this.time;
            this.timeEndCopy = this.timeEnd;

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.calendar = JSON.parse(JSON.stringify(this.getCompany.calendar));
            this.calendarCopy = JSON.parse(JSON.stringify(this.getCompany.calendar));

            this.calendar = this.calendar.map((c) => {
                c.d = (c.date !== undefined) ? c.date : c.date_report;

                return c;
            });

            this.calendar = _.orderBy(this.calendar, ['d'], ['desc']);
        },
        async updateCalendarOnLanguage(calendar) {
            const updateObject = {
                event_id: calendar.event_id,
                company_id: calendar.company_id,
                language: calendar.language,
                title: calendar.name,
                detail: calendar.description,
            };

            // console.log(updateObject);

            const result = await this.updateCalendarTexts(updateObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully updated calendar event',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.calendar = JSON.parse(JSON.stringify(this.getCompany.calendar));
            this.calendarCopy = JSON.parse(JSON.stringify(this.getCompany.calendar));

            this.calendar = this.calendar.map((c) => {
                c.d = (c.date !== undefined) ? c.date : c.date_report;

                return c;
            });

            this.calendar = _.orderBy(this.calendar, ['d'], ['desc']);
        },
        openEditCalendarPopup(calendar) {
            console.log(JSON.parse(JSON.stringify(calendar)));

            this.activeCalendarId = calendar.event_id;

            this.date = calendar.date;
            this.dateCopy = calendar.date;
            this.time = calendar.time;
            this.timeCopy = calendar.time;
            this.timeEnd = calendar.time_end;
            this.timeEndCopy = calendar.time_end;

            console.log(this.activeCalendarId);

            setTimeout(() => {
                window.addEventListener('click', this.clickedOutsideCheck);
            }, 100);
        },
        clickedOutsideCheck(e) {
            const element = document.getElementById('calendarPopupElement');

            if(!element.contains(e.target)) {
                console.log('clicked outside');

                this.activeCalendarId = 0;

                window.removeEventListener('click', this.clickedOutsideCheck);
            }
        },
        async saveNewCalendar() {
            const saveNewCalendarObject = {
                company_id: this.getCompany.company_id,
                date: moment().format('YYYY-MM-DD'),
                time: moment().format('YYYY-MM-DD HH:mm').split(' ')[1],
                time_end: null,
                period: null,
                type: null,
            };

            // console.log(saveNewCalendarObject);

            const result = await this.insertNewCalendar(saveNewCalendarObject);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully inserted new calendar event',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.calendar = JSON.parse(JSON.stringify(this.getCompany.calendar));
            this.calendarCopy = JSON.parse(JSON.stringify(this.getCompany.calendar));

            this.calendar = this.calendar.map((c) => {
                c.d = (c.date !== undefined) ? c.date : c.date_report;

                return c;
            });

            this.calendar = _.orderBy(this.calendar, ['d'], ['desc']);
        },
        getTypeText(report) {
            return `${this.type.find((t) => t.type === report.type_report).text} ${report.period}`;
        },
        getType(type) {
            return this.type.find((t) => t.type === type).typeText;
        },
        calendarsOnLang(language) {
            return this.companies.find((c) => c.language === language).calendar.filter((cc) => cc.event_id === this.activeCalendarId);
        },
        cancel() {
            window.removeEventListener('click', this.clickedOutsideCheck);

            this.activeCalendarId = 0;
        },
        async removeCalendar() {
            console.log(this.activeCalendarId);

            const result = await this.deleteCalendar(this.activeCalendarId);

            console.log(result);

            if(result.status === 0) {
                this.setShowSnackbar({
                    show: true,
                    message: 'Successfully deleted calendar event',
                    type: 'success',
                });
            } else {
                this.setShowSnackbar({
                    show: true,
                    message: result.message,
                    type: 'error',
                });
            }

            this.companies = JSON.parse(JSON.stringify(this.getCompanies));
            this.companiesCopy = JSON.parse(JSON.stringify(this.getCompanies));

            this.calendar = JSON.parse(JSON.stringify(this.getCompany.calendar));
            this.calendarCopy = JSON.parse(JSON.stringify(this.getCompany.calendar));

            this.calendar = this.calendar.map((c) => {
                c.d = (c.date !== undefined) ? c.date : c.date_report;

                return c;
            });

            this.calendar = _.orderBy(this.calendar, ['d'], ['desc']);

            this.activeCalendarId = 0;

            window.removeEventListener('click', this.clickedOutsideCheck);
        },
        updateCompanyCheck(cal) {
            const company = this.companies.find((c) => c.language === cal.language);
            const companyCopy = this.companiesCopy.find((c) => c.language === cal.language);

            const calendars = company.calendar;
            const calendarsCopy = companyCopy.calendar;

            const calendar = calendars.find((c) => c.event_id === cal.event_id);
            const calendarCopy = calendarsCopy.find((c) => c.event_id === cal.event_id);

            return (calendar.name === calendarCopy.name && calendar.description === calendarCopy.description);
        },
        updateDateCheck() {
            const editedString = `${this.date}T${this.time}-${this.timeEnd}`;
            const originalString = `${this.dateCopy}T${this.timeCopy}-${this.timeEndCopy}`;

            return (editedString === originalString || this.date === '');
        },
    },
}
</script>

<style scoped>
textarea {
	resize: none;
}
</style>
