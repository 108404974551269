import ApiService from "@/services/ApiService";

interface ICompany {
    short_name?: string;
    language: string;
}

interface ICompanyNews {
    company_id: number;
    page: number;
}

interface ICompanyCanonicalCName {
    url: string;
}

interface ICeoComment {
    company_id: number;
    stock_id: number;
    date: string;
}

interface IUpdateCeoComment {
    entity_id: number;
    language: string;
    text_1: string;
    text_2: string;
}

export default {
    state: {
        company: {},
        companyDetails: {},
        companyName: '',
        genericPageValues: {},
        cashedNews: [],
        companyCanonicalCName: '',
        prices: [],
        companies: [],
        companyColor: '#000000',
        isListed: true,
    },
    getters: {
        getCompany(state: any) {
            return state.company;
        },
        getCompanyDetails(state: any) {
            return state.companyDetails;
        },
        getCompanyName(state: any) {
            return state.companyName;
        },
        getGenericPage(state: any) {
            return state.genericPageValues;
        },
        getCompanyPrices(state: any) {
            return state.prices;
        },
        getCashedNews(state: any) {
            return state.cashedNews;
        },
        getCompanyCanonicalCName(state: any) {
            return state.companyCanonicalCName;
        },
        getCompanies(state: any) {
            return state.companies;
        },
        getCompanyColor(state: any) {
            return state.companyColor;
        },
        getIsListed(state: any) {
            return state.isListed;
        },
    },
    mutations: {
        setCompany(state: any, payload: any) {
            state.company = payload;
        },
        setCompanyDetails(state: any, payload: any) {
            state.companyDetails = payload;
        },
        setCompanyName(state: any, payload: any) {
            state.companyName = payload;
        },
        setGenericPage(state: any, payload: any) {
            state.genericPageValues = payload;
        },
        setCompanyPrices(state: any, payload: any) {
            state.prices = payload;
        },
        setCashedNews(state: any, payload: any) {
            state.cashedNews = payload;
        },
        setCompanyCanonicalCName(state: any, payload: any) {
            state.companyCanonicalCName = payload;
        },
        setCompanies(state: any, payload: any) {
            state.companies = payload;
        },
        setCompanyColor(state: any, payload: any) {
            state.companyColor = payload;
        },
        setIsListed(state: any, payload: any) {
            state.isListed = payload;
        },
    },
    actions: {
        async loadCompanies({ commit }: any, short_name: string) {
            const companyObject: any = await ApiService.getCompany(short_name, 'sv');

            const company: any = companyObject.data;
            const languages: any = company.languages_available.map((l: any) => l.language);
            const company_id: number = company.company_id;

            // console.log(company_id);
            // console.log(languages);

            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            // console.log(companies);

            commit('setCompanies', companies);
        },
        async loadCompany({ commit }: any, payload: ICompany) {
            const short_name: string = payload.short_name as string;
            const language: string = payload.language;

            const companyObject: any = await ApiService.getCompany(short_name, language);
            const company: object = companyObject.data;

            commit('setCompany', company);
        },
        async loadCompanyDetails({ commit, rootState }) {
            // const short_name: string = payload.short_name;
            const language: string = rootState.Companies.company.language;
            // const company_id: number = companyTemp.company_id;
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, language);
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            // console.log(JSON.parse(JSON.stringify(rootState.Companies.company)));
            // console.log(JSON.parse(JSON.stringify(companyDetails)));

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            // console.log(JSON.parse(JSON.stringify(news)));

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
        },
        async loadCompanyPrices({ commit, rootState }: any, company_id: number) {
            let prices: any = await ApiService.getCompanyPrices(company_id);
            
            prices = prices.data;

            rootState.Companies.company.prices = prices;

            commit('setCompanyPrices', prices);
        },
        async loadCashedNews({ commit, rootState }: any, payload: ICompanyNews) {
            const company_id: number = payload.company_id;
            const page: number = payload.page;

            let cashedNewsTemp: any = rootState.Companies.cashedNews;

            if(cashedNewsTemp.filter((n: any) => n.page === page).length === 0) {
                let companyNews: any = await ApiService.getCompanyNews(company_id, page);

                companyNews = companyNews.data.news;

                companyNews = companyNews.map((n: any) => {
                    n.page = page;

                    return n;
                });

                cashedNewsTemp = cashedNewsTemp.concat(companyNews);

                commit('setCashedNews', cashedNewsTemp);
            }
        },
        async loadCompanyCanonicalCName({ commit }: any, payload: ICompanyCanonicalCName) {
            let canonical: any = '';
            
            canonical = (await ApiService.getCanonicalCName(payload)).data;

            // TODO: return to this after going back to dns instead of hardcoded
            // if(canonical.message === undefined) {
            //     canonical = canonical.cnames[0].split('.')[0];
            // } else {
            //     canonical = window.location.host.split('.')[0];
            // }

            // canonical = canonical.toLowerCase();

            commit('setCompanyCanonicalCName', canonical);
        },
        async setCeoComment({ commit, rootState }, payload: ICeoComment) {
            const dataObject: object = {
                entry: payload
            };

            const dataResult: any = (await ApiService.setCeoComment(dataObject)).data;

            console.log(dataResult);

            const entity_id: number = dataResult.newId;

            console.log(entity_id);

            const languagesAvailable: any = rootState.Companies.company.languages_available;
            const company_id: number = rootState.Companies.company.company_id;

            console.log(JSON.parse(JSON.stringify(languagesAvailable)));

            for(const lang of languagesAvailable) {
                const language: string = lang.language;

                console.log(language);

                const textObject: object = {
                    team_person: {
                        entity_id: entity_id,
                        language: language,
                        type: 'journal',
                        text_1: `New Entry ${language}`,
                        text_2: `New Comment ${language}`,
                        company_id: company_id
                    }
                };

                console.log(textObject);

                await ApiService.setText(textObject);
            }
        },
        async updateTextTitleAndDescription({ commit, rootState }, data: any) {
            const result = (await ApiService.updateTextTitleAndDescription(data)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.ceo_comments.map((ceo: any) => {
                    if(ceo.journal_id === data.journal_id) {
                        ceo.note = data.content;
                    }

                    return ceo;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.ceo_comments.map((ceo: any) => {
                        if(ceo.journal_id === data.journal_id) {
                            ceo.note = data.content;
                        }

                        return ceo;
                    });
                }

                return c;
            });

            return result;
        },
        async insertNewBespokePage({ commit }, data: object) {
            const result = (await ApiService.insertNewBespokePage(data)).data;

            return result;
        },
        async updateBespokePage({ commit }, data: object) {
            const result = (await ApiService.updateBespokePage(data)).data;

            return result;
        },
        async updateCssStyles({ commit }, data: object) {
            const result = (await ApiService.updateCssStyles(data)).data;

            return result;
        },
        async updateLogoImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateLogoImage(data)).data;

            rootState.Companies.company.logo_img = data.logo_image;

            return result;
        },
        async updateBannerImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateBannerImage(data)).data;

            rootState.Companies.company.banner_img = data.image;

            return result;
        },
        async updateTitleImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateTitleImage(data)).data;

            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.image = data.image;
                }

                return c;
            });

            return result;
        },
        async updatePitch({ commit, rootState }, data: any) {
            const result = (await ApiService.updatePitch(data)).data;

            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.pitch_title = data.title;
                    c.pitch_detail = data.detail;

                    if(data.language === 'sv') {
                        rootState.Companies.company.pitch_title = data.title;
                        rootState.Companies.company.pitch_detail = data.detail;
                    }
                }

                return c;
            });

            return result;
        },
        async addLanguage({ commit, rootState }, data: any) {
            // add language **************************
            const result = (await ApiService.addLanguage(data)).data;
            // ***************************************

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
            
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateCompanyInformation({ commit, rootState }, data: any) {
            const result = (await ApiService.updateCompanyInformation(data)).data;

            rootState.Companies.company.name = data.name;
            rootState.Companies.company.video_url_1 = data.video_url_1;
            rootState.Companies.company.video_url_2 = data.video_url_2;
            rootState.Companies.company.post_address = data.post_address;
            rootState.Companies.company.home_url = data.home_url;
            rootState.Companies.company.ir_url = data.ir_url;
            rootState.Companies.company.ir_email = data.ir_email;
            rootState.Companies.company.ir_telephone = data.ir_telephone;
            rootState.Companies.company.org_nr = data.org_nr;
            rootState.Companies.company.insider_trade_isins = data.insider_trade_isins;
            rootState.Companies.company.linkedin = data.linked_in;
            rootState.Companies.company.browser_tab_text = data.browser_tab_text;

            return result;
        },
        async updateWhyImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateWhyImage(data)).data;

            rootState.Companies.company.whys.map((w: any) => {
                if(w.why_id === data.why_id) {
                    w.img = data.image
                }

                return w;
            });

            return result;
        },
        async updateWhyTexts({ commit, rootState }, data: any) {
            const result = (await ApiService.updateWhyTexts(data)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.whys.map((w: any) => {
                    if(w.why_id === data.why_id) {
                        w.title = data.title;
                        w.detail = data.detail;
                    }

                    return w;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.whys.map((w: any) => {
                        if(w.why_id === data.why_id) {
                            w.title = data.title;
                            w.detail = data.detail;
                        }

                        return w;
                    });
                }

                return c;
            });

            return result;
        },
        async updateTeamBio({ commit, rootState }, data: any) {
            const result = (await ApiService.updateTeamBio(data)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.team.map((t: any) => {
                    if(t.team_id === data.team_id) {
                        t.position = data.title;
                        t.bio = data.bio;
                    }

                    return t;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.team.map((t: any) => {
                        if(t.team_id === data.team_id) {
                            t.position = data.title;
                            t.bio = data.bio;
                        }

                        return t;
                    });
                }

                return c;
            });

            return result;
        },
        async updateTeamNameEmail({ commit, rootState }, data: any) {
            const result = (await ApiService.updateTeamNameEmail(data)).data;

            // update whys specific field for company
            rootState.Companies.company.team.map((t: any) => {
                if(t.team_id === data.team_id) {
                    t.name = data.name;
                    t.email = data.email;
                    t.linkedin = data.linkedin;
                    t.is_board_member = data.is_board_member;
                    t.order_by = data.order_by;
                }

                return t;
            });

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.team.map((t: any) => {
                        if(t.team_id === data.team_id) {
                            t.name = data.name;
                            t.email = data.email;
                            t.linkedin = data.linkedin;
                            t.is_board_member = data.is_board_member;
                            t.order_by = data.order_by;
                        }

                        return t;
                    });
                }

                return c;
            });

            return result;
        },
        async deleteTeamMember({ commit, rootState }, team_id: number) {
            const result = (await ApiService.deleteTeamMember(team_id)).data;

            // update whys specific field for company
            rootState.Companies.company.team = rootState.Companies.company.team.filter((t: any) => t.team_id !== team_id);

            // update whys specific field for companies
            rootState.Companies.companies = rootState.Companies.companies.map((c: any) => {
                c.team = c.team.filter((t: any) => t.team_id !== team_id);

                return c;
            });

            return result;
        },
        async updateTeamImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateTeamImage(data)).data;

            rootState.Companies.company.team.map((t: any) => {
                if(t.team_id === data.team_id && t.company_id === data.company_id) {
                    t.photo_img = data.image;
                }

                return t;
            });

            return result;
        },
        async insertNewTeamMember({ commit, rootState }, data: any) {
            // insert new team member ****************
            const result = (await ApiService.insertNewTeamMember(data)).data;
            // ***************************************

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateCalendarTexts({ commit, rootState }, data: any) {
            const result = (await ApiService.updateCalendarTexts(data)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.calendar.map((c: any) => {
                    if(c.event_id === data.event_id) {
                        c.name = data.title;
                        c.description = data.detail;
                    }

                    return c;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.calendar.map((cc: any) => {
                        if(cc.event_id === data.event_id) {
                            cc.name = data.title;
                            cc.description = data.detail;
                        }

                        return cc;
                    });
                }

                return c;
            });

            return result;
        },
        async updateCalendarDateTime({ commit, rootState }, data: any) {
            const result = (await ApiService.updateCalendarDateTime(data)).data;

            // update whys specific field for company
            rootState.Companies.company.calendar.map((c: any) => {
                if(c.event_id === data.event_id) {
                    c.date = data.date;
                    c.time = data.time;
                    c.time_end = data.time_end;
                }

                return c;
            });

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.calendar.map((cc: any) => {
                        if(cc.event_id === data.event_id) {
                            cc.date = data.date;
                            cc.time = data.time;
                            cc.time_end = data.time_end;
                        }

                        return cc;
                    });
                }

                return c;
            });

            return result;
        },
        async insertNewCalendar({ commit, rootState }, data: any) {
            const result = (await ApiService.insertNewCalendar(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async deleteCalendar({ commit, rootState }, event_id: number) {
            const result = (await ApiService.deleteCalendar(event_id)).data;

            // update whys specific field for company
            rootState.Companies.company.calendar = rootState.Companies.company.calendar.filter((c: any) => c.event_id !== event_id);

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                c.calendar = c.calendar.filter((cc: any) => cc.event_id !== event_id);

                return c;
            });

            return result;
        },
        async insertNewKpiItem({ commit, rootState }, data: any) {
            const result = (await ApiService.insertNewKpiItem(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateKpiItem({ commit, rootState }, data: any) {
            const result = (await ApiService.updateKpiItem(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async uploadMediaFile({ commit, rootState }, data: any) {
            const result = (await ApiService.uploadMediaFile(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateMediaFile({ commit, rootState }, data: any) {
            const result = (await ApiService.updateMediaFile(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async addNewMarketComment({ commit, rootState }, data: any) {
            const result = (await ApiService.addNewMarketComment(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateMarketCommentDate({ commit, rootState }, data: any) {
            const dataObject = {
                company_id: data.company_id,
                journal_id: data.journal_id,
                stock_id: data.stock_id,
                date: data.date,
            };

            const result = (await ApiService.updateMarketCommentDate(dataObject)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.ceo_comments.map((ceo: any) => {
                    if(ceo.journal_id === data.journal_id) {
                        ceo.date = data.date;
                    }

                    return ceo;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.ceo_comments.map((ceo: any) => {
                        if(ceo.journal_id === data.journal_id) {
                            ceo.date = data.date;
                        }

                        return ceo;
                    });
                }

                return c;
            });

            return result;
        },
        async deleteMarketComment({ commit, rootState }, journal_id: number) {
            const result = (await ApiService.deleteMarketComment(journal_id)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateKpiTitleAndDetail({ commit, rootState }, data: any) {
            const result = (await ApiService.updateKpiTitleAndDetail(data)).data;

            // update whys specific field for company
            if(data.language === 'sv') {
                rootState.Companies.company.kpis.map((kpi: any) => {
                    if(kpi.row_to_json.kpi_id === data.kpi_id) {
                        kpi.row_to_json.text_1 = data.title;
                        kpi.row_to_json.text_2 = data.detail;
                    }

                    return kpi;
                });
            }

            // update whys specific field for companies
            rootState.Companies.companies.map((c: any) => {
                if(c.language === data.language) {
                    c.kpis.map((kpi: any) => {
                        if(kpi.row_to_json.kpi_id === data.kpi_id) {
                            kpi.row_to_json.text_1 = data.title;
                            kpi.row_to_json.text_2 = data.detail;
                        }

                        return kpi;
                    });
                }

                return c;
            });

            return result;
        },
        async insertNewKpi({ commit, rootState }, data: any) {
            const result = (await ApiService.insertNewKpi(data)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async updateFaviconImage({ commit, rootState }, data: any) {
            const result = (await ApiService.updateFaviconImage(data)).data;

            // update company
            rootState.Companies.company.favicon = data.favicon_image;

            return result;
        },
        async deleteKpiItem({ commit, rootState }, kpi_item_id: number) {
            const result = (await ApiService.deleteKpiItem(kpi_item_id)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async deleteKpi({ commit, rootState }, kpi_id: number) {
            const result = (await ApiService.deleteKpi(kpi_id)).data;

            // set company ***************************
            const short_name: string = rootState.Companies.companyName;

            const companyObject: any = await ApiService.getCompany(short_name, 'sv');
            const company: any = companyObject.data;

            commit('setCompany', company);
            // ***************************************

            // set company details and cashed news ***
            const company_id: number = rootState.Companies.company.company_id;

            let companyDetails: any = await ApiService.getCompanyDetails(company_id, 'sv');
            companyDetails = companyDetails.data;

            rootState.Companies.company.calendar = companyDetails.calendar;
            rootState.Companies.company.calendarFinancialReporting = companyDetails.calendarFinancialReporting;
            rootState.Companies.company.docs = companyDetails.docs;
            rootState.Companies.company.news = companyDetails.news;
            rootState.Companies.company.reports = companyDetails.reports;
            rootState.Companies.company.team = companyDetails.team;
            rootState.Companies.company.whys = companyDetails.whys;
            rootState.Companies.company.ceo_comments = companyDetails.ceo_comments;

            const news: object[] = (companyDetails.news.news !== undefined) ? companyDetails.news.news.map((n: any) => {
                n.page = 1;

                return n;
            }) : [];

            commit('setCompanyDetails', companyDetails);
            commit('setCashedNews', news);
            // ***************************************

            // set prices ****************************
            if(company.is_listed) {
                let prices: any = await ApiService.getCompanyPrices(company_id);
                
                prices = prices.data;

                rootState.Companies.company.prices = prices;

                commit('setCompanyPrices', prices);
            }
            // ***************************************

            // set companies *************************
            const languages: any = rootState.Companies.company.languages_available.map((l: any) => l.language);
            const companies: any = [];

            for(const language of languages) {
                let companyTemp: any = await ApiService.getCompany(short_name, language);
                let companyDetailsTemp: any = await ApiService.getCompanyDetails(company_id, language);
                
                companyTemp = companyTemp.data;
                companyDetailsTemp = companyDetailsTemp.data;

                companyTemp.calendar = companyDetailsTemp.calendar;
                companyTemp.calendarFinancialReporting = companyDetailsTemp.calendarFinancialReporting;
                companyTemp.docs = companyDetailsTemp.docs;
                companyTemp.news = companyDetailsTemp.news;
                companyTemp.reports = companyDetailsTemp.reports;
                companyTemp.team = companyDetailsTemp.team;
                companyTemp.whys = companyDetailsTemp.whys;
                companyTemp.ceo_comments = companyDetailsTemp.ceo_comments;

                companies.push(companyTemp);
            }

            commit('setCompanies', companies);
            // ***************************************

            return result;
        },
        async openFile({ commit, rootState }, data: any) {
            const result = (await ApiService.openFile(data)).data;

            return result;
        },
    }
};
