<template lang="pug">
    div(style="border:0px solid;")
        //- regular skeleton view
        v-container(v-if="innerWidth > 599" fluid)
            v-row
                v-col(cols="12" class="pa-0")
                    div(class="fade-out-and-fade-in-1" style="min-height:92px; background-color:#E4E4E4; border:0px solid;")

                v-col(cols="12" class="cards pa-0 mt-4" style="border:0px solid;")
                    div(style="border:0px solid;")
                        div(class="fade-out-and-fade-in-2" style="height:300px; background-color:#E4E4E4; border:0px solid;")

                    div(class="ml-8 mr-2" style="border:0px solid;")
                        div(style="max-width:1000px; height:calc(100vh - 215px); border:0px solid;")
                            v-container(fluid)
                                v-row
                                    v-col(cols="9" class="pl-0 pt-0 pb-0 pr-4" style="border:0px solid;")
                                        v-container(fluid)
                                            v-row
                                                v-col(cols="12" class="fade-out-and-fade-in-3 pa-0 mb-4" style="height:120px; background-color:#E4E4E4; border:0px solid;")
                                                v-col(cols="6" class="pl-0 pt-0 pb-0 pr-2" style="border:0px solid;")
                                                    div(class="fade-out-and-fade-in-4 mb-2" style="height:170px; background-color:#E4E4E4; border:0px solid;")
                                                v-col(cols="6" class="pl-2 pt-0 pb-0 pr-0" style="border:0px solid;")
                                                    div(class="fade-out-and-fade-in-5 mb-2" style="height:170px; background-color:#E4E4E4; border:0px solid;")
                                    v-col(cols="3" class="pa-0" style="position:relative; border:0px solid;")
                                        div(class="fade-out-and-fade-in-6 mb-2" style="position:absolute; bottom:0; width:100%; height:210px; background-color:#E4E4E4; borer:0px solid;")
                                    v-col(cols="12" class="pa-0" style="border:0px solid;")
                                        div(class="fade-out-and-fade-in-1 mt-2" style="height:calc(100vh - 488px); background-color:#E4E4E4; border:0px solid;")

        div(v-if="innerWidth > 599" class="fade-out-and-fade-in-2" style="position: fixed; bottom:0; height:65px; width:100%; z-index:2; background-color:#E4E4E4; border:0px solid;")

        //- mobile skeleton view
        v-container(v-if="innerWidth <= 599" fluid)
            v-row
                v-col(cols="12" class="pa-0")
                    div(class="fade-out-and-fade-in-1" style="min-height: 56px; background-color:#E4E4E4; border:0px solid;")
                v-col(cols="12" class="pa-0 mt-4")
                    div(style="border:0px solid;")
                        v-container(fluid)
                            v-row
                                v-col(cols="12" class="pa-0 mb-4")
                                    div(class="fade-out-and-fade-in-2" style="background-color:#E4E4E4; height:120px; border:0px solid;")
                                v-col(cols="4" class="pl-0 pt-0 pb-0 pr-2")
                                    div(class="fade-out-and-fade-in-3" style="background-color:#E4E4E4; height:110px; border:0px solid;")
                                v-col(cols="4" class="pl-2 pt-0 pb-0 pr-2")
                                    div(class="fade-out-and-fade-in-4" style="background-color:#E4E4E4; height:110px; border:0px solid;")
                                v-col(cols="4" class="pl-2 pt-0 pb-0 pr-0")
                                    div(class="fade-out-and-fade-in-5" style="background-color:#E4E4E4; height:110px; border:0px solid;")
                                v-col(cols="12" class="pa-0 mt-4")
                                    div(class="fade-out-and-fade-in-6" style="background-color:#E4E4E4; height:500px; border:0px solid;")
</template>

<script>
export default {
    name: "MainSkeleton",
    data() {
        return {
            innerWidth: null,
        };
    },
    mounted() {
        window.addEventListener('resize', this.onResize);

        this.onResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.innerWidth = window.innerWidth;
        }
    },
};
</script>

<style scoped>
.cards {
    display: grid;
    grid-template-columns: 220px 1fr;
}

/* #header-mobile-element,
#mobile-main-content-element {
    display: none;
} */

@media (max-width: 599px) {
    .cards {
        grid-template-columns: 1fr;
    }

    /* #header-element,
    #menu-element,
    #main-element {
        display: none;
    } */

    /* #header-mobile-element {
        display: block;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100000;
    } */

    /* #mobile-main-content-element {
        display: block;
    } */
}

.fade-out-and-fade-in-1 {
    animation: fade-out-fade-in 1.8s infinite;
}

.fade-out-and-fade-in-2 {
    animation: fade-out-fade-in 2s infinite;
}

.fade-out-and-fade-in-3 {
    animation: fade-out-fade-in 2.2s infinite;
}

.fade-out-and-fade-in-4 {
    animation: fade-out-fade-in 2.4s infinite;
}

.fade-out-and-fade-in-5 {
    animation: fade-out-fade-in 2.6s infinite;
}

.fade-out-and-fade-in-6 {
    animation: fade-out-fade-in 2.8s infinite;
}

@keyframes fade-out-fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>
