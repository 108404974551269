<template lang="pug">
    div(class="main-container" style="border:0px solid;")
        div(class="ml-2 mr-2")
            OverviewMobile(v-if="getIsListed")
            Ul_overviewMobile(v-else)
        div(class="ml-2 mr-2 mt-4")
            InvestInUsMobile
        div(class="ml-2 mr-2 mt-4")
            LeadershipTeamMobile
        template(v-if="getIsListed")
            div(class="ml-2 mr-2 mt-4")
                PressReleasesAndNewsMobile
            div(class="ml-2 mr-2 mt-4")
                EarningsAndReportsMobile
        div(class="ml-2 mr-2 mt-4")
            CompanyCalendarMobile
        div(class="ml-2 mr-2 mt-4")
            CorporateMobile
        div(class="ml-2 mr-2 mt-4")
            GenericComponentMobile
        div(class="mt-4")
            FooterMobile
</template>

<script>
import OverviewMobile from "@/views/content/mobile/OverviewMobile.vue";
import Ul_overviewMobile from "@/views/content/mobile/unlisted/Ul_overviewMobile.vue";
import InvestInUsMobile from "@/views/content/mobile/InvestInUsMobile.vue";
import CompanyCalendarMobile from "@/views/content/mobile/CompanyCalendarMobile.vue";
import EarningsAndReportsMobile from "@/views/content/mobile/EarningsAndReportsMobile.vue";
import LeadershipTeamMobile from "@/views/content/mobile/LeadershipTeamMobile.vue";
import PressReleasesAndNewsMobile from "@/views/content/mobile/PressReleasesAndNewsMobile.vue";
import CorporateMobile from "@/views/content/mobile/CorporateMobile.vue";
import GenericComponentMobile from "@/views/content/mobile/GenericComponentMobile.vue";
import FooterMobile from "@/views/footer/FooterMobile.vue";
import { mapGetters } from "vuex";

export default {
    name: "MobileMainContent",
    components: {
        // listed
        OverviewMobile,
        InvestInUsMobile,
        CompanyCalendarMobile,
        EarningsAndReportsMobile,
        LeadershipTeamMobile,
        PressReleasesAndNewsMobile,
        CorporateMobile,
        GenericComponentMobile,
        FooterMobile,

        // unlisted
        Ul_overviewMobile,
    },
    computed: {
        ...mapGetters(['getCompany', 'getIsListed']),
    }
};
</script>

<style scoped>
.main-container {
    padding-top: 50px;
}
</style>
