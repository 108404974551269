<template lang="pug">
    div(class="ir-overview-main-regular")
        div(class="main-element" style="max-width:1000px; border:0px solid;")
            v-container(fluid id="kulijul" class="first-step row-block" style="border:0px solid red;")
                v-row(class="pb-3" style="border:0px solid;")
                    v-col(cols="9" class="pa-0" style="border:0px solid;")
                        v-container(fluid style="border:0px solid;")
                            v-row(class="pr-8" style="border:0px solid;")
                                v-col(cols="12" class="pa-0" style="border:0px solid;")
                                    div(class="mb-1" style="border:0px solid;")
                                        h2 {{ getCompany.pitch_title }}
                                    div(style="min-height:50px; margin-bottom:15px; border:0px solid;")
                                        div {{ getCompany.pitch_detail }}
                                v-col(cols="12" class="pa-0" style="height:170px; overflow:hidden; border:0px solid green;")
                                    v-container(fluid)
                                        v-row
                                            template(v-if="getCompany.video_url_2 !== ''")
                                                v-col(cols="6" class="pl-0 pt-0 pb-0 pr-3" style="height:calc(20vw); border:0px solid green;")
                                                    iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                    img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                                                v-col(cols="6" class="pl-3 pt-0 pb-0 pr-0" style="height:calc(20vw); border:0px solid green;")
                                                    iframe(v-if="getCompany.video_url_2.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_2}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                    img(v-else :src="getCompany.video_url_2" style="height:100%; width:100%;")
                                            template(v-else)
                                                v-col(cols="12" class="pa-0" style="height:calc(20vw); border:0px solid green;")
                                                    iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                    img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")

                    v-col(id="right-regular-elements" cols="3" class="pa-0" style="border:0px solid;")
                        div(class="pa-3" style="position:relative; background-color:#0077FF; color:#ffffff; height:270px; border-radius:5px; border:0px solid;")
                            div(style="font-size:19px; font-weight:bold; border:0px solid;") {{ $t('sign_up_to_keep_up_to_date_with_the_news') }}
                            div(class="mt-3" style="font-size:15px; height:150px; border:0px solid;") {{ getCompany.name }} {{ $t('company_is_intended') }}
                            input(type="email" v-model="subscriptionEmail" class="pl-2 pr-15" style="position:absolute; left:10px; bottom:10px; font-size:11px; width:calc(100% - 20px); height:20px; background-color:#ffffff; border-radius:5px; outline:none !important;")
                            button(@click="subscribe()" style="position:absolute; right:10px; bottom:10px; font-size:8px; color:#000000; background-color:#EDBC9B; height:20px; border-radius:5px; padding-left:5px; padding-right:5px;") {{ $t('subscribe') }}

            v-container(fluid id="kulijul2" class="second-step row-block" style="border:0px solid green;")
                v-row(style="border:0px solid;")
                    v-col(cols="12" class="pa-0" style="border:0px solid;")
                        v-container(fluid style="border:0px solid;")
                            v-row(style="border:0px solid;")
                                v-col(cols="12" class="pa-0 mb-1")
                                    div(class="mb-1" style="border:0px solid;")
                                        h2 {{ getCompany.name }} - {{ getCompany.pitch_title }}
                                    div(style="min-height:50px; margin-bottom:10px; border:0px solid;")
                                        div {{ getCompany.pitch_detail }}
                                
                                v-col(cols="12" class="pa-0" style="border:0px solid green;")
                                    div(style="border:0px solid;")
                                        v-container(fluid)
                                            v-row
                                                template(v-if="getCompany.video_url_2 !== ''")
                                                    v-col(cols="6" class="pl-0 pt-0 pb-0 pr-3" style="height:calc(20vw); border:0px solid green;")
                                                        iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                        img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")
                                                    v-col(cols="6" class="pl-3 pt-0 pb-0 pr-0" style="height:calc(20vw); border:0px solid green;")
                                                        iframe(v-if="getCompany.video_url_2.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_2}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                        img(v-else :src="getCompany.video_url_2" style="height:100%; width:100%;")
                                                template(v-else)
                                                    v-col(cols="12" class="pa-0" style="height:calc(20vw); border:0px solid green;")
                                                        iframe(v-if="getCompany.video_url_1.substring(0, 5) !== 'data:'" :src="`https://www.youtube.com/embed/${getCompany.video_url_1}`" frameborder="0" allowfullscreen style="height:100%; width:100%; border:0px solid red;")
                                                        img(v-else :src="getCompany.video_url_1" style="height:100%; width:100%;")

                                v-col(cols="12" class="pa-0")
                                    v-container(fluid style="border:0px solid;")
                                        v-row
                                            v-col(sm="12" md="6")
                                                div(class="pa-3" style="position:relative; background-color:#0077FF; color:#ffffff; border-radius:5px; border:0px solid;")
                                                    div(style="font-size:19px; font-weight:bold; border:0px solid;") {{ $t('sign_up_to_keep_up_to_date_with_the_news') }}
                                                    div(class="mt-3" style="font-size:15px; height:150px; border:0px solid;") {{ getCompany.name }} {{ $t('company_is_intended') }}
                                                    input(type="email" v-model="subscriptionEmail" class="pl-2 pr-15" style="position:absolute; left:10px; bottom:10px; font-size:11px; width:calc(100% - 20px); height:20px; background-color:#ffffff; border-radius:5px; outline:none !important;")
                                                    button(@click="subscribe()" style="position:absolute; right:10px; bottom:10px; font-size:8px; color:#000000; background-color:#EDBC9B; height:20px; border-radius:5px; padding-left:5px; padding-right:5px;") {{ $t('subscribe') }}

                                            v-col(sm="12" md="6")
                                                div(class="ul-right-menu text-center" style="border:0px solid;")
                                                    div
                                                        v-btn(style="width:100%; height:35px;") {{ $t('memorandum') }}
                                                    div
                                                        v-btn(class="mt-3" style="width:100%; height:35px;") {{ $t('subscribe_for_shares_here') }}
                                                    div
                                                        v-btn(@click="openGenericPage(getCompany.page_id)" class="mt-3" style="width:100%; height:35px;") {{ $t('ceo_vision') }}

                                            v-col(cols="12")
                                                div(class="ul-row-block" style="border:0px solid;")
                                                    div(class="pl-3 pt-3" style="font-size:24px; color:#000000;") {{ getCompany.name }} {{ $t('in_numbers') }}:
                                                    div(style="overflow-y:scroll; overflow-x:hidden; min-height:150px; max-height:calc(100vh - 580px);")
                                                        v-container(fluid style="border:0px solid;")
                                                            v-row
                                                                v-col(:sm="width > 850 ? 4 : 6" md="3" v-for="kpi in kpis" :key="`second${kpi.row_to_json.kpi_id}`" class="sparkline pa-2" style="border:0px solid;")
                                                                    div(class="ellipsis pl-2" :title="kpi.row_to_json.text_1" style="border:0px solid;") {{ kpi.row_to_json.text_1 }}
                                                                    CanvasGeneric(v-if="color !== null && showMe2" :values="kpi.row_to_json.values" :color="color" :label="kpi.row_to_json.text_1" style="width:100%; height:120px; border:0px solid;")
                                                                    div(v-else style="position:relative; width:100%; height:120px; border:0px dashed;")
                                                                        div(style="font-size:24px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px slid;") loading
                                                                    div(class="mt-2 pl-2" style="height:145px; color:#000000; overflow:hidden; border:0px solid;")
                                                                        span(v-html="getFirstSentence(kpi.row_to_json.text_2)")
                                                                        div(@click="openInformation(kpi.row_to_json.text_1, kpi.row_to_json.text_2)" class="ml-1" style="text-decoration:underline; cursor:pointer;") {{ $t('edger_module_read_more') }}

            v-container(fluid class="third-step mt-3 mb-2" style="height:calc(100vh - 520px); border:0px solid;")
                v-row(class="pa-2" style="border:0px solid;")
                    v-col(class="pa-0" cols="12" sm="6" md="9" lg="9" xl="9" style="border:0px solid;")
                        div(class="ul-row-block mr-7" style="border:0px solid;")
                            div(class="pl-3 pt-3" style="font-size:24px; color:#000000;") {{ getCompany.name }} {{ $t('in_numbers') }}:
                            div(style="overflow-y:scroll; overflow-x:hidden; min-height:150px; max-height:calc(100vh - 580px);")
                                v-container(fluid style="border:0px solid;")
                                    v-row
                                        v-col(cols="3" v-for="kpi in kpis" :key="`first${kpi.row_to_json.kpi_id}`" class="sparkline pa-2" style="float:left; border:0px solid;")
                                            div(class="ellipsis pl-2" :title="kpi.row_to_json.text_1" style="border:0px solid;") {{ kpi.row_to_json.text_1 }}
                                            CanvasGeneric(v-if="color !== null && showMe2" :values="kpi.row_to_json.values" :color="color" :label="kpi.row_to_json.text_1" style="width:100%; height:120px; border:0px solid;")
                                            div(v-else style="position:relative; width:100%; height:120px; border:0px dashed;")
                                                div(style="font-size:24px; position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); border:1px slid;") loading
                                            div(class="mt-2 pl-2" style="height:145px; color:#000000; overflow:hidden; border:0px solid;")
                                                span(v-html="getFirstSentence(kpi.row_to_json.text_2)")
                                                div(@click="openInformation(kpi.row_to_json.text_1, kpi.row_to_json.text_2)" class="ml-1" style="text-decoration:underline; cursor:pointer;") {{ $t('edger_module_read_more') }}

                    v-col(class="fourth-step pa-0" cols="12" sm="6" md="3" lg="3" xl="3" style="border:0px solid red;")
                        div(class="ul-right-menu" style="position:relative; height:100%; border:0px solid green;")
                            div(class="ml-7" style="position:absolute; bottom:0; width:200px; height:100%; border:0px solid;")
                                div
                                    v-btn(style="width:100%; height:35px;") {{ $t('memorandum') }}
                                div
                                    v-btn(class="mt-3" style="width:100%; height:35px;") {{ $t('subscribe_for_shares_here') }}
                                div
                                    v-btn(@click="openGenericPage(getCompany.page_id)" class="mt-3" style="width:100%; height:35px;") {{ $t('ceo_vision') }}

        PopupInformation(:openDialog="dialog" :title="title" :text="text")
</template>

<script>
import ChartPrice from "@/components/ChartPrice.vue";
import CanvasGeneric from "@/components/CanvasGeneric.vue";
import PopupInformation from "@/components/PopupInformation.vue";
import { mapGetters } from "vuex";

export default {
    name: "Ul_overview",
    components: {
        ChartPrice,
        CanvasGeneric,
        PopupInformation,
    },
    data() {
        return {
            widthOnContainer: 0,
            width: 0,
            height: 0,
            color: null,
            dialog: false,
            title: '',
            text: '',
            topElementHeight: 0,
            bottomElementHeight: 0,
            showMe: true,
            showMe2: true,
            counter: 0,
            subscriptionEmail: '',
        };
    },
    mounted() {
        this.color = this.getColor();

        window.addEventListener('resize', this.onResize);

        this.onResize();

        console.log(JSON.parse(JSON.stringify(this.getCompany)));
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapGetters([
            'getCompany',
            'getShowDisplayButton'
        ]),
        kpis() {
            return this.getCompany.kpis;
        },
        getShowDisplayButtonString() {
            return JSON.stringify(this.getShowDisplayButton);
        },
    },
    watch: {
        getShowDisplayButtonString() {
            this.showMe = false;

            setTimeout(() => {
                this.showMe = true;
            }, 1);
        },
    },
    methods: {
        subscribe() {
            console.log(this.subscriptionEmail);

            //TODO: Add the subscription email to the database
        },
        openEdgerFinance() {
            window.open('https://edger.finance', '_blank');
        },
        openEdgerFinanceIR() {
            window.open('https://ir.edger.finance/', '_blank');
        },
        openInformation(title, text) {
            this.dialog = true;

            setTimeout(() => {
                this.dialog = false;
            }, 200);

            console.log(title);
            console.log(text);

            this.title = title;
            this.text = text.replace(/\n/g, "<br />");
        },
        getFirstSentence(text) {
            const sentence = text.split(".")[0];

            return `${sentence}.`;
        },
        getColor() {
            const element = document.getElementsByClassName('ir-overview-main-regular')[0];
            const style = getComputedStyle(element);

            return style.color;
        },
        onResize() {
            this.width = window.innerWidth;
            this.showMe2 = false;

            setTimeout(() => {
                console.log('resize');
                
                this.showMe2 = true;
            }, 300);
        },
        openGenericPage(page_id) {
            const element = document.getElementById(`genericMenuElement${page_id}`);

            element.click();
        },
    },
};
</script>

<style scoped>
>>> .trading-vue-data-toolbar-item {
    margin-right: 3px;
}

.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.second-step {
    display: none;
}

@media (max-width: 1130px) {
    .first-step {
        display: none;
    }

    .fourth-step {
        display: none;
    }

    .third-step {
        display: none;
    }

    .second-step {
        display: block;
    }
}

.fade-out-and-fade-in {
    animation: fade-out-fade-in 2s infinite;
}

@keyframes fade-out-fade-in {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>
